import { RouteObject } from "react-router-dom";
import { hashValues, route, string } from "react-router-typesafe-routes/dom";
import { HpGroupSessionLayout } from "../pages/hp/group-sessions/[group-session-id]/group-session.layout";
import { HpGroupSessionMainRoomPage } from "../pages/hp/group-sessions/[group-session-id]/main-room/main-room.page";
import { HpGroupSessionWaitingRoomPage } from "../pages/hp/group-sessions/[group-session-id]/waiting-room.page";
import { HpMyDashboardBillingMethodsPage } from "../pages/hp/my/dashboard/billing.page";
import { HpDashboardCalendarPage } from "../pages/hp/my/dashboard/calendar/calendar.page";
import { HpDashboardClientLayout } from "../pages/hp/my/dashboard/clients/[clientId]/[clientId].layout";
import { HpDashboardClientInfoPage } from "../pages/hp/my/dashboard/clients/[clientId]/client-info.page";
import { HpDashboardClientMessagesPage } from "../pages/hp/my/dashboard/clients/[clientId]/messages.page";
import { HpMyDashboardClientsPage } from "../pages/hp/my/dashboard/clients/clients.page";
import { CommunitiesLandingPage } from "../pages/hp/my/dashboard/community/communities/communities-landing.page";
import { CommunityTabLayout } from "../pages/hp/my/dashboard/community/community-tab.layout";
import {
  CommunityDiscussionFeed,
  CommunityLearningTab,
  CommunityMembersTab,
  HpDashboardCommunityPageLayout,
} from "../pages/hp/my/dashboard/community/community/community.layout";
import { CommunityEventPage } from "../pages/hp/my/dashboard/community/community/events/[eventId].page";
import {
  CommunityEventsTabAll,
  CommunityEventsTabLayout,
} from "../pages/hp/my/dashboard/community/community/events/community-events.tab";
import { GlobalMemberChatTab } from "../pages/hp/my/dashboard/community/global/members/[userId]/chat-tab";
import {
  GlobalMemberAboutTab,
  GlobalMemberPage,
} from "../pages/hp/my/dashboard/community/global/members/[userId]/global-member.page";
import { HpMyDashboardLayout } from "../pages/hp/my/dashboard/dashboard.layout";
import HpDashboardHomePage from "../pages/hp/my/dashboard/home.page";
import { HpMyDashboardInvoicesPage } from "../pages/hp/my/dashboard/invoices.page";
import { HpDashboardSettingsAccountPage } from "../pages/hp/my/dashboard/settings/account/account.page";
import { HpDashboardSettingsBillingPage } from "../pages/hp/my/dashboard/settings/billing/billing.page";
import { HpSettingsClientAndSchedulingPage } from "../pages/hp/my/dashboard/settings/clients-and-scheduling/clients-and-scheduling.page";
import { HpSettingsLayout } from "../pages/hp/my/dashboard/settings/settings.layout";
import { MyLayout } from "../pages/hp/my/my.layout";
import { HpMyPaymentSetupPage } from "../pages/hp/my/payment-setup/payment-setup.page";
import { HpStripeRefreshPage } from "../pages/hp/my/payment-setup/stripe-refresh.page";
import { HpStripeReturnPage } from "../pages/hp/my/payment-setup/stripe-return.page";
import { HpSessionReviewPage } from "../pages/hp/my/sessions/[sessionId]/review.page";
import { HpOnboardChooseProfileMethodPage } from "../pages/hp/onboard/choose-profile-method.page";
import { HpOnboardSetProfilePage } from "../pages/hp/onboard/set-profile.page";
import { PrivateSessionMainRoomPage } from "../pages/hp/sessions/[sessionId]/main-room.page";
import { HpPrivateSessionLayout } from "../pages/hp/sessions/[sessionId]/session.layout";
import { HpLivePrivateSessionWaitingRoomPage } from "../pages/hp/sessions/[sessionId]/waiting-room.page";

const COMMUNITY_ROUTES = route(
  "community",
  {},
  {
    GLOBAL: route(
      "global",
      {},
      {
        USER_PROFILE: route(
          "members",
          {},
          {
            USER_ID: route(
              ":userId",
              {
                params: { userId: string().defined() },
              },
              {
                ABOUT: route("about"),
                CHAT: route("chat"),
              }
            ),
          }
        ),
      }
    ),
    COMMUNITIES: route(
      "communities",
      {},
      {
        COMMUNITY: route(
          ":community",
          {
            params: { community: string().defined() },
          },
          {
            DISCUSSION: route("discussion"),
            LEARNING: route("learning"),
            MEMBERS: route("members"),
            EVENTS: route(
              "events",
              {},
              {
                ALL: route("all"),
                EVENT_ID: route(":eventId", {
                  params: { eventId: string().defined() },
                }),
              }
            ),
          }
        ),
        PRACTITIONER: route("practitioner"),
      }
    ),
  }
);

export const HP_ROUTES = route(
  "hp",
  {},
  {
    ONBOARD_CHOOSE_PROFILE_METHOD: route("choose-profile-method"),
    ONBOARD_SET_PROFILE: route("set-profile", {
      searchParams: { fromUrl: string() },
    }),
    MY: route(
      "my",
      {},
      {
        PRIVATE_SESSIONS: route(
          "private_sessions",
          {},
          {
            SESSION_ID: route(
              ":sessionId",
              {
                params: { sessionId: string().defined() },
              },
              {
                REVIEW: route("review"),
                LIVE: route(
                  "live",
                  {},
                  {
                    MAIN_ROOM: route("main-room"),
                    WAITING_ROOM: route("waiting-room"),
                  }
                ),
              }
            ),
          }
        ),
        GROUP_SESSIONS: route(
          "group-sessions",
          {},
          {
            GROUP_SESSION_ID: route(
              ":groupSessionId",
              { params: { groupSessionId: string().defined() } },
              {
                MAIN_ROOM: route("main-room"),
                WAITING_ROOM: route("waiting-room"),
              }
            ),
          }
        ),
        PAYMENT_SETUP: route(
          "payment-setup",
          {},
          {
            STRIPE_RETURN: route("stripe-return"),
            STRIPE_REFRESH: route("stripe-refresh"),
          }
        ),
        DASHBOARD: route(
          "dashboard",
          {},
          {
            CLIENTS: route(
              "clients",
              {},
              {
                CLIENT_ID: route(
                  ":clientId",
                  {
                    params: { clientId: string().defined() },
                  },
                  {
                    INFO: route("info"),
                    CHAT: route("chat"),
                  }
                ),
              }
            ),
            HOME: route("home"),
            COMMUNITY_TAB: COMMUNITY_ROUTES,
            INVOICES: route("invoices"),
            BILLING: route("billing"),
            CALENDAR: route("calendar", {
              searchParams: {
                action: string(),
                clientId: string(),
                clientName: string(),
                apptDate: string(),
                requestApptId: string(),
              },
            }),
            SETTINGS: route(
              "settings",
              {},
              {
                ACCOUNT: route("account", {
                  hash: hashValues("modalities", "practice-info"),
                }),
                CLIENT_AND_SCHEDULING: route("clients-and-scheduling"),
                BILLING: route("billing"),
              }
            ),
          }
        ),
      }
    ),
  }
);

const communityRoutes: RouteObject = {
  path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.path,
  children: [
    {
      path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
        .path,
      element: <GlobalMemberPage />,
      children: [
        {
          path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
            .ABOUT.path,
          element: <GlobalMemberAboutTab />,
        },
        {
          path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
            .CHAT.path,
          element: <GlobalMemberChatTab />,
        },
      ],
    },
    {
      path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.path,
      element: <CommunityTabLayout />,
      children: [
        {
          path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.path,
          element: <CommunitiesLandingPage />,
        },
        {
          path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.path,
          element: <HpDashboardCommunityPageLayout />,
          children: [
            {
              path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .DISCUSSION.path,
              element: <CommunityDiscussionFeed />,
            },
            {
              path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .LEARNING.path,
              element: <CommunityLearningTab />,
            },
            {
              path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .MEMBERS.path,
              children: [
                {
                  path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.MEMBERS.path,
                  element: <CommunityMembersTab />,
                },
              ],
            },
            {
              path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .EVENTS.path,
              element: <CommunityEventsTabLayout />,
              children: [
                {
                  path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.EVENTS.ALL.path,
                  element: <CommunityEventsTabAll />,
                },
                {
                  path: HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.EVENTS.EVENT_ID.path,
                  element: <CommunityEventPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const hpRoutes: RouteObject = {
  path: HP_ROUTES.path,
  element: <MyLayout />,
  children: [
    {
      path: HP_ROUTES.ONBOARD_CHOOSE_PROFILE_METHOD.path,
      element: <HpOnboardChooseProfileMethodPage />,
    },
    {
      path: HP_ROUTES.ONBOARD_SET_PROFILE.path,
      element: <HpOnboardSetProfilePage />,
    },
    {
      path: HP_ROUTES.MY.path,
      children: [
        {
          path: HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID.REVIEW.path,
          element: <HpSessionReviewPage />,
        },
        {
          path: HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID.path,
          element: <HpPrivateSessionLayout />,
          children: [
            {
              path: HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID.LIVE.MAIN_ROOM
                .path,
              element: <PrivateSessionMainRoomPage />,
            },
            {
              path: HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID.LIVE.WAITING_ROOM
                .path,
              element: <HpLivePrivateSessionWaitingRoomPage />,
            },
          ],
        },
        {
          path: HP_ROUTES.MY.GROUP_SESSIONS.GROUP_SESSION_ID.path,
          element: <HpGroupSessionLayout />,
          children: [
            {
              path: HP_ROUTES.MY.GROUP_SESSIONS.GROUP_SESSION_ID.WAITING_ROOM
                .path,
              element: <HpGroupSessionWaitingRoomPage />,
            },
            {
              path: HP_ROUTES.MY.GROUP_SESSIONS.GROUP_SESSION_ID.MAIN_ROOM.path,
              element: <HpGroupSessionMainRoomPage />,
            },
          ],
        },
        {
          path: HP_ROUTES.MY.PAYMENT_SETUP.path,
          children: [
            {
              path: HP_ROUTES.MY.PAYMENT_SETUP.path,
              element: <HpMyPaymentSetupPage />,
            },
            {
              path: "stripe-return",
              element: <HpStripeReturnPage />,
            },
            {
              path: "stripe-refresh",
              element: <HpStripeRefreshPage />,
            },
          ],
        },
        {
          path: HP_ROUTES.MY.DASHBOARD.path,
          element: <HpMyDashboardLayout />,
          children: [
            {
              path: HP_ROUTES.MY.DASHBOARD.CLIENTS.path,
              element: <HpMyDashboardClientsPage />,
            },
            communityRoutes,
            {
              path: HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.path,
              element: <HpDashboardClientLayout />,
              children: [
                {
                  path: HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.INFO.path,
                  element: <HpDashboardClientInfoPage />,
                },
                {
                  path: HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.CHAT.path,
                  element: <HpDashboardClientMessagesPage />,
                },
              ],
            },
            {
              path: HP_ROUTES.MY.DASHBOARD.HOME.path,
              element: <HpDashboardHomePage />,
            },
            {
              path: HP_ROUTES.MY.DASHBOARD.INVOICES.path,
              element: <HpMyDashboardInvoicesPage />,
            },
            {
              path: HP_ROUTES.MY.DASHBOARD.CALENDAR.path,
              element: <HpDashboardCalendarPage />,
            },
            {
              path: HP_ROUTES.MY.DASHBOARD.BILLING.path,
              element: <HpMyDashboardBillingMethodsPage />,
            },
            {
              path: HP_ROUTES.MY.DASHBOARD.SETTINGS.path,
              element: <HpSettingsLayout />,
              children: [
                {
                  path: HP_ROUTES.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
                  element: <HpDashboardSettingsAccountPage />,
                },
                {
                  path: HP_ROUTES.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING
                    .path,
                  element: <HpSettingsClientAndSchedulingPage />,
                },
                {
                  path: HP_ROUTES.MY.DASHBOARD.SETTINGS.BILLING.path,
                  element: <HpDashboardSettingsBillingPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
