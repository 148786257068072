import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Appointment } from "shared";
import { FormDisclosureContainer } from "../../../../../components/disclosures";
import { FormRadioCard } from "../../../../../components/primitives/radio-buttons";
import {
  SessionFormComponents,
  SetDurationSection,
} from "../../../../../components/session/session-form.components";
import { Rx, RxO } from "../../../../../prelude";
import { HP_ROUTES } from "../../../../../routes/hp-routes";
import { safeParseInt } from "../../../../../util";
import { CalendarMenuInput } from "../../../../../components/form/calendar.input";

export class ViewAppointmentStateMgr {
  appointment$: Rx.BehaviorSubject<Appointment>;

  constructor(p: { appointment: Appointment }) {
    this.appointment$ = new Rx.BehaviorSubject(p.appointment);
  }

  changeAppointmentDate(date: Date) {
    this.appointment$.next({
      ...this.appointment$.value,
      date,
    });
  }

  setDuration(duration: number | null) {
    this.appointment$.next({
      ...this.appointment$.value,
      durationInMinutes: duration,
    });
  }
}

export const ViewAppointmentStateMgrContext =
  React.createContext<ViewAppointmentStateMgr | null>(null);

function useViewAppointmentStateMgr() {
  return React.useContext(ViewAppointmentStateMgrContext)!;
}

export const ViewAppointmentFormContent: React.FC<{}> = () => {
  const stateMgr = useViewAppointmentStateMgr();
  const appointment = useObservableEagerState(stateMgr.appointment$);
  const nav = useNavigate();
  const [paymentMethod, setPaymentMethod] = React.useState("Auto-pay");

  return (
    <div className="flex flex-col gap-2 px-4 py-1">
      {/* <button
            className="bg-red-500 text-white p-2 rounded-lg cursor-pointer self-end"
            onClick={() => {
              BE.authedTrpcTE((tkn) =>
                BE.Api(tkn).hp.calendar.deleteAppointment.mutate({
                  id: appointment.id,
                })
              )().then((er) => {
                if (E.isRight(er)) {
                  dashboardModel.closeRightNav();
                  dashboardModel.showBottomToast("Appointment cancelled");
                }
              });
            }}
          >
            Delete
          </button> */}
      <CalendarMenuInput
        title="Date & time"
        initialDate={appointment.date}
        onSelection={(dt) => {
          stateMgr.changeAppointmentDate(dt);
        }}
      />
      <SetDurationSection
        durationInMinutes$={stateMgr.appointment$.pipe(
          RxO.map((appt) => appt.durationInMinutes)
        )}
        onChange={(v) => {
          stateMgr.setDuration(v);
        }}
      />
      <FormDisclosureContainer
        buttonView={
          <div className="flex gap-1 items-center flex-col">
            <h1 className="text-vid-purple font-sans font-light text-sm">
              Price
            </h1>
            {appointment.price && (
              <div>
                <h4 className="font-light font-sans text-sm">{`$${appointment.price}`}</h4>
              </div>
            )}
          </div>
        }
        dropdownView={() => (
          <div className="flex w-full">
            <input
              type="text"
              className="flex-1 text-input"
              value={appointment.price ?? ""}
              onChange={(e) => {
                const price = safeParseInt(e.target.value) ?? null;
                const updatedAppointment = { ...appointment, price };
                stateMgr.appointment$.next(updatedAppointment);
              }}
            />
          </div>
        )}
      />
      <FormRadioCard
        title="Payment"
        // optionsMenu={<div></div>}
        radioProps={{
          options: [
            {
              name: "Auto-pay",
              icon: <></>,
            },
            {
              name: "Outside platform",
              icon: <></>,
            },
          ],
          value: paymentMethod,
          onChange: (v) => {
            console.log("PAYMENT METHOD! ", v);
            setPaymentMethod(v);
          },
        }}
      />
      <SessionFormComponents.StartSessionButton
        clientUserId={appointment.client.id}
        sessionConfig={{
          durationInMinutes: appointment.durationInMinutes,
          minutesBeforeEndReminder: null,
          sendInviteEmail: false,
        }}
        onSuccess={({ sessionId }) => {
          console.log("SESSION STARTED! ", sessionId);
          const navPath =
            HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID.LIVE.WAITING_ROOM.buildPath(
              {
                sessionId,
              }
            );
          nav(navPath);
        }}
      />
    </div>
  );
};

export const ViewAppointmentFormTopView: React.FC = () => {
  const stateMgr = useViewAppointmentStateMgr();
  const appointment = useObservableEagerState(stateMgr.appointment$);

  return (
    <div className="flex flex-col items-center justify-center gap-2 p-4">
      {appointment.client.profilePhoto && (
        <img
          src={appointment.client.profilePhoto}
          alt="Client profile photo"
          className="w-24 h-24 rounded-full object-cover"
        />
      )}
      <h1 className="font-sans font-light text-xl">
        {appointment.client.name}
      </h1>
    </div>
  );
};
