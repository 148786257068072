/// <reference lib="dom" />
/// <reference lib="dom.iterable" />

import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import { router } from "./router.tsx";
import { AppConfig } from "./app-config.ts";

console.log("INITING APP ENV! ", AppConfig.appEnv);

Sentry.init({
  environment: AppConfig.appEnv,
  dsn: "https://a30c26222580c9e3d4c88fbb87b7f3d1@o4506825648635904.ingest.us.sentry.io/4506825650667520",
  // integrations: [captureConsoleIntegration()],
  // integrations: [
  //   Sentry.reactRouterV6BrowserTracingIntegration({
  //   }),
  //   Sentry.replayIntegration({
  //     maskAllText: false,
  //     blockAllMedia: false,
  //   }),
  // ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router} />
);
