import { createContext, useContext } from "react";

export class HpClientDashboardState {
  constructor(readonly clientId: string) {}
}

export const HpClientDashboardContext =
  createContext<HpClientDashboardState | null>(null);

export function useHpClientDashboardState() {
  const state = useContext(HpClientDashboardContext);
  if (!state) {
    throw new Error("HpClientDashboardContext not found");
  }
  return state;
}
