export const InfoHeader: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <div
      className={`
      bg-vid-black-100 py-4 px-6
        rounded-tr-[12px] rounded-tl-[12px]
    `}
    >
      <h3 className="font-sans font-semibold text-sm text-vid-black-900">
        {title}
      </h3>
    </div>
  );
};

export const ListContainer: React.FC<{
  title?: string;
  rows: React.ReactNode[];
  emptyMessage?: string;
}> = ({ title, rows, emptyMessage }) => {
  return (
    <div className="bg-white rounded-[12px]">
      <InfoHeader title={title} />
      <div className="px-6 py-4 flex flex-col">
        {rows.length === 0 ? (
          <div className="text-vid-black-900 font-medium text-center">
            {emptyMessage ?? "Empty"}
          </div>
        ) : (
          rows
        )}
      </div>
    </div>
  );
};
