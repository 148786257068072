import { useObservableState } from "observable-hooks";
import React, { useEffect, useState } from "react";
import { Appointment } from "shared";
import { E } from "shared/base-prelude";
import { BE } from "../../../../../backend";
import { Rx } from "../../../../../prelude";
import { useHpState } from "../../../hp.state";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

type CalendarMonthViewProps = {
  currentMonth$: Rx.Observable<Date>;
  currentDay$: Rx.Observable<Date>;
};

export const CalendarMonthView: React.FC<CalendarMonthViewProps> = ({
  currentMonth$,
  currentDay$,
}) => {
  const currentMonth = useObservableState(currentMonth$, new Date());
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const dashboardModel = useHpState();
  const currentDay = useObservableState(currentDay$, new Date());

  useEffect(() => {
    const fetchAppointments = async () => {
      // Assuming your API returns appointments for the current month

      const appts = await BE.authedTE((tkn) =>
        BE.Api(tkn).hp.calendar.getAppointmentsInWindow.query({})
      )();

      if (E.isRight(appts)) {
        setAppointments(appts.right);
      }
    };

    fetchAppointments();
  }, [currentMonth]);

  const getDaysInMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const startDayOfMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth(), 1).getDay();

  const daysInMonth = getDaysInMonth(currentMonth);
  const emptyStartDays = Array.from(
    { length: startDayOfMonth(currentMonth) },
    (_, idx) => (
      <div
        key={idx}
        className="border border-gray-200 flex justify-center items-center p-2 h-full"
      >
        <p className=""> </p>
      </div>
    )
  );
  const days = Array.from({ length: daysInMonth }, (_, i) => {
    const day = i + 1;
    const dateStr = `${currentMonth.getFullYear()}-${String(
      currentMonth.getMonth() + 1
    ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    const dayAppointments = appointments.filter(
      (appt) => appt.date.toISOString().split("T")[0] === dateStr
    );

    return (
      <div
        key={day}
        className="border border-gray-200 flex flex-col justify-start items-center p-4 h-42 aspect-square overflow-hidden"
      >
        <div
          className={`${
            day.toString() === currentDay.getDate().toString()
              ? "bg-vid-purple text-white p-1 rounded-full"
              : ""
          } text-xs text-center font-semibold w-fit h-fit`}
        >
          {day}
        </div>
        {dayAppointments.map((appt) => (
          <div
            key={appt.id}
            className="mt-2 text-xs h-8 bg-[#F4F4FF] text-black px-1 py-2 self-stretch rounded-lg cursor-pointer overflow-hidden whitespace-nowrap font-sans"
            onClick={() => {
              dashboardModel.openRightNav({
                _tag: "VIEW_APPOINTMENT",
                appointmentId: appt.id,
              });
            }}
          >
            {appt.title}
          </div>
        ))}
      </div>
    );
  });

  return (
    <div className="border rounded-xl my-4 h-full">
      <div className="grid grid-cols-7 rounded-xl">
        {daysOfWeek.map((day, idx) => (
          <div
            key={idx}
            className="h-fit py-2 font-sans text-center font-medium inline-flex justify-center bg-gray-100"
          >
            {day}
          </div>
        ))}
        {emptyStartDays}
        {days}
      </div>
    </div>
  );
};
