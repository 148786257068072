import { Outlet } from "react-router-dom";
import { useHpState } from "../../../hp.state";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import { CommunityTabMessenger } from "../../../../../components/chat/group-chat";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { BE } from "../../../../../backend";
import { RD } from "shared/base-prelude";

export const CommunityTabLayout: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col static lg:relative">
      <Outlet />
      <CommunityTabMessengerBottomBar />
    </div>
  );
};

const CommunityTabMessengerBottomBar: React.FC = () => {
  const hpState = useHpState();
  const myProfile = useObservableEagerState(hpState.myProfile$);
  const rdTotalUnreadMessagesCount = useTaskEither(
    BE.fetchEndpointTE((Api) =>
      Api.hp.communities.getUnreadMessagesCount.query()
    )
  );

  const [isMinimized, setIsMinimized] = React.useState(true);

  useEffect(() => {
    if (
      RD.isSuccess(rdTotalUnreadMessagesCount) &&
      rdTotalUnreadMessagesCount.value > 0
    ) {
      setIsMinimized(false);
    }
  }, [rdTotalUnreadMessagesCount]);

  return (
    <div
      className={`
        hidden fixed bottom-0 right-24 z-20  bg-vid-purple w-[400px] rounded-lg 
        lg:flex flex-col
        overflow-y-auto
        border-4
        ${isMinimized ? "max-h-[40px]" : "min-h-[400px] max-h-[80%]"}
          `}
    >
      <CommunityTabMessenger
        myProfile={{ id: myProfile.id!, name: myProfile.name! }}
        onMinimizeClick={() => setIsMinimized((i) => !i)}
        isMinimized={isMinimized}
      />
    </div>
  );
};
