import { Effect } from "effect";
import { sendPasswordResetEmail } from "firebase/auth";
import { pipe } from "fp-ts/lib/function";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BE } from "../backend";
import { PasswordInput } from "../components/onboard.components";
import {
  FirebaseAuthErrorCode,
  messageForFirebaseAuthError,
  useFirebaseJs,
} from "shared/firebase";
import { LoadingSpinner } from "../loading";
import { E, RD } from "../prelude";
import { HP_ROUTES } from "../routes/hp-routes";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { ROOT_ROUTES } from "../routes/routes";

const loginViaApi = (firebaseUserId: string) =>
  BE.publicTE(() => BE.PublicApi.auth.login.mutate({ firebaseUserId }))();

export const LoginPage: React.FC = () => {
  const nav = useNavigate();
  const [{ prefilledEmail }] = useTypedSearchParams(ROOT_ROUTES.LOGIN);
  const [mbError, setMbError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const mbRedirectTo = searchParams.get("redirectTo");

  return (
    <div className="flex flex-col justify-center items-center">
      <LoginCard
        email={prefilledEmail}
        onSuccessSignInFirebase={(firebaseUserId) => {
          console.log("Success!");
          loginViaApi(firebaseUserId).then((er) => {
            console.log("ER: ", er);
            if (E.isRight(er)) {
              const user = er.right;

              if (mbRedirectTo) {
                nav(mbRedirectTo);
              } else if (user.primary_role === "client") {
                nav("/cp/dashboard/home");
              } else {
                nav(HP_ROUTES.MY.DASHBOARD.CLIENTS.path);
              }
            } else {
              setMbError(`${er.left}`);
            }
          });
        }}
      />
      {mbError && (
        <div className="text-red-500 text-center mt-8">{mbError}</div>
      )}
    </div>
  );
};

type EmailPasswordForm = {
  email: string;
  password: string;
};

type FirebaseAuthError = {
  code: FirebaseAuthErrorCode;
};

type RegisterError =
  | { _tag: "Authentication"; error: FirebaseAuthError }
  | { _tag: "Backend"; error: unknown };

const LoginCard: React.FC<{
  email?: string;
  onSuccessSignInFirebase: (firebaseUserId: string) => void;
}> = ({ email, onSuccessSignInFirebase }) => {
  const { register, handleSubmit, watch, control } = useForm<EmailPasswordForm>(
    {
      defaultValues: {
        email: email ?? "",
        password: "",
      },
    }
  );
  const [rdLoginResult, setRdLoginResult] = useState<
    RD.RemoteData<RegisterError, unknown>
  >(RD.initial);
  const [rdForgotMyPwdResult, setRdForgotMyPwdResult] = useState<
    RD.RemoteData<unknown, unknown>
  >(RD.initial);

  const firebaseJs = useFirebaseJs();

  const emailInput = watch("email");

  return (
    <div className="h-screen p-8 flex flex-col justify-center items-center gap-2">
      <h1 className="font-bold text-2xl lg:text-5xl text-default-purple font-outfit mb-4">
        Login
      </h1>
      <form
        className="flex flex-col gap-4 w-full"
        onSubmit={handleSubmit((data) => {
          setRdLoginResult(RD.pending);

          firebaseJs
            .signinWithEmailPasswordEff({
              email: data.email,
              password: data.password,
            })
            .pipe(
              Effect.match({
                onFailure: (err) => {
                  setRdLoginResult(
                    RD.failure({
                      _tag: "Authentication",
                      error: { code: err.code },
                    })
                  );
                },
                onSuccess: (uc) => {
                  setRdLoginResult(RD.success(uc));
                  onSuccessSignInFirebase(uc.user.uid);
                },
              })
            )
            .pipe(Effect.runPromise)
            .then((r) => {
              console.log("R! ", r);
            });

          // signInWithEmailAndPassword(firebaseAuth, data.email, data.password)
          //   .then((uc) => {
          //     console.log("UC! ", uc);
          //     onSuccessSignInFirebase(uc.user.uid);
          //   })
          //   .catch((error: FirebaseAuthError) => {
          //     const authError: RegisterError = {
          //       _tag: "Authentication",
          //       error: error,
          //     };
          //     setRdLoginResult(RD.failure(authError));
          //   });
        })}
      >
        <input
          type="email"
          placeholder="Email"
          className="text-input"
          id="email"
          {...register("email", { required: true })}
        />
        <PasswordInput control={control} name="password" />
        {RD.isFailure(rdLoginResult) && (
          <div className="text-red-500">
            {rdLoginResult.error._tag === "Authentication"
              ? messageForFirebaseAuthError(rdLoginResult.error.error.code)
              : "An error has occured"}
            {/* {match(rdLoginResult.error)
              .with({ _tag: "Authentication" }, (e) =>
                messageForFirebaseAuthError(e.error.code)
              )
              .with({ _tag: "Backend" }, (e) => JSON.stringify(e.error))
              .exhaustive()} */}
          </div>
        )}
        <button
          type="submit"
          className="bg-default-purple text-white px-8 py-4 rounded-lg font-bold"
        >
          {RD.isPending(rdLoginResult) ? "Submitting..." : "Login"}
        </button>
      </form>

      <div className="flex flex-col gap-4 mt-8">
        <button
          className="underline px-8 py-0 rounded-lg font-light font-sans text-sm text-vid-gray"
          onClick={() => {
            sendPasswordResetEmail(firebaseJs.auth, emailInput)
              .then((r) => {
                console.log("R of forgoto pawd0: ", r);
                setRdForgotMyPwdResult(RD.success(r));
              })
              .catch((e) => {
                setRdForgotMyPwdResult(RD.failure(e));
              });
          }}
        >
          {`Forgot password?`}
        </button>
        {pipe(
          rdForgotMyPwdResult,
          RD.fold(
            () => <></>,
            () => <LoadingSpinner />,
            (e) => <div className="text-red-400">{JSON.stringify(e)}</div>,
            (_) => (
              <div className="text-green-400 font-bold text-lg">{`Password reset email sent to ${emailInput}`}</div>
            )
          )
        )}
      </div>
      <button
        className=" text-vid-purple px-8 py-0 rounded-lg font-light font-sans text-sm"
        onClick={() => {
          window.location.href = "/onboard/portal-selection";
        }}
      >
        {`Don't have an account? Sign up`}
      </button>
    </div>
  );
};
