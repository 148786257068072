import { Switch } from "@headlessui/react";
import { useObservableEagerState } from "observable-hooks";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { UserWithProfilePhoto } from "shared";
import { FirebaseJsMgr, useFirebaseJs } from "shared/firebase";
import {
  defaultNormalSessionState,
  SessionStateSchema,
} from "shared/session-state/session-state";
import { BE, DefaultError } from "../../../../../backend";
import {
  SelectClientSection,
  SetDurationSection,
  SetEndingSessionReminderSection,
} from "../../../../../components/session/session-form.components";
import { FullContainerLoadingSpinner } from "../../../../../loading";
import { FirestoreSessionStateMgmt } from "../../../../../mgrs/live-session/firestore-live-session.mgmt";
import { E, RD, Rx, RxO } from "../../../../../prelude";
import { HP_ROUTES } from "../../../../../routes/hp-routes";
import { ClientsPageComponents } from "../clients/clients-page.components";
import { SimpleClient } from "./right-nav";

type SessionConfig = ClientsPageComponents.NewSessionConfig;

export const StartASessionForm: React.FC<{
  onClose: () => void;
  forClient:
    | { _tag: "EXISTING"; client: SimpleClient }
    | { _tag: "SELECT"; myClients: UserWithProfilePhoto[] };
}> = ({ onClose, forClient }) => {
  const nav = useNavigate();
  const firebaseJs = useFirebaseJs();
  const sessionConfig$ = useMemo(
    () =>
      new Rx.BehaviorSubject<SessionConfig>({
        client: forClient._tag === "EXISTING" ? forClient.client : null,
        durationInMinutes: 60,
        minutesBeforeEndReminder: 10,
        sendInviteEmail: true,
        audioOnly: false,
      }),
    [forClient]
  );
  const sessionConfig = useObservableEagerState(sessionConfig$);
  const selectedClient$ = React.useMemo(
    () => sessionConfig$.pipe(RxO.map((sc) => sc.client)),
    []
  );
  const [rdSubmitResult, setRdSubmitResult] = React.useState<
    RD.RemoteData<DefaultError, any>
  >(RD.initial);

  return (
    <div className="flex flex-col p-8">
      <div className="flex flex-col gap-12">
        {forClient._tag === "SELECT" && (
          <SelectClientSection
            selectedClient$={selectedClient$}
            myClients={forClient.myClients}
            onClientSelect={(client) => {
              sessionConfig$.next({ ...sessionConfig, client });
            }}
          />
        )}
        <SetDurationSection
          durationInMinutes$={sessionConfig$.pipe(
            RxO.map((v) => v.durationInMinutes)
          )}
          onChange={(v) => {
            sessionConfig$.next({ ...sessionConfig, durationInMinutes: v });
          }}
        />
        {/* <ClientsPageComponents.DurationRadioGroup
          sessionConfig$={sessionConfig$}
        /> */}
        {/* <ClientsPageComponents.SetTimeWarningRadioGroup
          sessionConfig$={sessionConfig$}
        /> */}
        <SetEndingSessionReminderSection
          endingSessionReminder$={sessionConfig$.pipe(
            RxO.map((v) => v.minutesBeforeEndReminder)
          )}
          onChange={(v) => {
            sessionConfig$.next({
              ...sessionConfig,
              minutesBeforeEndReminder: v,
            });
          }}
        />
        <div className="flex gap-8 mt-8">
          <h4 className="font-bold">Send Invite Email?</h4>
          <Switch
            checked={sessionConfig.sendInviteEmail}
            onChange={(v) => {
              sessionConfig$.next({ ...sessionConfig, sendInviteEmail: v });
            }}
            className={`${
              sessionConfig.sendInviteEmail ? "bg-vid-purple" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Send invite email</span>
            <span
              className={`${
                sessionConfig.sendInviteEmail
                  ? "translate-x-6"
                  : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        <div className="flex gap-8 mt-8">
          <h4 className="font-bold">Audio only?</h4>
          <Switch
            checked={sessionConfig.audioOnly}
            onChange={(v) => {
              sessionConfig$.next({ ...sessionConfig, audioOnly: v });
            }}
            className={`${
              sessionConfig.audioOnly ? "bg-vid-purple" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Audio only</span>
            <span
              className={`${
                sessionConfig.audioOnly ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      </div>
      <button
        className="bg-btn-purple p-4 rounded-xl text-white self-stretch mt-8"
        onClick={() => {
          setRdSubmitResult(RD.pending);
          startSession(firebaseJs, sessionConfig).then((er) => {
            setRdSubmitResult(RD.fromEither(er));
            if (E.isRight(er)) {
              const navPath =
                HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID.LIVE.WAITING_ROOM.buildPath(
                  {
                    sessionId: er.right.session.id,
                  }
                );
              nav(navPath);
              onClose();
            }
          });
        }}
      >
        {RD.isPending(rdSubmitResult) ? (
          <FullContainerLoadingSpinner />
        ) : (
          "Start session"
        )}
      </button>
      {RD.isFailure(rdSubmitResult) && (
        <div className="text-red-500 font-bold self-center mt-4">
          {rdSubmitResult.error.error.message}
        </div>
      )}
    </div>
  );
};

async function startSession(
  firebaseJs: FirebaseJsMgr,
  sessionConfig: SessionConfig
) {
  const {
    client,
    durationInMinutes,
    sendInviteEmail,
    minutesBeforeEndReminder,
    audioOnly,
  } = sessionConfig;
  const serverCreateResult = await BE.authedTE((tkn) =>
    BE.Api(tkn).hp.sessions.startSession.mutate({
      clientUserId: client?.id ?? null,
      durationInMinutes,
      sendEmail: sendInviteEmail,
      minutesBeforeEndReminder,
      audioOnly,
    })
  )();

  if (E.isRight(serverCreateResult)) {
    const { session } = serverCreateResult.right;
    const firestoreMgmt = new FirestoreSessionStateMgmt(
      firebaseJs,
      { _tag: "PRIVATE", sessionId: session.id },
      defaultNormalSessionState,
      SessionStateSchema
    );

    await firestoreMgmt.createSessionStateDoc();
  }

  return serverCreateResult;
}
