import { Effect } from "effect";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import {
  Auth,
  AuthErrorCodes,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  getIdToken,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { pipe } from "fp-ts/lib/function";
import { AppEnv } from "shared";
import { match } from "ts-pattern";
import { E, Rx, TE } from "./base-prelude";
import { createContextAndHook } from "./util";

type FirebaseCheckAuthState =
  | { _tag: "UNKNOWN" }
  | { _tag: "KNOWN"; authState: FirebaseAuthState };
type FirebaseAuthState =
  | { _tag: "LOGGED_IN"; user: User }
  | { _tag: "LOGGED_OUT" };

class GetFirebaseTokenError {
  readonly _tag = "GetFirebaseTokenError";
  constructor(readonly error: Error) {}
}

export type FirebaseAuthErrorCode =
  (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];

export interface FirebaseAuthError {
  code: FirebaseAuthErrorCode;
}

export type CreateUserWithEmailPasswordError = {
  _tag: "Error";
  error: FirebaseAuthError;
};

type CreateUserWithEmailPasswordSuccess =
  | { _tag: "SUCCESS"; user: UserCredential }
  | { _tag: "ALREADY_CREATED" };

export class FirebaseJsMgr {
  config: FirebaseOptions;
  app: FirebaseApp;
  auth: Auth;
  firestore: Firestore;

  firebaseAuthState$ = new Rx.BehaviorSubject<FirebaseCheckAuthState>({
    _tag: "UNKNOWN",
  });

  constructor(
    appEnv: AppEnv,
    mkAuth: (app: FirebaseApp) => Auth,
    mbAppName?: "Vidalify" | "InsightLive"
  ) {
    this.config = configForAppEnv(appEnv, mbAppName);

    this.app = initializeApp(this.config);
    this.auth = mkAuth(this.app);
    this.firestore = getFirestore(this.app);

    onAuthStateChanged(this.auth, (user) => {
      this.firebaseAuthState$.next(
        user
          ? { _tag: "KNOWN", authState: { _tag: "LOGGED_IN", user } }
          : { _tag: "KNOWN", authState: { _tag: "LOGGED_OUT" } }
      );
    });
  }

  getKnownFirebaseAuthState(): Promise<FirebaseAuthState> {
    return new Promise((resolve) => {
      if (this.firebaseAuthState$.value._tag === "KNOWN") {
        resolve(this.firebaseAuthState$.value.authState);
      } else {
        const unsub = this.firebaseAuthState$.subscribe((authState) => {
          if (authState._tag === "KNOWN") {
            resolve(authState.authState);
            unsub.unsubscribe();
          }
        });
      }
    });
  }

  getFirebaseUser = async (): Promise<User | null> => {
    const authState = await this.getKnownFirebaseAuthState();
    return authState._tag === "LOGGED_IN" ? authState.user : null;
  };

  private getFirebaseAuthToken = async () => {
    const mbUser = await this.getFirebaseUser();

    if (mbUser) {
      return getIdToken(mbUser);
    }

    return null;
  };

  getFirebaseAuthTokenTE = TE.tryCatch(
    this.getFirebaseAuthToken,
    (e) => e as Error
  );

  getAssumedFirebaseTokenTE = pipe(
    this.getFirebaseAuthTokenTE,
    TE.chain((mbToken) => {
      if (mbToken) {
        return TE.right(mbToken);
      }

      return TE.left(new Error("No Firebase Token"));
    })
  );

  getFirebaseTokenEffect: Effect.Effect<string, GetFirebaseTokenError, never> =
    Effect.promise(() => this.getAssumedFirebaseTokenTE()).pipe(
      Effect.flatMap((eToken) => {
        if (E.isLeft(eToken)) {
          return Effect.fail(new GetFirebaseTokenError(eToken.left));
        }
        return Effect.succeed(eToken.right);
      })
    );

  createUserWithEmailAndPasswordTE = (p: { email: string; password: string }) =>
    TE.tryCatch(
      () => createUserWithEmailAndPassword(this.auth, p.email, p.password),
      (e) => e as FirebaseAuthError
    );

  createUserWithEmailAndPasswordEff = (p: {
    email: string;
    password: string;
  }): Effect.Effect<
    CreateUserWithEmailPasswordSuccess,
    FirebaseAuthError,
    never
  > =>
    Effect.promise(() => this.createUserWithEmailAndPasswordTE(p)()).pipe(
      Effect.flatMap((eresult) => {
        if (E.isLeft(eresult)) {
          if (isAlreadyCreatedError(eresult.left)) {
            return Effect.succeed({
              _tag: "ALREADY_CREATED",
            } as CreateUserWithEmailPasswordSuccess);
          }
          return Effect.fail(eresult.left);
        }
        return Effect.succeed({ _tag: "SUCCESS", user: eresult.right });
      })
    );

  signinWithEmailPasswordEff = (p: { email: string; password: string }) =>
    Effect.tryPromise({
      try: () => signInWithEmailAndPassword(this.auth, p.email, p.password),
      catch: (e) => e as FirebaseAuthError,
    });

  signinWithCustomTokenEff = (token: string) =>
    Effect.tryPromise({
      try: () => signInWithCustomToken(this.auth, token),
      catch: (e) => e as FirebaseAuthError,
    });

  signOut() {
    return signOut(this.auth);
  }
}

export const [FirebaseJsContext, useFirebaseJs] =
  createContextAndHook<FirebaseJsMgr>();

// export const firebaseApp = initializeApp(prodFirebaseConfig);

function isAlreadyCreatedError(error: FirebaseAuthError) {
  const validCodes = [
    AuthErrorCodes.EMAIL_EXISTS,
    AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE,
  ] as FirebaseAuthErrorCode[];
  return validCodes.includes(error.code);
}

export const messageForFirebaseAuthError = (code: FirebaseAuthErrorCode) =>
  match(code)
    .with(AuthErrorCodes.EMAIL_EXISTS, () => "Email already exists")
    .with(
      AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE,
      () => "Email already exists"
    )
    .with(
      AuthErrorCodes.CREDENTIAL_MISMATCH,
      () => "Email or password is incorrect"
    )
    .with(
      AuthErrorCodes.INVALID_LOGIN_CREDENTIALS,
      () => "Invalid login email or password"
    )
    .with(AuthErrorCodes.INVALID_EMAIL, () => "Invalid email")
    .with(AuthErrorCodes.INVALID_PASSWORD, () => "Invalid password")
    .with(AuthErrorCodes.USER_DISABLED, () => "User is disabled")
    .with(AuthErrorCodes.NULL_USER, () => "User not found")
    .with(AuthErrorCodes.WEAK_PASSWORD, () => "Password is too weak")
    .with(
      AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER,
      () => "Too many requests. Please try again later."
    )
    .with(AuthErrorCodes.OPERATION_NOT_ALLOWED, () => "Operation not allowed")
    .otherwise(
      () => `Error creating account. Please try again later. (code: ${code})`
    );

function configForAppEnv(
  appEnv: AppEnv,
  mbAppName?: "Vidalify" | "InsightLive"
): FirebaseOptions {
  return match(appEnv)
    .with("prod", () =>
      mbAppName === "Vidalify" ? vidalifyProdFirebaseConfig : prodFirebaseConfig
    )
    .with("dev", () => devFirebaseConfig)
    .exhaustive();
}

const vidalifyProdFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCk6YF6j31i2Nxm55J7Ur4J1BTuP9KEpis",
  authDomain: "webapp-prod-deb9c.firebaseapp.com",
  projectId: "webapp-prod-deb9c",
  storageBucket: "webapp-prod-deb9c.appspot.com",
  messagingSenderId: "244231368260",
  appId: "1:244231368260:web:08408b86668af36c40d611",
  measurementId: "G-TV3RQ19WHE",
};

const prodFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCBwI45Ks5i_XUx-HqieQ63pIC1u5OwQEE",
  authDomain: "prod-46796.firebaseapp.com",
  projectId: "prod-46796",
  storageBucket: "prod-46796.appspot.com",
  messagingSenderId: "151843005587",
  appId: "1:151843005587:web:5c0008f426a1699861d966",
};

const devFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDSxb_ld5D8TFsa7U_idGfbpd9Sowfdw7Y",
  authDomain: "webapp-dev-b61bf.firebaseapp.com",
  projectId: "webapp-dev-b61bf",
  storageBucket: "webapp-dev-b61bf.appspot.com",
  messagingSenderId: "335821848330",
  appId: "1:335821848330:web:c15e46893bb972e0fd04f3",
};
