import React from "react";
import { E, O, RD } from "shared/base-prelude";
import { BE } from "../../../backend";
import { useTaskEither } from "shared/backend-api/api.mgr";

export const CpSettingsPage: React.FC<{}> = ({}) => {
  return (
    <div>
      Settings
      <div>
        <UploadProfilePhoto />
      </div>
    </div>
  );
};

const UploadProfilePhoto: React.FC<{}> = ({}) => {
  const [mbProfilePhoto, setMbProfilePhoto] = React.useState<O.Option<Blob>>(
    O.none
  );

  const rdUploadUrl = useTaskEither(
    BE.authedTE((tkn) => BE.Api(tkn).cp.getProfilePhotoUploadUrl.query())
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        {O.isSome(mbProfilePhoto) && (
          <img
            src={URL.createObjectURL(mbProfilePhoto.value)}
            className="w-32 h-32 rounded-full"
          />
        )}
        <label htmlFor="profilePhoto">Profile Photo </label>
        <input
          type="file"
          id="profilePhoto"
          onChange={(e) => {
            const file = e.target.files?.[0];

            if (file && RD.isSuccess(rdUploadUrl)) {
              const uploadPhoto = async () => {
                const uploadResult = await fetch(
                  rdUploadUrl.value.profilePhotoUploadUrl,
                  {
                    method: "PUT",
                    body: file,
                  }
                );

                if (uploadResult.ok) {
                  console.log("UPLOAD RESULT! ", uploadResult);
                  console.log("REGISTERING IT UPLOADED! ");
                  const itUploadedResult = await BE.authedTE((tkn) =>
                    BE.Api(tkn).cp.setProfilePhotoUploadedAt.mutate()
                  )();
                  console.log("IT UPLOADED RESULT! ", itUploadedResult);

                  const downloadUrl = await BE.authedTE((tkn) =>
                    BE.Api(tkn).cp.getProfilePhotoDownloadUrl.query()
                  )();

                  if (E.isRight(downloadUrl)) {
                    const downloadResult = await fetch(
                      downloadUrl.right.profilePhotoDownloadUrl,
                      {
                        method: "GET",
                      }
                    );

                    if (downloadResult.ok) {
                      const blob = await downloadResult.blob();
                      setMbProfilePhoto(O.some(blob));
                    }
                  }
                }
              };

              uploadPhoto().then();
            }
          }}
        />
      </div>
    </div>
  );
};
