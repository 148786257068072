import { BE } from "../../../../backend";

export const HpMyPaymentSetupPage: React.FC = () => {
  return (
    <div className="w-screen h-screen flex flex-col gap-8 justify-center items-center">
      <h1>Payment Setup</h1>
      <button
        className="btn-light"
        onClick={() => {
          BE.authedTE((tkn) =>
            BE.Api(tkn).hp.payment.createStripeAccount.mutate()
          )().then((er) => {
            console.log("Payment Setup Result! ", er);
            if (er._tag === "Right") {
              window.location.href = er.right.stripeAccountLink.url;
            }
          });
        }}
      >
        Setup payment now
      </button>
      <button>{`I'll do this later`}</button>
    </div>
  );
};
