import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import React from "react";

export function createContextAndHook<T>(
  displayName?: string
): [React.Context<T | null>, () => T] {
  const Context = React.createContext<T | null>(null);

  function useContext() {
    const ctx = React.useContext(Context);
    if (ctx === null) {
      throw new Error(
        `use${displayName} must be used within a ${displayName ?? ""}Provider`
      );
    } else {
      return ctx as T;
    }
  }

  return [Context, useContext];
}

export async function ignorePromiseError<T>(promise: Promise<T>) {
  try {
    await promise;
  } catch (e) {
    console.log("IGNORED ERROR", e);
  }
}

export function isNullOrUndefined<T>(value: T): boolean {
  return pipe(value, O.fromNullable, O.isSome);
}
