import { useTaskEither } from "shared/backend-api/api.mgr";
import { format } from "date-fns";
import { signOut } from "firebase/auth";
import { sequenceT } from "fp-ts/lib/Apply";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { Link } from "react-router-dom";
import { BE } from "../../../backend";
import { ListContainer } from "../../../components/list.components";
import { useFirebaseJs } from "shared/firebase";
import { FullContainerLoadingSpinner } from "../../../loading";
import { O, RD } from "../../../prelude";
import { CP_ROUTES } from "../../../routes/cp-routes";

export const CpDashboardHomePage: React.FC = () => {
  const firebaseJs = useFirebaseJs();
  const rdHomeData = useTaskEither(
    BE.authedTE((tkn) => BE.Api(tkn).cp.getHomeData.query())
  );
  const rdMe = useTaskEither(
    BE.authedTE((tkn) => BE.Api(tkn).cp.getMyProfile.query())
  );
  const rdRequests = useTaskEither(
    BE.authedTE((tkn) => BE.Api(tkn).cp.getHpRequests.query())
  );

  return (
    <div className="flex flex-col p-8 h-screen">
      <div>{`Welcome Home! `}</div>
      <button
        onClick={() => {
          signOut(firebaseJs.auth).then(() => {
            window.location.href = "/";
          });
        }}
      >
        Sign out
      </button>
      {pipe(
        sequenceT(RD.remoteData)(
          pipe(
            rdRequests,
            RD.mapLeft((r) => r as any)
          ),
          rdHomeData,
          rdMe
        ),
        RD.toOption,
        O.fold(
          () => <FullContainerLoadingSpinner />,
          ([
            { requests },
            { myTherapists, liveSessions, pastSessions, upcomingSessions },
            me,
          ]) => (
            <div className="flex flex-col gap-24">
              <h4>{me.email}</h4>
              <h4>{`${me.first_name} ${me.last_name}`}</h4>
              <div className="mt-4 flex flex-col border">
                <ListContainer
                  title="Upcoming Sessions"
                  rows={upcomingSessions.map((s) => (
                    <div className="flex gap-4">
                      <div>{s.hp_email}</div>
                    </div>
                  ))}
                />
              </div>
              <div className="flex flex-col gap-4">
                <ListContainer
                  title="My Therapists"
                  rows={myTherapists.map((t) => (
                    <Link
                      to={CP_ROUTES.DASHBOARD.HPS.HP_ID.DETAILS.buildPath({
                        hpId: t.id,
                      })}
                      key={t.id}
                      className="cursor-pointer py-4 border-b-2"
                    >
                      {`${t.firstName} ${t.lastName}`}
                    </Link>
                  ))}
                />
              </div>

              <div className="flex flex-wrap gap-8">
                {requests.map((request) => (
                  <div
                    key={request.id}
                    className="w-56 h-56 shadow-md rounded-md flex flex-col justify-center items-center p-4"
                  >
                    <h4 className="font-bold">
                      {`${request.first_name} ${request.last_name} is requesting you as client`}
                    </h4>
                    <button
                      className="bg-default-purple text-white p-2 rounded-md"
                      onClick={() => {
                        BE.authedTE((tkn) =>
                          BE.Api(tkn).cp.acceptHpRequest.mutate({
                            requestId: request.id,
                            doesAccept: true,
                          })
                        )().then((er) => {
                          console.log("RESPONDING TO REQUEST", er);
                          window.location.reload();
                        });
                      }}
                    >
                      Agree
                    </button>
                  </div>
                ))}
              </div>
              {liveSessions.length > 0 && (
                <div>
                  <h4 className="font-bold">Live Sessions</h4>
                  <div className="flex flex-wrap gap-8">
                    {liveSessions.map((session) => (
                      <Link
                        key={session.id}
                        to={CP_ROUTES.SESSIONS.SESSION_ID.LIVE.MAIN_ROOM.buildPath(
                          {
                            sessionId: session.id,
                          }
                        )}
                        className="bg-white shadow-md p-8 w-56 h-56"
                      >
                        <div className="font-bold">{session.id}</div>
                        <div className="mt-8">{session.hp_name}</div>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              {pastSessions.length > 0 && (
                <div>
                  <h4 className="font-bold">Past Sessions</h4>
                  <div className="flex flex-wrap gap-8">
                    {pastSessions.map((ps) => (
                      <Link
                        key={ps.id}
                        to={`/cp/sessions/${ps.sessionId}/review`}
                        className="bg-white shadow-md p-8 w-56 h-56 flex flex-col gap-4"
                      >
                        {ps.sessionId}
                        <div className="font-bold text-xl">{`${ps.hp_first_name} ${ps.hp_last_name}`}</div>
                        <div className="font-bold text-blue-400">
                          {format(new Date(ps.started_at!), "MMM dd yyyy")}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )
        )
      )}
    </div>
  );
};
