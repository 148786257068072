import * as Sentry from "@sentry/react";
import { CaptureContext } from "@sentry/types";

export module ErrorTracking {
  export function reportError(e: { message: string; extra?: CaptureContext }) {
    console.error(e);
    Sentry.captureMessage(e.message, e.extra);
  }

  export function captureException(e: Error) {
    console.error(e);
    Sentry.captureException(e);
  }
}
