import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTypedHash } from "react-router-typesafe-routes/dom";
import { RxO } from "../../../../../../prelude";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import {
  convertObservableToBehaviorSubject,
  useScrollToAnchor,
} from "../../../../../../util";
import { useHpState } from "../../../../hp.state";
import { SettingsContainer } from "../components/settings.components";
import { HpSettingsEditProfileForm } from "./edit-profile.form";
import { ModalitiesOfferedSelector } from "../../../../../../components/modalities-selector.fc";

export const HpDashboardSettingsAccountPage: React.FC<{}> = ({}) => {
  useScrollToAnchor();

  return (
    <div className="flex-1 flex flex-col gap-4">
      <div className="flex gap-8">
        <Sidebar />
        <div className="flex flex-col gap-4 w-[50%]">
          <PracticeInformationView />
          {/* <VidalifySubscriptionView /> */}
          <ModalitiesView />
        </div>
      </div>
    </div>
  );
};

const Sidebar: React.FC = () => {
  return (
    <div className="sticky top-0 h-[400px] w-[256px] py-6 flex flex-col gap-4">
      <div>
        <SidebarTab tab={"practice-info"} />
      </div>
      {/* <div>
        <button onClick={() => handleClick("vidalify-subscription")}>
          Vidalify Subscription
        </button>
      </div> */}
      <div>
        <SidebarTab tab="modalities" />
      </div>
    </div>
  );
};

type SideTabHashes = "practice-info" | "modalities";

function titleForTab(tab: SideTabHashes): string {
  if (tab === "practice-info") return "Practice Information";
  if (tab === "modalities") return "Modalities";
  return "";
}

const SidebarTab: React.FC<{ tab: SideTabHashes }> = ({ tab }) => {
  const curHash = useTypedHash(HP_ROUTES.MY.DASHBOARD.SETTINGS.ACCOUNT);

  return (
    <Link
      to={`${HP_ROUTES.MY.DASHBOARD.SETTINGS.ACCOUNT.buildPath(
        {},
        undefined,
        tab
      )}`}
      className="flex items-center gap-2"
    >
      <div
        className={`rounded-lg pr-4 py-2 font-sans ${
          curHash !== undefined && curHash === tab
            ? "bg-vid-black-100"
            : "bg-white"
        }`}
      >
        {titleForTab(tab)}
      </div>
    </Link>
  );
};

const PracticeInformationView: React.FC = () => {
  return (
    <SettingsContainer id="practice-info" title="Practice Information">
      <HpSettingsEditProfileForm />
    </SettingsContainer>
  );
};

// const VidalifySubscriptionView: React.FC = () => {
//   return (
//     <div id="vidalify-subscription" className="h-[500px] border rounded-lg">
//       Vidalify Subscription View
//     </div>
//   );
// };

const ModalitiesView: React.FC = () => {
  const dashboardState = useHpState();
  const myModalities$ = useMemo(
    () =>
      convertObservableToBehaviorSubject(
        dashboardState.myProfile$.pipe(RxO.map((p) => p.modalities)),
        dashboardState.myProfile$.value.modalities
      ),
    []
  );
  return (
    <SettingsContainer id="modalities" title="Modalities">
      <ModalitiesOfferedSelector
        myModalities$={myModalities$}
        onSelect={(m) => {
          dashboardState.addModality(m);
        }}
      />
    </SettingsContainer>
  );
};
