interface WelcomeItem {
  actionItemName: string;
  isChecked: boolean;
  icon: string;
}

interface WelcomeChecklistProps {
  welcomeList: WelcomeItem[];
}
export const WelcomeChecklist = ({ welcomeList }: WelcomeChecklistProps) => {
  return (
    <div className="flex flex-col p-4">
      <div className="px-2">
        <p className="text-xl font-light">Welcome</p>
        {welcomeList.map((item, i) => (
          <div key={i} id={`${i}`}>
            <div className="flex justify-between items-end py-4">
              <div className="flex">
                <img
                  className="mr-2"
                  src={
                    item.isChecked
                      ? "/dashboard/check-ok.png"
                      : "/dashboard/not-checked.png"
                  }
                ></img>
                <p className="font-light">{item.actionItemName}</p>
              </div>
              <img src={item.icon} width={20} height={20}></img>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
