import { ParticipantView, useCallStateHooks } from "@stream-io/video-react-sdk";
import { useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { match } from "ts-pattern";
import { BE } from "../../../../backend";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
  LiveSessionComponents,
} from "../../../../components/live-session/live-session.components";
import { MainRoomContainer } from "../../../../components/live-session/main-room/main-room-container";
import { MeditationToolsPanel } from "../../../../components/live-session/right-nav/therapy-tools/meditation.tools";
import { NoteToolsPanel } from "../../../../components/live-session/right-nav/therapy-tools/note-tools";
import { ReviewTools } from "../../../../components/live-session/right-nav/therapy-tools/review-tools";
import { FullContainerLoadingSpinner } from "../../../../loading";
import { O, RxO } from "../../../../prelude";
import { HP_ROUTES } from "../../../../routes/hp-routes";
import { hasScreenShare } from "../../../../util";
import {
  useHpPrivateSessionState,
  usePluckSessionState$,
} from "./session.state";

export const PrivateSessionMainRoomPage: React.FC = () => {
  const nav = useNavigate();
  const mgr = useHpPrivateSessionState();
  const { useLocalParticipant, useRemoteParticipants } = useCallStateHooks();
  const me = useLocalParticipant();
  const remoteParticipants = useRemoteParticipants();

  if (!me) {
    return <FullContainerLoadingSpinner />;
  }

  const participantInSpotlight =
    remoteParticipants.length > 0 ? remoteParticipants[0] : me;

  return (
    <MainRoomContainer
      mainRoomStateMgr={mgr.mainRoomStateMgr}
      stageContent={
        <div className="flex-1 md:aspect-16/9 min-w-0 min-h-0 relative border-2 rounded-[22px] max-w-screen">
          <ParticipantView
            participant={participantInSpotlight}
            ParticipantViewUI={<CustomParticipantViewUI />}
            trackType={
              hasScreenShare(participantInSpotlight)
                ? "screenShareTrack"
                : "videoTrack"
            }
            VideoPlaceholder={CustomVideoPlaceholder}
          />
          {remoteParticipants.length > 0 && (
            <div className="absolute bottom-8 right-8 w-[200px] aspect-16/9 z-30 rounded-[22px] border-2 ">
              <ParticipantView
                participant={me}
                trackType={
                  hasScreenShare(me) ? "screenShareTrack" : "videoTrack"
                }
                ParticipantViewUI={CustomParticipantViewUI}
                VideoPlaceholder={CustomVideoPlaceholder}
              />
            </div>
          )}
        </div>
      }
      leftControls={[
        <RecordSectionButton />,
        <LiveSessionComponents.BottomLeftButtons.NotesButton
          onNotesClick={() => {
            mgr.mainRoomStateMgr.openRightNav({
              state: "THERAPY_TOOLS",
              initialViewState: { _tag: "TOOL", tool: "notes" },
            });
          }}
        />,
        <LiveSessionComponents.BottomLeftButtons.BookmarkButton
          onBookmarkClick={() => {
            mgr.notesMgr.saveBookmark({}).then((_) => {
              mgr.notesMgr.fetchAndSetBookmarks().catch();
              mgr.mainRoomStateMgr.openRightNav({
                state: "THERAPY_TOOLS",
                initialViewState: { _tag: "TOOL", tool: "notes" },
              });
            });
          }}
        />,
      ]}
      knownTools={[
        {
          tool: "notes",
          component: (
            <NoteToolsPanel
              notes$={mgr.notesMgr.notes$}
              bookmarks={{
                rdBookmarks$: mgr.notesMgr.rdBookmarks$,
              }}
              bookends={{
                rdRecordedBookends$:
                  mgr.notesMgr.recordSectionMgr.rdRecordedBookends$,
                onBookendLabelChange: ({ bookendId, newLabel }) => {
                  mgr.notesMgr.recordSectionMgr.setBookendLabel({
                    bookendId,
                    label: newLabel,
                  });
                },
              }}
            />
          ),
        },
        {
          tool: "meditation",
          component: (
            <MeditationToolsPanel mainRoomStateMgr={mgr.mainRoomStateMgr} />
          ),
        },
        {
          tool: "review",
          component: (
            <ReviewTools
              onViewFullReview={() => {
                mgr.mainRoomStateMgr.topPreview$.next(
                  O.some({
                    _tag: "Review",
                    sessionType: { _tag: "PRIVATE", sessionId: mgr.session.id },
                  })
                );
              }}
            />
          ),
        },
      ]}
      // rightNavDisplay={(rightPanelViewState) => (
      //   <div className="flex-1 flex flex-col">
      //     {match(rightPanelViewState)
      //       .with({ state: "THERAPY_TOOLS" }, ({ initialViewState }) => (
      //         <TherapyToolsRightNav initialViewState={initialViewState} />
      //       ))
      //       .with({ state: "SETTINGS" }, () => <SettingsRightNav />)
      //       .with({ state: "NEXT_SCHEDULE_REMINDER" }, () => (
      //         <div className="flex flex-col ">
      //           <CreateNewAppointmentForm
      //             onSuccessSave={() => {
      //               mgr.mainRoomStateMgr.closeRightNav();
      //             }}
      //             mbClient={{
      //               id: mgr.session.client_id!,
      //               name: mgr.session.client_name!,
      //             }}
      //           />
      //         </div>
      //       ))
      //       .exhaustive()}
      //   </div>
      // )}
      topBarLeft={{
        memberProfilePhotos: [],
      }}
      topPreviewDisplay={(tps) =>
        match(tps)
          .with({ _tag: "Transcript" }, () => <TranscriptPreview />)
          .exhaustive()
      }
      onEndCall={() =>
        onEndCall({ nav, leaveAndEndSession: () => mgr.leaveAndEndSession() })
      }
    />
  );
};

const TranscriptPreview: React.FC = () => {
  const mgr = useHpPrivateSessionState();
  const rdTranscript = useTaskEither(
    BE.fetchEndpointTE((Api) =>
      Api.u.session.getLatestTranscript.query({ sessionId: mgr.session.id })
    )
  );
  return (
    <div>
      <div>{JSON.stringify(rdTranscript)}</div>
    </div>
  );
};

async function onEndCall(p: {
  nav: NavigateFunction;
  leaveAndEndSession: () => Promise<void>;
}) {
  await p.leaveAndEndSession();
  p.nav(HP_ROUTES.MY.DASHBOARD.INVOICES.path);
}

const RecordSectionButton: React.FC = () => {
  const mgr = useHpPrivateSessionState();
  const isRecordingSectionId$ = usePluckSessionState$(
    mgr.remoteStateMgr.sessionState$,
    "isRecordingSectionId"
  );

  const isRecordingSection$ = useMemo(
    () => isRecordingSectionId$.pipe(RxO.map((isr) => isr !== null)),
    []
  );
  return (
    <LiveSessionComponents.BottomLeftButtons.RecordSectionButton
      isRecording$={isRecordingSection$}
      onStartClick={() => {
        mgr.notesMgr.recordSectionMgr.startSectionRecording();
      }}
      onStopClick={() => {
        mgr.notesMgr.recordSectionMgr.stopSectionRecording().then((_) => {
          mgr.notesMgr.recordSectionMgr.fetchAndSetRecordedBookends().catch();
          mgr.mainRoomStateMgr.openRightNav({
            state: "THERAPY_TOOLS",
            initialViewState: { _tag: "TOOL", tool: "notes" },
          });
        });
      }}
    />
  );
};
