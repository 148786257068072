import { pipe } from "fp-ts/lib/function";
import { useObservableState } from "observable-hooks";
import {
  FormattedTranscriptJson,
  FormattedTranscriptJsonSegment,
  NotesAndBookmarks,
} from "shared";
import { RD, Rx } from "shared/base-prelude";
import { FullContainerLoadingSpinner } from "../../../loading";
import { BookmarksCard } from "../../live-session/bookmark.components";
import { AudioPlayer } from "../audio-player.fc";

export const TranscriptTab: React.FC<{
  rdMbTranscript$: Rx.Observable<
    RD.RemoteData<any, FormattedTranscriptJson | null>
  >;
  rdNotesAndBookmarks$: Rx.Observable<RD.RemoteData<any, NotesAndBookmarks>>;
}> = ({ rdMbTranscript$, rdNotesAndBookmarks$ }) => {
  const rdMbTranscript = useObservableState(rdMbTranscript$, RD.initial);
  return (
    <div className="flex-1 flex gap-4 overflow-hidden">
      <BookmarksCard rdNotesAndBookmarks$={rdNotesAndBookmarks$} />
      <div className="flex flex-col gap-4 self-stretch overflow-hidden">
        <AudioPlayer />

        {pipe(
          rdMbTranscript,
          RD.fold(
            () => <></>,
            () => <FullContainerLoadingSpinner />,
            (e) => <div>{JSON.stringify(e)}</div>,
            (mbTranscript) =>
              mbTranscript ? (
                <FullTranscriptScrollView items={mbTranscript} />
              ) : (
                <div>There was an issue getting the transcript</div>
              )
          )
        )}
      </div>
    </div>
  );
};

export const FullTranscriptScrollView: React.FC<{
  items: FormattedTranscriptJsonSegment[];
}> = ({ items }) => {
  return (
    <div className="flex-1 flex flex-col overflow-y-scroll ">
      {items.map((item) => (
        <TranscriptSpeakerCard key={item.secondsIntoStart} {...item} />
      ))}
    </div>
  );
};

const TranscriptSpeakerCard: React.FC<FormattedTranscriptJsonSegment> = ({
  speaker,
  secondsIntoStart,
  text,
}) => {
  return (
    <div className="w-[624px] basis-48 min-h-48 grow-0 shrink-0 p-6 rounded-xl border border-zinc-200 flex flex-col justify-start items-start gap-3">
      <div className="self-stretch justify-between items-center flex">
        <div className="flex justify-between items-center ">
          <div className="text-center text-gray-900 text-base font-normal font-['Roboto Flex'] leading-tight">
            {speaker}
          </div>
        </div>
        <div className="text-center text-zinc-500 text-sm font-normal font-['Roboto Flex'] leading-[16.80px]">
          {minutesToHourMinuteFormat(secondsIntoStart)}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto self-stretch text-gray-900 text-base font-normal font-['Roboto Flex'] leading-normal">
        {text}
      </div>
    </div>
  );
};

function minutesToHourMinuteFormat(secondsIntoStartP: number): string {
  const minutesIntoStart = Math.floor(secondsIntoStartP / 60);
  const secondsIntoStart = Math.floor(secondsIntoStartP % 60);
  return `${minutesIntoStart}:${secondsIntoStart.toString().padStart(2, "0")}`;
}

// const BookmarksCard: React.FC<{
//   savedBookmarks: SavedBookmark[];
//   recordedSections: Bookend[];
// }> = ({ savedBookmarks, recordedSections }) => {
//   return (
//     <div className="w-[298px] h-[311px] px-3 pt-6 pb-[17px] bg-white rounded-xl border border-zinc-200 flex-col justify-start items-start gap-4 inline-flex">
//       <div className="self-stretch px-3 justify-between items-center inline-flex">
//         <div className="text-center text-gray-900 text-base font-normal font-['Roboto Flex'] leading-tight">
//           Bookmarks
//         </div>
//         <div className="w-4 h-4 relative">
//           <div className="left-[6px] top-[0.67px] absolute flex-col justify-start items-start gap-[1.33px] inline-flex">
//             <div className="w-1 h-1 bg-zinc-200 rounded-full" />
//             <div className="w-1 h-1 bg-zinc-200 rounded-full" />
//             <div className="w-1 h-1 bg-zinc-200 rounded-full" />
//           </div>
//         </div>
//       </div>
//       <div className="self-stretch py-3 bg-white rounded-xl shadow flex-col justify-start items-start flex">
//         <div className="self-stretch px-3 justify-between items-center flex flex-col gap-4">
//           {savedBookmarks.map((_) => (
//             <></>
//             // <SavedBookmarkItem bookmark={sb} />
//           ))}
//           <div className="flex flex-col gap-4">
//             {recordedSections.map((rs) => (
//               <RecordedSectionItem recordedSection={rs} />
//             ))}
//           </div>
//         </div>
//       </div>
//       {/* <div className="self-stretch h-[38px] px-3 py-[9px] rounded-xl flex-col justify-start items-start flex">
//         <div className="self-stretch justify-start items-center gap-[79px] inline-flex">
//           <div className="justify-start items-center gap-3 flex">
//             <div className="w-5 h-5 relative">
//               <div className="w-5 h-5 left-0 top-0 absolute bg-gray-900 rounded-md" />
//             </div>
//             <div className="flex-col justify-center items-start gap-[3px] inline-flex">
//               <div className="text-center text-gray-900 text-sm font-normal font-['Roboto Flex'] leading-[16.80px]">
//                 Send bookmarks to client
//               </div>
//             </div>
//           </div>
//         </div>
//       </div> */}
//     </div>
//   );
// };
