import { Menu, Transition } from "@headlessui/react";
import { addDays, addMonths, format, subDays, subMonths } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import React, { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { Appointment } from "shared";
import { useAuthedFetchTE } from "shared/backend-api/api.mgr";
import { Arr } from "shared/base-prelude";
import { createContextAndHook } from "shared/util";
import { InversePrimaryButton } from "../../../../../components/primitives/button";
import { FullContainerLoadingSpinner } from "../../../../../loading";
import { O, RD, Rx, RxO } from "../../../../../prelude";
import { HP_ROUTES } from "../../../../../routes/hp-routes";
import { useHpState } from "../../../hp.state";
import { DayViewCalendar } from "./calendar-day-view.fc";
import { CalendarMonthView } from "./calendar-month-view.fc";

type ViewState = { _tag: "DAY" } | { _tag: "MONTH" };
class StateMgr {
  allAppointments: Appointment[];
  viewState$ = new Rx.BehaviorSubject<ViewState>({ _tag: "MONTH" });
  dayViewStateMgr: DayViewStateMgr;
  monthViewStateMgr = new MonthViewStateMgr();

  constructor(allAppointments: Appointment[]) {
    this.allAppointments = allAppointments;
    this.dayViewStateMgr = new DayViewStateMgr(allAppointments);
  }

  toggleView = () => {
    this.viewState$.next(
      this.viewState$.value._tag === "DAY" ? { _tag: "MONTH" } : { _tag: "DAY" }
    );
  };
}

class DayViewStateMgr {
  allAppointments: Appointment[];
  currentDay$ = new Rx.BehaviorSubject(new Date());
  appointmentsForCurrentDay$: Rx.Observable<Appointment[]>;

  constructor(allAppointments: Appointment[]) {
    this.allAppointments = allAppointments;

    this.appointmentsForCurrentDay$ = this.currentDay$.pipe(
      RxO.map((curDay) =>
        allAppointments.filter((a) => a.date.getDate() === curDay.getDate())
      )
    );
  }

  nextDay = () => {
    this.currentDay$.next(addDays(this.currentDay$.value, 1));
  };

  prevDay = () => {
    this.currentDay$.next(subDays(this.currentDay$.value, 1));
  };
}

class MonthViewStateMgr {
  currentMonth$ = new Rx.BehaviorSubject(new Date());

  nextMonth = () => {
    this.currentMonth$.next(addMonths(this.currentMonth$.value, 1));
  };

  prevMonth = () => {
    this.currentMonth$.next(subMonths(this.currentMonth$.value, 1));
  };
}

const [StateMgrContext, useModel] = createContextAndHook<StateMgr>("StateMgr");

export const HpDashboardCalendarPage: React.FC = () => {
  const rdAppointments = useAuthedFetchTE(
    (Api) => Api.hp.calendar.getAppointmentsInWindow.query({}),
    []
  );

  return pipe(
    rdAppointments,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (allAppointments) => (
        <HpDashboardCalendarLoadedPage allAppointments={allAppointments} />
      )
    )
  );
};

const HpDashboardCalendarLoadedPage: React.FC<{
  allAppointments: Appointment[];
}> = ({ allAppointments }) => {
  const queryParams = useTypedSearchParams(HP_ROUTES.MY.DASHBOARD.CALENDAR);
  const [_, setSearchParams] = useSearchParams();
  const hpState = useHpState();
  const model = React.useMemo(
    () => new StateMgr(allAppointments),
    [allAppointments]
  );
  const viewState = useObservableEagerState(model.viewState$);

  useEffect(() => {
    if (queryParams.length > 0) {
      const [{ action, clientId, clientName, requestApptId }] = queryParams;
      if (action === "new-appointment") {
        const client = pipe(
          [clientId, clientName],
          Arr.map(O.fromNullable),
          Arr.sequence(O.option),
          O.map(([clientId, clientName]) => ({
            id: clientId,
            name: clientName,
          })),
          O.toUndefined
        );
        hpState.openRightNav({
          _tag: "NEW_APPOINTMENT",
          client,
          requestApptId,
        });
        setSearchParams({});

        // window.history.replaceState(
        //   {},
        //   document.title,
        //   window.location.pathname
        // );
      }
    }
  }, [queryParams]);

  return (
    <StateMgrContext.Provider value={model}>
      <div className="p-8 flex-1 flex flex-col">
        <CalendarNavbar />
        {viewState._tag === "DAY" ? (
          <DayViewCalendar
            // currentDay$={model.dayViewStateMgr.currentDay$}
            appointmentsForCurrentDay$={
              model.dayViewStateMgr.appointmentsForCurrentDay$
            }
          />
        ) : (
          <CalendarMonthView
            currentDay$={model.dayViewStateMgr.currentDay$}
            currentMonth$={model.monthViewStateMgr.currentMonth$}
          />
        )}
      </div>
    </StateMgrContext.Provider>
  );
};

const DayMonthViewToggleButton: React.FC = () => {
  const stateMgr = useModel();
  const viewState = useObservableEagerState(stateMgr.viewState$);
  return (
    <Menu as="div" className="relative flex">
      <div>
        <Menu.Button className="w-full h-full justify-center rounded-xl px-4 py-2 text-sm border text-black hover:bg-vid-gray/10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/7 flex items-center">
          <p className="mr-2">{viewState._tag === "DAY" ? "Day" : "Month"}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.6 7.45837L11.1666 12.8917C10.525 13.5334 9.47496 13.5334 8.8333 12.8917L3.39996 7.45837"
              stroke="#161616"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    stateMgr.toggleView();
                  }}
                >
                  Month
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    stateMgr.toggleView();
                  }}
                >
                  Day
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const CalendarNavbar: React.FC = () => {
  const stateMgr = useModel();
  const viewState = useObservableEagerState(stateMgr.viewState$);
  const dashboardModel = useHpState();

  return (
    <div className="flex justify-between items-center mx-4">
      {viewState._tag === "DAY" ? (
        <DayViewDayToggles />
      ) : (
        <CalendarMonthViewDateToggles />
      )}
      <div className="flex gap-4">
        <DayMonthViewToggleButton />
        <InversePrimaryButton
          title={"New appointment"}
          onClick={() => {
            dashboardModel.openRightNav({ _tag: "NEW_APPOINTMENT" });
          }}
        />
      </div>
    </div>
  );
};

const DayViewDayToggles: React.FC = () => {
  const model = useModel().dayViewStateMgr;
  const currentDay = useObservableEagerState(model.currentDay$);

  return (
    <div className="flex gap-8 items-center">
      <h1 className="font-bold">{format(currentDay, "EEEE, MMMM d, yyyy")}</h1>
      <div className="flex gap-4">
        <button
          onClick={() => {
            model.prevDay();
          }}
        >
          Prev
        </button>
        <button
          onClick={() => {
            model.nextDay();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
              stroke="#1D1626"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const CalendarMonthViewDateToggles: React.FC = () => {
  const model = useModel().monthViewStateMgr;
  const currentMonth = useObservableEagerState(model.currentMonth$);

  return (
    <div className="flex gap-8 items-center">
      <h1 className="font-sans font-light text-xl">
        {format(currentMonth, "MMMM")}
      </h1>
      <div className="flex gap-4">
        <button
          onClick={() => {
            model.prevMonth();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
              stroke="#1D1626"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="scale(-1,1)"
            />
          </svg>
        </button>
        <button
          onClick={() => {
            model.nextMonth();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
              stroke="#1D1626"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
