import { Effect } from "effect";
import { TherapyToolMenuViewState } from "shared";
import { O, Rx, erp } from "shared/base-prelude";
import {
  KnownMeditationVisual,
  MeditationState,
  MeditationStateSchema,
  defaultMeditationState,
} from "shared/session-state/session-state";
import { match } from "ts-pattern";
import zu from "zod_utilz";
import { RxO } from "../../../prelude";
import { FirestoreSessionStateMgmt } from "../firestore-live-session.mgmt";

export type OpenRightPanelViewState =
  | {
      state: "THERAPY_TOOLS";
      initialViewState?: TherapyToolMenuViewState;
    }
  | { state: "SETTINGS" }
  | { state: "NEXT_SCHEDULE_REMINDER" };

export type TopPreviewContent<Extra> =
  | { _tag: "Meditation"; videoSrc?: string; minutes: number; seconds: number }
  | {
      _tag: "Review";
      sessionType:
        | { _tag: "PRIVATE"; sessionId: string }
        | { _tag: "GROUP"; groupSessionId: string };
    }
  | { _tag: "Extra"; extraState: Extra };

export interface SpecialClockData {
  secondsLeft: number;
  description?: string;
}

export type BaseRegisterPingRes = {
  timeLeftSeconds: number | null;
  mbShowNearEndSessionTimer: string | null;
};

export class MainRoomStateMgr<
  RegisterPingRes extends BaseRegisterPingRes,
  TopPreviewExtraState,
  ExtraRemoteState
> {
  rightNav$ = new Rx.BehaviorSubject<O.Option<OpenRightPanelViewState>>(O.none);
  isLeftNavToolsOpen$ = new Rx.BehaviorSubject<boolean>(true);
  topPreview$ = new Rx.BehaviorSubject<
    O.Option<TopPreviewContent<TopPreviewExtraState>>
  >(O.none);
  isTopPreviewMaximized$ = new Rx.BehaviorSubject<boolean>(false);
  remoteStateMgr: FirestoreSessionStateMgmt<ExtraRemoteState>;

  pingInterval: Timer | null = null;
  pingResult$ = new Rx.ReplaySubject<RegisterPingRes>();
  sendPing: () => void;
  timeLeftMinutes$ = this.pingResult$.pipe(
    RxO.map((pr) => pr.timeLeftSeconds),
    RxO.map((tls) => (tls ? Math.floor(tls / 60) : null))
  );

  tools: {
    meditationMgr: MeditationMgr<ExtraRemoteState>;
  };

  mbShowSpecialClock$: Rx.Observable<SpecialClockData | null>;

  constructor(p: {
    sendPingEff: Effect.Effect<RegisterPingRes, unknown, never>;
    remoteStateMgr: FirestoreSessionStateMgmt<ExtraRemoteState>;
  }) {
    this.remoteStateMgr = p.remoteStateMgr;
    this.sendPing = () => {
      erp(p.sendPingEff).then((pr) => {
        this.pingResult$.next(pr);
      });
    };
    this.pingInterval = setInterval(() => {
      console.log("SENDING PING!");
      this.sendPing();
    }, 1000 * 60);

    this.tools = {
      meditationMgr: new MeditationMgr(p.remoteStateMgr),
    };

    this.mbShowSpecialClock$ = Rx.combineLatest([
      this.pingResult$,
      this.tools.meditationMgr.countdownTimerSeconds$,
    ]).pipe(
      RxO.map(([pr, timerSeconds]) => {
        if (timerSeconds) {
          return {
            secondsLeft: timerSeconds,
            description: "test",
          } as SpecialClockData;
        } else if (pr.mbShowNearEndSessionTimer && pr.timeLeftSeconds) {
          return {
            secondsLeft: pr.timeLeftSeconds,
            description: "test",
          } as SpecialClockData;
        } else {
          return null;
        }
      })
    );
  }

  closeRightNav() {
    this.rightNav$.next(O.none);
  }

  openRightNav(rightNav: OpenRightPanelViewState) {
    this.rightNav$.next(O.some(rightNav));
  }

  toggleTopPreview(
    curTopPreview: O.Option<TopPreviewContent<TopPreviewExtraState>>,
    newTopPreview: TopPreviewContent<TopPreviewExtraState>
  ) {
    if (O.isSome(curTopPreview)) {
      this.closeTopPreview();
    } else {
      this.openTopPreview(newTopPreview);
    }
  }

  openTopPreview(topPreview: TopPreviewContent<TopPreviewExtraState>) {
    this.topPreview$.next(O.some(topPreview));
  }

  closeTopPreview() {
    this.topPreview$.next(O.none);
  }

  toggleLeftToolsNav() {
    this.isLeftNavToolsOpen$.next(!this.isLeftNavToolsOpen$.value);
  }
}

type PlayState = "PLAYING" | "PAUSED" | "STOPPED";

class MeditationMgr<ExtraRemoteState> {
  selectedInputs$ = new Rx.BehaviorSubject<{
    timerMinutes: number | null;
    visual: KnownMeditationVisual | null;
    playState: PlayState;
  }>({
    timerMinutes: 5,
    visual: "None",
    playState: "STOPPED",
  });
  remoteState$: Rx.Observable<MeditationState>;

  countdownTimerSeconds$ = new Rx.BehaviorSubject<number | null>(null);
  countdownTimerInterval: Timer | null = null;

  constructor(
    readonly remoteStateMgr: FirestoreSessionStateMgmt<ExtraRemoteState>
  ) {
    remoteStateMgr.getCurSessionState().then((curState) => {
      console.log("CUR STATE", curState);
    });
    this.remoteState$ = remoteStateMgr.sessionState$.pipe(
      RxO.map((ss) => {
        if (ss.meditationState === null) {
          return defaultMeditationState;
        } else {
          return MeditationStateSchema.parse(
            zu.stringToJSON().parse(ss.meditationState)
          );
        }
      })
    );
    Rx.combineLatest([this.remoteState$, this.selectedInputs$]).subscribe(
      ([{ playState }, selectedInputs]) => {
        // p.remoteStateMgr.updateSessionState({ meditationState});
        match(playState)
          .with("PLAYING", () => {
            if (playState === "PLAYING") {
              this.countdownTimerInterval = setInterval(() => {
                if (this.countdownTimerSeconds$.value) {
                  this.countdownTimerSeconds$.next(
                    this.countdownTimerSeconds$.value - 1
                  );
                } else {
                  if (selectedInputs.timerMinutes) {
                    this.countdownTimerSeconds$.next(
                      selectedInputs.timerMinutes * 60
                    );
                  }
                }
              }, 1000);
            }
          })
          .with("PAUSED", () => {
            if (this.countdownTimerInterval) {
              clearInterval(this.countdownTimerInterval);
              this.countdownTimerInterval = null;
            }
          })
          .with("STOPPED", () => {
            if (this.countdownTimerInterval) {
              clearInterval(this.countdownTimerInterval);
            }

            this.countdownTimerSeconds$.next(null);
            this.countdownTimerInterval = null;
          })
          .exhaustive();
      }
    );

    this.setInitialState();
  }

  updatedRemoteSessionState(partial: Partial<MeditationState>) {
    this.remoteStateMgr.updateWithRemoteSessionState((curState) => {
      console.log("CUR STATE! ", curState);
      if (curState.meditationState === null) {
        const z = {
          meditationState: JSON.stringify({
            ...defaultMeditationState,
            ...partial,
          }),
        };
        console.log("Z", z);
        return z;
      } else {
        const curMeditationState = MeditationStateSchema.parse(
          zu.stringToJSON().parse(curState.meditationState)
        );
        return {
          meditationState: JSON.stringify({
            ...curMeditationState,
            ...partial,
          }),
        };
      }
    });
  }

  setInitialState() {
    this.updatedRemoteSessionState(defaultMeditationState);
  }

  setPlayState(playState: PlayState) {
    this.updatedRemoteSessionState({ playState });
  }
}
