import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { HP_ROUTES } from "../../../../../routes/hp-routes";
import { useEffect } from "react";

export const HpSettingsLayout: React.FC = () => {
  const nav = useNavigate();
  const pathname = useLocation().pathname;

  useEffect(() => {
    // if the pathname is just /settings, redirect to /settings/account
    if (pathname === HP_ROUTES.MY.DASHBOARD.SETTINGS.path) {
      nav(
        HP_ROUTES.MY.DASHBOARD.SETTINGS.ACCOUNT.buildPath(
          {},
          undefined,
          "practice-info"
        )
      );
    }
  }, [pathname]);
  return (
    <div className="flex flex-col  p-8">
      <div className="flex gap-32">
        <TabLink
          to={`${HP_ROUTES.MY.DASHBOARD.SETTINGS.ACCOUNT.path}#practice-info`}
        >
          Account
        </TabLink>
        <TabLink
          to={HP_ROUTES.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING.path}
        >
          Clients And Scheduling
        </TabLink>
        <TabLink to={HP_ROUTES.MY.DASHBOARD.SETTINGS.BILLING.path}>
          Billing
        </TabLink>
      </div>
      <div className="flex-1 flex flex-col pt-8">
        <Outlet />
      </div>
    </div>
  );
};

const TabLink: React.FC<{ to: string; children: string }> = ({
  to,
  children,
}) => {
  return (
    <Link to={to} className="text-blue-500 text-2xl font-bold">
      {children}
    </Link>
  );
};
