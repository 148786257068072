import { format } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { BE } from "../../../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../../../loading";
import { O, RD } from "../../../../../../prelude";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import { useHpState } from "../../../../hp.state";

type ClientInfoResp = UApiOutput["hp"]["clients"]["client"]["getClient"];

export const HpDashboardClientInfoPage: React.FC = () => {
  const dashboardState = useHpState();
  const { clientId } = useTypedParams(HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID);
  const [rdClient, setRdClient] = useState<RD.RemoteData<any, ClientInfoResp>>(
    RD.initial
  );

  useEffect(() => {
    if (clientId === undefined) return;
    setRdClient(RD.pending);
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.clients.client.getClient.query({ clientId })
    )().then((er) => {
      setRdClient(RD.fromEither(er));
    });
  }, []);

  return (
    <div className="w-full flex flex-col p-8">
      {pipe(
        rdClient,
        RD.toOption,
        O.fold(
          () => <FullContainerLoadingSpinner />,
          ({ client, pastSessions }) => (
            <div className="flex flex-col gap-8">
              <h4 className="font-bold text-2xl text-btn-purple">
                {client.first_name}
              </h4>
              <h4 className="font-bold text-2xl text-btn-purple">
                {client.email}
              </h4>
              <button
                className="w-[200px] ml-4 bg-vid-purple text-center text-white p-3 mb-4 rounded-2xl"
                onClick={() => {
                  dashboardState.rightNav$.next(
                    O.some({ _tag: "START_CLIENT_SESSION", client })
                  );
                }}
              >
                Begin session
              </button>
              {/* {lastSessionReview && (
                <div className="flex flex-col gap-8">
                  <h1 className="font-bold text-xl mt-8">
                    Last Session Review
                  </h1>
                  <ReviewPageContent
                    sessionType={{
                      _tag: "PRIVATE",
                      sessionId: lastSessionReview.,
                    }}
                  />
                </div>
              )} */}
              <h1 className="font-bold text-xl mt-8">Past Sessions</h1>
              {pastSessions.map((ps) => (
                <Link
                  to={`/hp/my/sessions/${ps.id}/review`}
                  className="w-64 p-8 border-2 shadow-md bg-white rounded-md flex flex-col gap-8 cursor-pointer"
                >
                  <div>{ps.id}</div>
                  <div className="flex flex-col gap-4">
                    <div>{`Started: ${format(
                      new Date(ps.started_at!),
                      "MMM do hh:mm"
                    )}`}</div>
                    {ps.ended_at && (
                      <div>{`Ended: ${format(
                        new Date(ps.ended_at),
                        "MMM do hh:mm"
                      )}`}</div>
                    )}
                  </div>
                  {/* <video src={recording} controls className="w-[40%]" /> */}
                </Link>
              ))}
            </div>
          )
        )
      )}
    </div>
  );
};
