import {
  InputDeviceStatus,
  PaginatedGridLayout,
} from "@stream-io/video-react-sdk";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { taskEitherToEff } from "shared/base-prelude";
import { createContextAndHook } from "shared/util";
import { BE } from "../../../../backend";
import { MainRoomStateMgr } from "../../../../mgrs/live-session/main-room/main-room.statemgr";
import { Rx, RxO } from "../../../../prelude";
import { HP_ROUTES } from "../../../../routes/hp-routes";
import { MainRoomGroupSpeakerViewLayout } from "./main-room/layouts/speaker-view";
import { FirestoreSessionStateMgmt } from "../../../../mgrs/live-session/firestore-live-session.mgmt";
import {
  defaultGroupSessionState,
  GroupSessionStateSchema,
  GroupSessionStateSpecific,
} from "shared/session-state/session-state";
import { FirebaseJsMgr } from "shared/firebase";

const LayoutMap = {
  Speaker: {
    component: MainRoomGroupSpeakerViewLayout,
    title: "Speaker layout with spotlight",
    props: {},
  },
  PaginatedGrid: {
    component: PaginatedGridLayout,
    title: "Paginated grid layout",
    props: {
      groupSize: 12,
    },
  },
};

type KnownLayout = keyof typeof LayoutMap;

type GetGroupSessionResp = UApiOutput["u"]["groupSession"]["getGroupSession"];
type RegisterGroupSessionPingResp =
  UApiOutput["u"]["groupSession"]["registerGroupSessionPing"];

type TopPreviewState = {};

export class GroupSessionState {
  selectedLayout$ = new Rx.BehaviorSubject<KnownLayout>("PaginatedGrid");
  screenshareState$ = new Rx.BehaviorSubject<InputDeviceStatus | null>(null);

  currentLayout$: Rx.Observable<KnownLayout>;

  remoteStateMgr: FirestoreSessionStateMgmt<GroupSessionStateSpecific>;

  mainRoomMgr: MainRoomStateMgr<
    RegisterGroupSessionPingResp,
    TopPreviewState,
    GroupSessionStateSpecific
  >;

  constructor(
    readonly groupSessionData: GetGroupSessionResp,
    firebaseJsMgr: FirebaseJsMgr
  ) {
    this.remoteStateMgr =
      new FirestoreSessionStateMgmt<GroupSessionStateSpecific>(
        firebaseJsMgr,
        { _tag: "GROUP", groupSessionId: groupSessionData.callId },
        defaultGroupSessionState,
        GroupSessionStateSchema
      );

    this.mainRoomMgr = new MainRoomStateMgr<
      RegisterGroupSessionPingResp,
      TopPreviewState,
      GroupSessionStateSpecific
    >({
      sendPingEff: taskEitherToEff(
        BE.fetchEndpointTE((Api) =>
          Api.u.groupSession.registerGroupSessionPing.mutate({
            groupSessionId: this.groupSessionData.callId,
          })
        )
      ),
      remoteStateMgr: this.remoteStateMgr,
    });
    this.currentLayout$ = Rx.combineLatest([
      this.selectedLayout$,
      this.screenshareState$,
    ]).pipe(
      RxO.map(([layout, screenshareState]) => {
        if (screenshareState === null) {
          return layout;
        }
        if (screenshareState === "enabled") {
          return "Speaker";
        }
        return layout;
      })
    );
  }
}

export const [GroupSessionStateContext, useGroupSessionState] =
  createContextAndHook<GroupSessionState>();

export function useGroupSessionId() {
  const groupSessionId = useTypedParams(
    HP_ROUTES.MY.GROUP_SESSIONS.GROUP_SESSION_ID
  ).groupSessionId!;

  return groupSessionId;
}
