import { UApiOutput } from "shared/backend-api/trpc-cli";
import { match } from "ts-pattern";
import { BE } from "../../../../../../backend";
import { RD, Rx } from "../../../../../../prelude";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import { createContextAndHook } from "shared/util";

type CommunityInfo = UApiOutput["hp"]["community"]["getCommunity"];
type ListPostsResponse = UApiOutput["hp"]["community"]["listRecentPosts"];
type AllMembersList = UApiOutput["hp"]["community"]["listAllMembers"];

export type CommunityPageTab = "Discussion" | "Learning" | "Members" | "Events";

export class CommunitySam {
  community: CommunityInfo;
  rdRecentPosts$ = new Rx.BehaviorSubject<
    RD.RemoteData<unknown, ListPostsResponse>
  >(RD.initial);

  rdAllMembers$ = new Rx.BehaviorSubject<
    RD.RemoteData<unknown, AllMembersList>
  >(RD.initial);

  constructor(community: CommunityInfo) {
    this.community = community;

    this.fetchAndSetRecentPosts();
    this.fetchAndSetAllMembers();
  }

  tabFromLocation(location: Location): CommunityPageTab {
    return match(location.pathname)
      .with(
        HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION
          .path,
        () => "Discussion" as CommunityPageTab
      )
      .with(
        HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.LEARNING
          .path,
        () => "Learning" as CommunityPageTab
      )
      .with(
        HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.MEMBERS.path,
        () => "Members" as CommunityPageTab
      )
      .with(
        HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.path,
        () => "Events" as CommunityPageTab
      )
      .otherwise(() => "Discussion" as CommunityPageTab);
  }

  fetchAndSetRecentPosts() {
    this.rdRecentPosts$.next(RD.pending);

    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.community.listRecentPosts.query({
        communitySlug: this.community.slug,
      })
    )().then((er) => {
      this.rdRecentPosts$.next(RD.fromEither(er));
    });
  }

  fetchAndSetAllMembers() {
    this.rdAllMembers$.next(RD.pending);

    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.community.listAllMembers.query({
        communitySlug: this.community.slug,
      })
    )().then((er) => {
      this.rdAllMembers$.next(RD.fromEither(er));
    });
  }
}

export const [CommunitySamContext, useCommunitySam] =
  createContextAndHook<CommunitySam>("CommunitySam");
