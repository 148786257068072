import { Link, Outlet } from "react-router-dom";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useMemo } from "react";
import {
  HpClientDashboardContext,
  HpClientDashboardState,
} from "./[clientId].state";

export const HpDashboardClientLayout: React.FC = () => {
  const clientId = useTypedParams(
    HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID
  ).clientId;

  const clientDashboardState = useMemo(
    () => new HpClientDashboardState(clientId),
    [clientId]
  );

  return (
    <HpClientDashboardContext.Provider value={clientDashboardState}>
      <div className="flex-1 flex flex-col p-8">
        <div className="flex gap-4">
          <Link
            to={HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.INFO.buildPath({
              clientId,
            })}
            className="text-neutral-700 hover:text-neutral-900 text-2xl font-semibold"
          >
            Info
          </Link>
          <Link
            to={HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.CHAT.buildPath({
              clientId,
            })}
            className="text-neutral-700 hover:text-neutral-900 text-2xl font-semibold"
          >
            Messages
          </Link>
        </div>
        <Outlet />
      </div>
    </HpClientDashboardContext.Provider>
  );
};
