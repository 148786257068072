import { signOut } from "firebase/auth";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { E, O } from "shared/base-prelude";
import { useFirebaseJs } from "shared/firebase";
import { VidalifySvgIcon } from "../../../../assets/vidalify-logo.fc";
import { BE } from "../../../../backend";
import { ProfileButton } from "../../../../components/dashboard/Profile";
import { Search } from "../../../../components/dashboard/Search";
import { DashboardFCs } from "../../../../components/dashboard/dashboard.components";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "../../../../components/primitives/button";
import { FullContainerLoadingSpinner } from "../../../../loading";
import { RD, RxO } from "../../../../prelude";
import { HP_ROUTES } from "../../../../routes/hp-routes";
import { HpStateContext, TopAlert, useHpState } from "../../hp.state";
import { RightNav } from "./right-drawer-action/right-nav";

const Navbar: React.FC<{ currentDashboardPage: string }> = ({
  currentDashboardPage,
}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  return (
    <div className="flex justify-between py-4 px-8 border border-b-[1px] border-x-0 border-t-0">
      <div className="font-medium font-outfit text-4xl leading-relaxed	">
        {currentDashboardPage}
      </div>
      <Search />
      <div className="lg:hidden">
        <HamburgerButton
          onClick={() => {
            console.log("clicked");
            setIsHamburgerOpen((o) => !o);
          }}
        />
      </div>
      {isHamburgerOpen && (
        <HamburgerMenu
          isOpen={isHamburgerOpen}
          close={() => {
            setIsHamburgerOpen(false);
          }}
        />
      )}
      <ProfileButton />
    </div>
  );
};

export const HpMyDashboardLayout: React.FC = () => {
  const curUrl = useLocation().pathname;
  const hpState = useHpState();
  const firebaseJs = useFirebaseJs();
  const [showBottomToast, setShowBottomToast] = useState<O.Option<string>>(
    O.none
  );
  const [showTopAlert, setShowTopAlert] = useState<O.Option<TopAlert>>(O.none);
  const [showReloading, setShowReloading] = useState(false);
  const isRightNavOpen = useObservableEagerState(
    hpState.rightNav$.pipe(RxO.map((rn) => O.isSome(rn)))
  );
  useEffect(() => {
    hpState.refreshMyProfile();

    hpState.showBottomToastSignal$.subscribe((toast) => {
      setShowBottomToast(O.some(toast.msg));

      if (toast.reload) {
        setShowReloading(true);
        setTimeout(() => {
          setShowReloading(false);
        }, 300);
      }
      setTimeout(() => {
        setShowBottomToast(O.none);
      }, 3000);
    });

    hpState.showTopAlert$.subscribe((alert) => {
      if (O.isSome(alert)) {
        setShowTopAlert(O.some(alert.value));

        if (alert.value.durationSeconds) {
          setTimeout(() => {
            setShowTopAlert(O.none);
          }, alert.value.durationSeconds * 1000);
        }
      } else {
        setShowTopAlert(O.none);
      }
    });

    hpState.checkIfIsInLiveSession().then((isInSession) => {
      if (O.isSome(isInSession)) {
        hpState.showTopAlert({
          msg: "You have one unended session",
          actionContent: (
            <div className="mt-4 flex gap-4">
              <InversePrimaryButton
                title="End session"
                onClick={() => {
                  BE.fetchEndpointTE((Api) =>
                    Api.hp.sessions.liveSession.endSession.mutate({
                      sessionId: isInSession.value,
                      fromOutside: true,
                    })
                  )().then((er) => {
                    if (E.isRight(er)) {
                      hpState.showBottomToast({
                        msg: "Ended Session",
                        // reload: true,
                      });
                      hpState.hideTopAlert();
                    }
                  });
                }}
              />
              <PrimaryButton
                title="Rejoin session"
                onClick={() => {
                  window.location.href =
                    HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID.LIVE.MAIN_ROOM.buildPath(
                      {
                        sessionId: isInSession.value,
                      }
                    );
                }}
              />
            </div>
          ),
          onClose() {},
        });
      }
    });
  }, []);

  return (
    <HpStateContext.Provider value={hpState}>
      <div className="w-screen h-screen flex flex-col md:flex-row relative">
        {O.isSome(showTopAlert) && (
          <div className="absolute top-0 left-0 right-0 h-[200px] bg-orange-600 z-50 flex">
            <div className="flex-1 h-full flex flex-col justify-center items-center relative">
              <div
                className="absolute top-4 right-4 h-[100px] aspect-square z-40 flex items-center justify-center rounded-full border border-white"
                onClick={() => {
                  hpState.hideTopAlert();
                }}
              >
                <img src="/close.svg" width={30} height={30} />
              </div>
              <h4 className="text-white font-bold text-2xl">
                {showTopAlert.value.msg}
              </h4>
              {showTopAlert.value.actionContent &&
                showTopAlert.value.actionContent}
            </div>
          </div>
        )}
        <div className="hidden md:flex md:flex-col absolute top-0 left-0 z-20 max-h-screen w-[230px]">
          <DashboardFCs.LeftMenu
            onLogoClick={() => {
              window.location.href = "/hp/my/dashboard";
            }}
            onSignout={() => {
              signOut(firebaseJs.auth).then(() => {
                window.location.href = "/";
              });
            }}
            links={[
              () => <StartSessionButton />,
              // () => <DasbhoardIconMenuLink />,
              () => <ClientsLeftMenuLink />,
              () => <CommunityLeftMenuLink />,
              () => <CalendarLeftMenuLink />,
              () => <InvoiceLeftMenuLink />,
              // () => <BillingLeftMenuLink />,
              () => <SettingsLeftMenLink />,
              // () => (
              //   <DashboardFCs.LogoutLeftMenuLink
              //     onClick={() => {
              //       signOut(firebaseAuth).then(() => {
              //         window.location.href = "/";
              //       });
              //     }}
              //   />
              // ),
            ]}
          />
        </div>
        <div className="flex-1 flex flex-col lg:pl-[230px]">
          <div className="self-end"></div>
          <div
            id="dashboard-content"
            className={`flex-1 flex flex-col bg-white relative 
          ${isRightNavOpen ? "overflow-y-hidden" : "overflow-y-auto"}
          overscroll-x-none
          `}
          >
            <Navbar
              currentDashboardPage={getCurrentDashboardPageFromUrl(curUrl)}
            />
            <RightNav />
            {showReloading ? <FullContainerLoadingSpinner /> : <Outlet />}
            {O.isSome(showBottomToast) && (
              <div className="absolute h-[100px] bottom-0 w-full py-8 flex justify-center items-center animate-slideup">
                <BottomToast message={showBottomToast.value} />
              </div>
            )}
          </div>
        </div>
      </div>
    </HpStateContext.Provider>
  );
};

function getCurrentDashboardPageFromUrl(url: string) {
  /* consider the following examples 
     1. url: http://localhost:5173/hp/my/dashboard/clients  -> return Clients
     2. url: http://localhost:5173/hp/my/dashboard/clients/123  -> return Clients
     3. url: http://localhost:5173/hp/my/dashboard/community  -> return Community
     */

  const dashboardPageRegex = /\/hp\/my\/dashboard\/(\w+)(\/|$)/;
  const match = url.match(dashboardPageRegex);
  if (match && match[1]) {
    return match[1].charAt(0).toUpperCase() + match[1].slice(1);
  }
  return "Dashboard"; // Default return if no specific page is found
}

const StartSessionButton: React.FC = () => {
  const dashboardState = useHpState();
  const rdClients = useTaskEither(
    BE.fetchEndpointTE((Api) => Api.hp.clients.getClients.query())
  );

  return (
    <div>
      <button
        className="w-[200px] ml-4 bg-vid-purple text-center text-white p-3 mb-4 rounded-2xl"
        onClick={() => {
          if (RD.isSuccess(rdClients)) {
            const myClients = rdClients.value.clients;
            dashboardState.rightNav$.next(
              O.some({ _tag: "START_SESSION", myClients })
            );
          }
        }}
      >
        Begin session
      </button>
    </div>
  );
};

const BottomToast: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="bg-black text-white rounded-full px-8 py-4">{message}</div>
  );
};

const HamburgerButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button
      className="w-8 h-full flex flex-col justify-center items-center gap-1"
      onClick={onClick}
    >
      <div className="w-8 h-1 bg-black rounded-full"></div>
      <div className="w-8 h-1 bg-black rounded-full"></div>
      <div className="w-8 h-1 bg-black rounded-full"></div>
    </button>
  );
};

const LeftMenuLink = DashboardFCs.LeftMenuLink;

const HamburgerMenu: React.FC<{ isOpen: boolean; close: () => void }> = ({
  isOpen,
  close,
}) => {
  const firebaseJs = useFirebaseJs();
  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen bg-white z-50 border-4 border-blue-400 overflow-scroll ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex flex-col gap-4 grow-0 shrink-0 py-8">
        <div className="flex justify-end w-full px-8">
          <button onClick={close} className="text-3xl">
            <img src="/close.svg" width={18} height={18} />
          </button>
        </div>
        <div className="w-full flex justify-center">
          <VidalifySvgIcon />
        </div>
        <div className="flex flex-col justify-between min-h-screen">
          <div className="flex flex-col gap-8 mb-8">
            <DasbhoardIconMenuLink onClick={close} />
            <ClientsLeftMenuLink onClick={close} />
            <InvoiceLeftMenuLink onClick={close} />
            <CalendarLeftMenuLink onClick={close} />
            {/* <BillingLeftMenuLink onClick={close} /> */}
            <SettingsLeftMenLink onClick={close} />
          </div>
          <DashboardFCs.LogoutLeftMenuLink
            onClick={() => {
              signOut(firebaseJs.auth).then(() => {
                window.location.href = "/";
              });
            }}
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};

const DasbhoardIconMenuLink: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <DashboardFCs.LeftMenuLink
      to={HP_ROUTES.MY.DASHBOARD.HOME.path}
      icon={() => <DashboardIcon />}
      onClick={onClick}
    >
      Dashboard
    </DashboardFCs.LeftMenuLink>
  );
};

const InvoiceLeftMenuLink: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <DashboardFCs.LeftMenuLink
      to={HP_ROUTES.MY.DASHBOARD.INVOICES.path}
      icon={(isSelected) => <InvoicesIcon isSelected={isSelected} />}
      onClick={onClick}
    >
      Invoices
    </DashboardFCs.LeftMenuLink>
  );
};

const CommunityLeftMenuLink: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <DashboardFCs.LeftMenuLink
      to={HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.path}
      icon={(isSelected) => <ClientsIcon isSelected={isSelected} />}
      onClick={onClick}
    >
      Community
    </DashboardFCs.LeftMenuLink>
  );
};

const ClientsLeftMenuLink: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <DashboardFCs.LeftMenuLink
      to={HP_ROUTES.MY.DASHBOARD.CLIENTS.path}
      icon={(isSelected) => <ClientsIcon isSelected={isSelected} />}
      onClick={onClick}
    >
      Clients
    </DashboardFCs.LeftMenuLink>
  );
};

const CalendarLeftMenuLink: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <LeftMenuLink
      to={HP_ROUTES.MY.DASHBOARD.CALENDAR.path}
      icon={(isSelected) => <BillingIcon isSelected={isSelected} />}
      onClick={onClick}
    >
      Calendar
    </LeftMenuLink>
  );
};

// const BillingLeftMenuLink: React.FC<{ onClick?: () => void }> = ({
//   onClick,
// }) => {
//   return (
//     <LeftMenuLink
//       to={HP_ROUTES.MY.DASHBOARD.BILLING.path}
//       icon={(isSelected) => <BillingIcon isSelected={isSelected} />}
//       onClick={onClick}
//     >
//       Billing method
//     </LeftMenuLink>
//   );
// };

const SettingsLeftMenLink: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <LeftMenuLink
      to={HP_ROUTES.MY.DASHBOARD.SETTINGS.ACCOUNT.buildPath(
        {},
        {},
        "practice-info"
      )}
      icon={(isSelected) => <SettingsIcon isSelected={isSelected} />}
      onClick={onClick}
    >
      Settings
    </LeftMenuLink>
  );
};

const SELECTED_COLOR = DashboardFCs.SELECTED_COLOR;
const UNSELECTED_COLOR = DashboardFCs.UNSELECTED_COLOR;

const InvoicesIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const fill = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16.767 1.488c-.7.002-1.267.568-1.267 1.256v6.218h2.67c.63 0 1.07-.195 1.352-.476.282-.28.478-.72.478-1.347V4.727c0-.887-.363-1.698-.952-2.294a3.276 3.276 0 00-2.281-.945zM14 2.744c0-1.524 1.25-2.75 2.77-2.75h.007c1.29.011 2.476.53 3.333 1.384l.004.004A4.75 4.75 0 0121.5 4.727v2.412c0 .947-.304 1.793-.917 2.404s-1.462.914-2.413.914h-3.42A.749.749 0 0114 9.71V2.744z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M1.057 1.42C1.862.49 3.097-.008 4.75-.008h12a.749.749 0 110 1.495c-.686 0-1.25.562-1.25 1.246v16.941c0 1.437-1.646 2.25-2.797 1.398l-.002-.002-1.718-1.281a.242.242 0 00-.313.025L8.99 21.49c-.683.68-1.797.68-2.48 0l-.002-.002-1.658-1.663a.259.259 0 00-.34-.03l-1.707 1.273-.002.002C1.643 21.942 0 21.114 0 19.675V4.727c0-1.199.27-2.4 1.057-3.308zm13.245.068H4.75c-1.346 0-2.112.395-2.557.908-.463.535-.693 1.327-.693 2.33v14.95c0 .215.235.323.397.201l.004-.003L3.61 18.6a1.762 1.762 0 012.3.17h.002l1.658 1.664a.26.26 0 00.36 0l1.68-1.675a1.746 1.746 0 012.283-.157l1.704 1.272c.17.125.403 0 .403-.198V2.735c0-.448.109-.872.302-1.246z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7 11.713c0-.413.336-.748.75-.748h3a.749.749 0 110 1.495h-3a.749.749 0 01-.75-.747zM7 7.726c0-.412.336-.747.75-.747h3c.414 0 .75.335.75.747a.749.749 0 01-.75.748h-3A.749.749 0 017 7.726zM3.746 11.703c0-.55.447-.996 1-.996h.009c.552 0 1 .446 1 .996s-.448.997-1 .997h-.01c-.552 0-1-.446-1-.997zM3.746 7.717c0-.55.447-.997 1-.997h.009c.552 0 1 .446 1 .997 0 .55-.448.996-1 .996h-.01c-.552 0-1-.446-1-.996z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const ClientsIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const stroke = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 01-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 01.16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 00-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
      ></path>
    </svg>
  );
};

const BillingIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const fill = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      fill="none"
      viewBox="0 0 22 19"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M0 5.75A.75.75 0 01.75 5h20a.75.75 0 010 1.5h-20A.75.75 0 010 5.75zM4 13.75a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM8.5 13.75a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M2.166 2.162c-.425.42-.666 1.22-.666 2.978v8.22c0 1.76.241 2.557.666 2.976.427.422 1.24.659 3.022.654H16.31c1.781 0 2.596-.239 3.024-.662.425-.42.666-1.22.666-2.978V5.14c0-1.759-.241-2.557-.667-2.977-.43-.424-1.247-.663-3.033-.663H5.19c-1.781 0-2.596.239-3.024.662zM1.111 1.095C2.016.201 3.421 0 5.19 0H16.3c1.774 0 3.181.201 4.087 1.095.91.897 1.113 2.294 1.113 4.045v8.21c0 1.751-.204 3.147-1.111 4.045-.905.894-2.31 1.095-4.079 1.095H5.19c-1.768.005-3.173-.193-4.078-1.086C.204 16.508 0 15.11 0 13.36V5.14c0-1.751.204-3.147 1.111-4.045z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const SettingsIcon: React.FC<{ isSelected: boolean }> = ({ isSelected }) => {
  const stroke = isSelected ? SELECTED_COLOR : UNSELECTED_COLOR;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12 15a3 3 0 100-6 3 3 0 000 6z"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M2 12.88v-1.76c0-1.04.85-1.9 1.9-1.9 1.81 0 2.55-1.28 1.64-2.85-.52-.9-.21-2.07.7-2.59l1.73-.99c.79-.47 1.81-.19 2.28.6l.11.19c.9 1.57 2.38 1.57 3.29 0l.11-.19c.47-.79 1.49-1.07 2.28-.6l1.73.99c.91.52 1.22 1.69.7 2.59-.91 1.57-.17 2.85 1.64 2.85 1.04 0 1.9.85 1.9 1.9v1.76c0 1.04-.85 1.9-1.9 1.9-1.81 0-2.55 1.28-1.64 2.85.52.91.21 2.07-.7 2.59l-1.73.99c-.79.47-1.81.19-2.28-.6l-.11-.19c-.9-1.57-2.38-1.57-3.29 0l-.11.19c-.47.79-1.49 1.07-2.28.6l-1.73-.99a1.899 1.899 0 01-.7-2.59c.91-1.57.17-2.85-1.64-2.85-1.05 0-1.9-.86-1.9-1.9z"
      ></path>
    </svg>
  );
};

function DashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g>
        <g
          stroke="#690DFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M10.5 20.23V4.43c0-1.5-.64-2.1-2.23-2.1H4.23c-1.59 0-2.23.6-2.23 2.1v15.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1z"></path>
          <path d="M22 8.85V4.31c0-1.41-.64-1.98-2.23-1.98h-4.04c-1.59 0-2.23.57-2.23 1.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"></path>
          <path d="M22 20.1v-4.04c0-1.59-.64-2.23-2.23-2.23h-4.04c-1.59 0-2.23.64-2.23 2.23v4.04c0 1.59.64 2.23 2.23 2.23h4.04c1.59 0 2.23-.64 2.23-2.23z"></path>
        </g>
      </g>
    </svg>
  );
}
