import { useObservableEagerState } from "observable-hooks";
import { useEffect, useMemo } from "react";
import { match } from "ts-pattern";
import {
  BaseRegisterPingRes,
  MainRoomStateMgr,
} from "../../../../mgrs/live-session/main-room/main-room.statemgr";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "../../../primitives/button";
import { RxO } from "../../../../prelude";

interface Props<
  RegisterPingRes extends {
    timeLeftSeconds: number | null;
    mbShowNearEndSessionTimer: string | null;
  },
  TopPreviewViewState,
  ExtraRemoteState
> {
  mainRoomStateMgr: MainRoomStateMgr<
    RegisterPingRes,
    TopPreviewViewState,
    ExtraRemoteState
  >;
}

export const MeditationToolsPanel = <
  RegisterPingRes extends BaseRegisterPingRes,
  TopPreviewViewState,
  ExtraRemoteState
>(
  p: Props<RegisterPingRes, TopPreviewViewState, ExtraRemoteState>
) => {
  const meditationMgr = p.mainRoomStateMgr.tools.meditationMgr;
  const meditationState = useObservableEagerState(meditationMgr.remoteState$);
  useEffect(() => {
    p.mainRoomStateMgr.openTopPreview({
      _tag: "Meditation",
      minutes: 1,
      seconds: 0,
    });
    p.mainRoomStateMgr.tools.meditationMgr.setInitialState();
  }, []);

  return (
    <div>
      MeditationToolsPanel
      {match(meditationState.playState)
        .with("STOPPED", () => (
          <PrimaryButton
            title="Start Meditation"
            onClick={() => {
              meditationMgr.setPlayState("PLAYING");
            }}
          />
        ))
        .with("PLAYING", () => (
          <div className="flex gap-2">
            <InversePrimaryButton
              title="Pause"
              onClick={() => {
                meditationMgr.setPlayState("PAUSED");
              }}
            />
            <PrimaryButton
              title="Stop"
              onClick={() => {
                meditationMgr.setPlayState("STOPPED");
              }}
            />
          </div>
        ))
        .with("PAUSED", () => (
          <InversePrimaryButton
            title="Resume"
            onClick={() => {
              meditationMgr.setPlayState("PLAYING");
            }}
          />
        ))
        .exhaustive()}
    </div>
  );
};

export const MeditationPreview = <
  RegisterPingRes extends BaseRegisterPingRes,
  TopPreviewViewState,
  ExtraRemoteState
>(
  p: Props<RegisterPingRes, TopPreviewViewState, ExtraRemoteState>
) => {
  const meditationMgr = p.mainRoomStateMgr.tools.meditationMgr;
  const playState$ = useMemo(
    () => meditationMgr.remoteState$.pipe(RxO.map((ms) => ms.playState)),
    [meditationMgr.remoteState$]
  );
  const playState = useObservableEagerState(playState$);
  return (
    <div>
      {playState === "PLAYING" && <div>Playing</div>}
      {playState === "PAUSED" && <div>Paused</div>}
      {playState === "STOPPED" && <div>Stopped</div>}
    </div>
  );
};
