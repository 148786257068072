import React from "react";

export const SettingsContainer: React.FC<{
  id: string;
  title: string;
  children: React.ReactNode;
}> = ({ id, title, children }) => {
  return (
    <div
      id={id}
      className="flex flex-col rounded-lg border border-vid-black-200"
    >
      <div className="flex items-center pl-[17px] py-4 bg-vid-black-100 rounded-t-lg">
        <h1 className="font-semibold text-vid-black-900">{title}</h1>
      </div>
      <div className="flex flex-col p-8">{children}</div>
    </div>
  );
};
