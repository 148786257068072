export const GetVidalifyProCard = () => {
  return (
    <div className="bg-[url('/dashboard/bg-vidalify-pro.png')] bg-no-repeat flex flex-col items-center justify-center text-white min-h-[430px] rounded-lg">
        <h5 className="text-2xl font-light mb-3 leading-tight">Get Vidalify Pro</h5>
        <p className="text-sm mb-6">Unlimited training sessions and reach</p>
        <div className='w-1/2 text-center mx-8 bg-white rounded-xl text-vid-purple px-8 py-4 hover:cursor-pointer'>
            Upgrade
        </div>
    </div>
  )
}
