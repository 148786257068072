import { ParticipantView, useCallStateHooks } from "@stream-io/video-react-sdk";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
} from "../../../../../../components/live-session/live-session.components";

export const PaginatedGridView: React.FC = () => {
  //   const call = useCall();
  const { useParticipants } = useCallStateHooks();
  const [participantInSpotlight, ...otherParticipants] = useParticipants();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {[participantInSpotlight, ...otherParticipants].map((participant) => {
        return (
          <div
            key={participant.userId}
            className="aspect-[16/9] rounded-[12px] border overflow-hidden"
          >
            <div className="w-full h-full bg-red-500 rounded-[12px]">
              <ParticipantView
                participant={participant}
                ParticipantViewUI={CustomParticipantViewUI}
                VideoPlaceholder={CustomVideoPlaceholder}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
