import { pipe } from "fp-ts/lib/function";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { HpProfile } from "shared";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { O, RD } from "shared/base-prelude";
import { BE } from "../../../../../../backend";
import { ProfileImageSelector } from "../../../../../../components/avatar.tc";
import { FullContainerLoadingSpinner } from "../../../../../../loading";
import { useHpState } from "../../../../hp.state";

export const HpSettingsEditProfileForm: React.FC = () => {
  const rdMyProfile = useTaskEither(
    BE.authedTE((tkn) => BE.Api(tkn).hp.getMyProfile.query())
  );

  return pipe(
    rdMyProfile,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (profile) => <EditProfileSection initialProfile={profile} />
    )
  );
};

type ProfileForm = {
  profilePhoto: string | null;
  firstName: string;
  lastName: string;
  email: string;
};

const inputClassNames = `rounded-full border border-gray-400`;

const EditProfileSection: React.FC<{ initialProfile: HpProfile }> = ({
  initialProfile,
}) => {
  const dashboardMgr = useHpState();
  // use useForm to edit profile
  const { register, handleSubmit, control } = useForm<ProfileForm>({
    defaultValues: {
      firstName: initialProfile.firstName ?? "",
      lastName: initialProfile.lastName ?? "",
      profilePhoto: initialProfile.profilePhoto ?? null,
      email: initialProfile.email ?? "",
    },
  });

  const onSubmit = async (data: ProfileForm) => {
    console.log(data);

    if (
      !!data.profilePhoto &&
      data.profilePhoto !== initialProfile.profilePhoto
    ) {
      console.log("MEOW !");
      await dashboardMgr.saveProfilePhoto({
        profilePhotoB64String: data.profilePhoto,
      });
    }

    await BE.authedTE((tkn) => BE.Api(tkn).hp.updateName.mutate(data))();

    dashboardMgr.refreshMyProfile();

    dashboardMgr.showBottomToast({ msg: "Saved settings!" });

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  return (
    <div className="flex flex-col gap-12">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <Controller
          control={control} // from useForm
          name="profilePhoto"
          render={({ field: { onChange, value } }) => (
            <ProfileImageSelector
              currentMbProfile={value}
              onImageChange={(base64String) => onChange(base64String)}
            />
          )}
        />
        <div className="flex items-center gap-4">
          <input
            className={`${inputClassNames} px-4 py-2`}
            {...register("firstName")}
          />
          <input
            className={`${inputClassNames} px-4 py-2`}
            {...register("lastName")}
          />
        </div>
        <input
          className={`${inputClassNames} px-4 py-2`}
          {...register("email")}
        />
        <button
          className="bg-blue-500 text-white rounded-lg p-4 self-start"
          type="submit"
        >
          Save
        </button>
      </form>
    </div>
  );
};
