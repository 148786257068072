import { Activity } from "getstream";
import { Kysely, Selectable } from "kysely";
import { z } from "zod";
import { RD } from "./base-prelude";
import { DB, Session, Users } from "./db";

export const AppEnvSchema = z.enum(["prod", "dev"]);

export type AppEnv = z.infer<typeof AppEnvSchema>;

export function parseAppEnv(input: string): AppEnv {
  const parsed = AppEnvSchema.safeParse(input);
  console.log("PARSED! ", input, parsed);
  if (parsed.success) {
    return parsed.data;
  } else {
    throw new Error("Invalid AppEnv value");
  }
}

export type SessionInvoice = {
  invoice_id: string;
  session_id: string;
  created_date: Date;
  from: {
    id: string;
    name: string;
    profilePhotoDownloadUrl: string | null;
  };
  client: {
    id: string;
    name: string;
    profilePhotoSavedAt: Date | null;
    profilePhotoDownloadUrl: string | null;
  };
  total: string;
  status: string;
  sessionLengthSeconds: number;
};

export type SimpleUser = {
  id: string;
  name: string | null;
  email: string | null;
};

export type SimpleUserWithProfilePhoto = SimpleUser & {
  profilePhoto: string | null;
};

export type UserWithProfilePhoto = Selectable<Users> & {
  profilePhoto: string | null;
};

export type BasicSessionReview = {
  session: {
    id: string;
    endedAt: Date;
    invoice: SessionInvoice;
    videoUrl: string | null;
    audioUrl: string | null;
  };
};

export type SessionDownloadUrls = {
  audioDownloadUrl: string | null;
  videoDownloadUrl: string | null;
  transcriptDownloadUrl: RD.RemoteData<any, string>;
};

export type StripeExpressBankAccount = {
  charges_enabled: boolean;
};

export type AiReviewer = {
  assistant_id: string;
  thread_id: string;
};

export namespace PostProcessState {
  export type VideoState = RD.RemoteData<any, { downloadUrl: string }>;
  export type AudioState = RD.RemoteData<any, string>;
  export type TranscriptState = RD.RemoteData<
    any,
    {
      transcript: any[];
      transcriptDownloadUrl: string;
    }
  >;
  export type AiState = RD.RemoteData<any, AiReviewer>;

  export type SummaryState = RD.RemoteData<
    any,
    {
      summaryDownloadUrl: string;
    }
  >;

  export interface AllState {
    videoState: VideoState;
    audioState: AudioState;
    transcriptState: TranscriptState;
    aiState: AiState;
    summaryState: SummaryState;
  }

  export const defaultState: AllState = {
    videoState: RD.initial,
    audioState: RD.initial,
    transcriptState: RD.initial,
    aiState: RD.initial,
    summaryState: RD.initial,
  };
}

export type HpProfile = {
  id: string;
  email: string;
  name: string | null;
  firstName: string;
  lastName: string;
  profilePhoto: string | null;
  isAvailableForPractice: boolean;
  bio: string;
  modalities: { name: string; slug: string }[];
};

export const NewHpSessionBookmarkT = z.object({
  label: z.string().optional(),
  color: z.string().optional(),
  bookmarkedAt: z.string().optional(),
});

export type NewHpSessionBookmark = z.infer<typeof NewHpSessionBookmarkT>;

export const SavedBookmarkSchema = z.object({
  id: z.string(),
  label: z.string().nullable(),
  color: z.string().nullable(),
  bookmarkedSecondsIntoSession: z.number(),
});

export type SavedBookmark = z.infer<typeof SavedBookmarkSchema>;

export const AppointmentT = z.object({
  id: z.string(),
  date: z.date(),
  title: z.string(),
  durationInMinutes: z.number().nullable(),
  price: z.number().nullable(),
  client: z.object({
    id: z.string(),
    name: z.string(),
    profilePhoto: z.string().nullable(),
  }),
});

export type Appointment = z.infer<typeof AppointmentT>;

export const NewAppointmentFormT = z.object({
  requestApptId: z.string().optional(),
  date: z.date().nullable(),
  client: z.object({ id: z.string(), name: z.string().nullable() }).nullable(),
  sessionType: z.string().optional(),
  price: z.number().nullable(),
  durationInMinutes: z.number().nullable(),
  therapyTypeSlug: z.string().nullable(),
  paymentMethod: z
    .union([z.literal("vidalify"), z.literal("outside")])
    .optional(),
});

export type NewAppointmentForm = z.infer<typeof NewAppointmentFormT>;

export type SessionWithUsers = Selectable<Session> & {
  hp_id: string;
  hp_name: string | null;
  client_id: string;
  client_name: string | null;
  clientProfilePhoto: string | null;
};

export type DbMgr = Kysely<DB>;

export const CommunityInfoT = z.object({
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  amIJoined: z.boolean(),
  recentMembers: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      profilePhoto: z.string().nullable(),
    })
  ),
});

export type CommunityInfo = z.infer<typeof CommunityInfoT>;

export const CommunityPostReplyT = z.object({
  id: z.string(),
  content: z.string(),
  author: z.object({
    id: z.string(),
    name: z.string(),
    profilePhoto: z.string().nullable(),
  }),
  createdAt: z.date(),
});

export const CommunityPostInfoT = z.object({
  id: z.string(),
  // title: z.string(),
  mainText: z.string(),
  mainImageUrl: z.string().optional(),
  fileType: z.string().optional(),
  communitySlug: z.string().optional(),
  likes: z.number(),
  haveILikedPost: z.boolean(),
  postedAt: z.date(),
  replies: z.array(CommunityPostReplyT),
  author: z.object({
    id: z.string(),
    name: z.string(),
    profilePhoto: z.string().nullable(),
    isAvailableForPractice: z.boolean(),
  }),
});

export type CommunityPostReply = z.infer<typeof CommunityPostReplyT>;

export type CommunityPostInfo = z.infer<typeof CommunityPostInfoT>;

export const FeedUploadableFileTypesSchema = z.union([
  z.literal("image/jpeg"),
  z.literal("image/png"),
  z.literal("video/mp4"),
  z.literal("video/quicktime"),
]);

export type FeedUploadableFileTypes = z.infer<
  typeof FeedUploadableFileTypesSchema
>;

export const NewCommunityFeedPostSchema = z.object({
  mainTextContent: z.string(),
  fileType: FeedUploadableFileTypesSchema.optional(),
  posterName: z.string(),
  posterProfilePhoto: z.string().nullable(),
});

export type NewCommunityFeedPost = z.infer<typeof NewCommunityFeedPostSchema>;

export type CommunityFeedPostActivity = Activity & NewCommunityFeedPost;

export const ModalityT = z.object({
  name: z.string(),
  slug: z.string(),
});

export type Modality = z.infer<typeof ModalityT>;

export interface SessionShortSummary {
  sessionInfo: {
    id: string;
    endedAt: Date;
  };
  review: { myNotes: string | null; autoSummary: string[] | null };
}

export type UserSessionRole = "HP" | "CP";

export function uidForSessionRole(role: UserSessionRole) {
  return role === "HP" ? 1 : 2;
}

export function sessionRoleForUid(uid: number) {
  return uid === 1 ? "HP" : "CP";
}

export const SessionGenericFullSummarySchema = z.object({
  sessionActivities: z.array(
    z.object({ type: z.string(), instructions: z.string() })
  ),
  potentialHomework: z.array(
    z.object({ type: z.string(), instructions: z.string() })
  ),
  techniquesUsed: z.array(
    z.object({ modality: z.string(), technique: z.string() })
  ),
  clientCoreBeliefs: z.array(z.string()),
});

export type SessionGenericFullSummary = z.infer<
  typeof SessionGenericFullSummarySchema
>;

export const FormattedTranscriptJsonSegmentSchema = z.object({
  secondsIntoStart: z.number(),
  secondsIntoEnd: z.number(),
  speaker: z.string(),
  text: z.string(),
});
export type FormattedTranscriptJsonSegment = z.infer<
  typeof FormattedTranscriptJsonSegmentSchema
>;

export const FormattedTranscriptJsonSchema = z.array(
  FormattedTranscriptJsonSegmentSchema
);

export type FormattedTranscriptJson = z.infer<
  typeof FormattedTranscriptJsonSchema
>;

export const BookendSchema = z.object({
  id: z.string(),
  label: z.nullable(z.string()),
  color: z.string().nullable(),
  bookmarked_at: z.date(),
  bookend_at: z.date().nullable(),
  secondsIntoStart: z.number(),
});

export type Bookend = z.infer<typeof BookendSchema>;

export type AppChatChannelTypeName =
  | "community-private-chat"
  | "hpcp-private-chat"
  | "live-session-private"
  | "community-event-group-chat";

export const ChronologicalSummarySchema = z.object({
  chronologicalSummary: z.array(
    z.object({
      startingMinute: z.number(),
      summary: z.string(),
      isTherapeutic: z.optional(z.boolean()),
    })
  ),
});

export type ChronologicalSummary = z.infer<typeof ChronologicalSummarySchema>;

export const FullSessionReviewSchema = z.object({
  bookmarks: z.array(SavedBookmarkSchema),
  bookends: z.array(BookendSchema),
  transcript: z.nullable(
    z.object({
      segments: z.array(FormattedTranscriptJsonSegmentSchema),
    })
  ),
  autoSummary: z.nullable(SessionGenericFullSummarySchema),
  chronologicalSummary: z.nullable(ChronologicalSummarySchema),
  recordingUrls: z.array(z.string()),
});

export type FullSessionReview = z.infer<typeof FullSessionReviewSchema>;

export const NotesAndBookmarksSchema = z.object({
  notes: z.string().nullable(),
  bookmarks: z.array(SavedBookmarkSchema),
  bookends: z.array(BookendSchema),
});

export type NotesAndBookmarks = z.infer<typeof NotesAndBookmarksSchema>;

export const RecurringStatusSchema = z.enum([
  "none",
  "daily",
  "weekly",
  "monthly",
  "yearly",
]);
export type RecurringStatus = z.infer<typeof RecurringStatusSchema>;

export const CommunityEventMemberStatusSchema = z.enum([
  "attending",
  "interested",
  "not_attending",
]);

export const KnownToolSchema = z.enum([
  "notes",
  "screenshare",
  "emdr",
  "music",
  "chat",
  "meditation",
  "review",
]);

export type KNOWN_TOOL = z.infer<typeof KnownToolSchema>;

export type TherapyToolMenuViewState =
  | { _tag: "MENU" }
  | { _tag: "TOOL"; tool: KNOWN_TOOL };

export const RawStreamTranscriptionSegmentSchema = z.object({
  start_time: z.string(),
  stop_time: z.string(),
  speaker_id: z.string(),
  text: z.string(),
});

export type RawStreamTranscriptionSegment = z.infer<
  typeof RawStreamTranscriptionSegmentSchema
>;

export const RawStreamTranscriptionSchema = z.array(
  RawStreamTranscriptionSegmentSchema
);

export type RawStreamTranscription = z.infer<
  typeof RawStreamTranscriptionSchema
>;
