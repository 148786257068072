import { useObservableState } from "observable-hooks";
import { useMemo } from "react";
import { Bookend, SavedBookmark, SessionWithUsers } from "shared";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { E, RD, Rx, taskEitherToEff } from "shared/base-prelude";
import { FirebaseJsMgr } from "shared/firebase";
import {
  defaultNormalSessionState,
  PrivateSessionState,
  SessionState,
  SessionStateSchema,
} from "shared/session-state/session-state";
import { createContextAndHook } from "shared/util";
import { BE } from "../../../../backend";
import { FirestoreSessionStateMgmt } from "../../../../mgrs/live-session/firestore-live-session.mgmt";
import { MainRoomStateMgr } from "../../../../mgrs/live-session/main-room/main-room.statemgr";
import { RxO } from "../../../../prelude";

type MgrConstructorArgs = {
  firebaseJsMgr: FirebaseJsMgr;
  session: SessionWithUsers;
};

type PingResult = UApiOutput["hp"]["sessions"]["liveSession"]["registerPing"];

type TopPreviewState = { _tag: "Transcript" };

export class HpPrivateSessionStateMgr {
  session: SessionWithUsers;

  remoteStateMgr: FirestoreSessionStateMgmt<PrivateSessionState>;

  mainRoomStateMgr: MainRoomStateMgr<
    PingResult,
    TopPreviewState,
    PrivateSessionState
  >;

  notesMgr: NotesMgr;

  constructor(p: MgrConstructorArgs) {
    console.log("SETTING THIS SESSION! ", p.session);
    this.session = p.session;
    this.remoteStateMgr = new FirestoreSessionStateMgmt<PrivateSessionState>(
      p.firebaseJsMgr,
      { _tag: "PRIVATE", sessionId: p.session.id },
      defaultNormalSessionState,
      SessionStateSchema
    );
    this.mainRoomStateMgr = new MainRoomStateMgr<
      PingResult,
      TopPreviewState,
      PrivateSessionState
    >({
      sendPingEff: taskEitherToEff(
        BE.authedTE((tkn) =>
          BE.Api(
            tkn
          ).hp.sessions.session.liveSessionActions.registerPing.mutate({
            sessionId: this.session.id,
          })
        )
      ),
      remoteStateMgr: this.remoteStateMgr,
    });
    this.notesMgr = new NotesMgr(this.remoteStateMgr, p.session);

    this.mainRoomStateMgr.pingResult$.subscribe((pr) => {
      this.onPingResult(pr).catch();
    });
  }

  async onPingResult(pr: PingResult) {
    const { timeLeftSeconds } = pr;

    const sessionState = await this.remoteStateMgr.getCurSessionState();
    if (
      timeLeftSeconds &&
      timeLeftSeconds < 60 * 5 &&
      !sessionState.showedNextScheduleReminderAt
    ) {
      this.remoteStateMgr.runUpdateSessionState({
        isShowingNextScheduleReminder: true,
        showedNextScheduleReminderAt: new Date().toISOString(),
      });
    }
  }

  async leaveAndEndSession() {
    // console.log("PING INTERVAL! ", this.mainRoomStateMgr);
    // if (this.mainRoomStateMgr.pingInterval) {
    //   clearInterval(this.mainRoomStateMgr.pingInterval);
    // }
    console.log("CALLILNG END SESSION! ", this.session);
    const leaveRes = await BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.liveSession.endSession.mutate({
        sessionId: this.session.id,
      })
    )();

    console.log("LEAVE RES! ", leaveRes);
  }

  playMusic() {
    this.remoteStateMgr.runUpdateSessionState({
      playingMediaFile: "meditation.mp3",
    });
  }

  stopMusic() {
    this.remoteStateMgr.runUpdateSessionState({
      playingMediaFile: null,
    });
  }
}

export function usePluckSessionState$<K extends keyof SessionState>(
  ss$: Rx.Observable<SessionState>,
  k: K
) {
  const sp$ = useMemo(() => ss$.pipe(RxO.map((ss) => ss[k])), [ss$]);

  return sp$;
}

export function usePluckSessionState<K extends keyof SessionState>(
  ss$: Rx.Observable<SessionState>,
  k: K,
  defaultValue: SessionState[K]
) {
  const sp$ = usePluckSessionState$(ss$, k);

  const sp = useObservableState(sp$, defaultValue);

  return sp;
}

export const [HpPrivateSessionStateContext, useHpPrivateSessionState] =
  createContextAndHook<HpPrivateSessionStateMgr>();

class NotesMgr {
  notes$ = new Rx.BehaviorSubject<string>("");

  recordSectionMgr: RecordSectionMgr;
  rdBookmarks$ = new Rx.BehaviorSubject<RD.RemoteData<any, SavedBookmark[]>>(
    RD.initial
  );

  constructor(
    readonly remoteStateMgr: FirestoreSessionStateMgmt<PrivateSessionState>,
    readonly session: SessionWithUsers
  ) {
    this.recordSectionMgr = new RecordSectionMgr(
      this.remoteStateMgr,
      this.session
    );

    this.recordSectionMgr.fetchAndSetRecordedBookends().catch();
    this.fetchAndSetBookmarks().catch();
    this.fetchAndSetNotes().catch();
  }

  async fetchAndSetNotes() {
    const er = await BE.authedTE((tkn) =>
      BE.Api(tkn).hp.sessions.liveSession.getNotes.query({
        sessionId: this.session.id,
      })
    )();

    if (E.isRight(er)) {
      this.notes$.next(er.right.notes ?? "");
    }
  }

  async saveBookmark(p: { label?: string }) {
    return BE.authedTE((tkn) =>
      BE.Api(tkn).hp.sessions.liveSession.bookmarkMoment.mutate({
        sessionId: this.session.id,
        bookmark: {
          label: p.label,
        },
      })
    )();
  }

  async fetchAndSetBookmarks() {
    const er = await BE.authedTE((tkn) =>
      BE.Api(tkn).hp.sessions.session.getBookmarks.query({
        sessionId: this.session.id,
      })
    )();
    this.rdBookmarks$.next(RD.fromEither(er));
  }
}

class RecordSectionMgr {
  rdRecordedBookends$ = new Rx.BehaviorSubject<RD.RemoteData<any, Bookend[]>>(
    RD.initial
  );

  constructor(
    readonly remoteStateMgr: FirestoreSessionStateMgmt<PrivateSessionState>,
    readonly session: SessionWithUsers
  ) {}

  async fetchAndSetRecordedBookends() {
    this.rdRecordedBookends$.next(RD.pending);
    const er = await BE.authedTE((tkn) =>
      BE.Api(tkn).hp.sessions.session.getRecordedBookends.query({
        sessionId: this.session.id,
      })
    )();
    this.rdRecordedBookends$.next(RD.fromEither(er));
  }

  startSectionRecording = () => {
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.sessions.liveSession.startBookendRecording.mutate({
        sessionId: this.session.id,
      })
    )().then((er) => {
      console.log("RESULT OF BOOKENDING AT! ", er);
      if (E.isRight(er)) {
        this.remoteStateMgr.runUpdateSessionState({
          isRecordingSectionId: er.right.id,
        });
      }
    });
  };

  stopSectionRecording = async () => {
    const sessionState = await this.remoteStateMgr.getCurSessionState();

    const isRecordingSectionId = sessionState.isRecordingSectionId!;

    const stopRes = await BE.authedTE((tkn) =>
      BE.Api(tkn).hp.sessions.liveSession.stopBookendRecording.mutate({
        sessionId: this.session.id,
        id: isRecordingSectionId,
      })
    )();

    console.log("STOP RES! ", stopRes);

    // await this.remoteStateMgr.fetchAndSetRecordedBookends().catch();

    this.remoteStateMgr.runUpdateSessionState({
      isRecordingSectionId: null,
    });
  };

  async setBookendLabel(p: { bookendId: string; label: string }) {
    return BE.authedTE((tkn) =>
      BE.Api(tkn).hp.sessions.session.setBookendLabel.mutate({
        ...p,
        sessionId: this.session.id,
      })
    )();
  }
}
