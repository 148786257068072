import { sequenceT } from "fp-ts/lib/Apply";
import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { NewAppointmentForm } from "shared";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { BE, DefaultError } from "../../../../../backend";
import { FormDisclosureContainer } from "../../../../../components/disclosures";
import { PrimaryButton } from "../../../../../components/primitives/button";
import { FormRadioCard } from "../../../../../components/primitives/radio-buttons";
import {
  SelectClientSection,
  SetDurationSection,
  SetPrimaryTherapyTypeSection,
} from "../../../../../components/session/session-form.components";
import { FullContainerLoadingSpinner } from "../../../../../loading";
import { E, O, RD, Rx, RxO, TE } from "../../../../../prelude";
import { SimpleClient } from "./right-nav";
import { CalendarMenuInput } from "../../../../../components/form/calendar.input";

const defaultForm: NewAppointmentForm = {
  date: null,
  client: null,
  sessionType: "initial",
  durationInMinutes: 60,
  price: null,
  therapyTypeSlug: null,
  paymentMethod: "vidalify",
};

type PreAppointmentFormData =
  UApiOutput["hp"]["calendar"]["getDefaultMakeAppointmentData"];

class StateMgr {
  newAppointmentForm$ = new Rx.BehaviorSubject<NewAppointmentForm>(defaultForm);
  preAppointmentFormData: PreAppointmentFormData;

  constructor(
    preAppointmentFormData: PreAppointmentFormData,
    mbClient?: SimpleClient,
    requestApptId?: string
  ) {
    this.preAppointmentFormData = preAppointmentFormData;

    if (mbClient) {
      this.newAppointmentForm$.next({
        ...this.newAppointmentForm$.value,
        client: mbClient,
      });
    }

    if (requestApptId) {
      this.newAppointmentForm$.next({
        ...this.newAppointmentForm$.value,
        requestApptId,
      });
    }
  }

  setDate = (date: Date | null) => {
    this.newAppointmentForm$.next({ ...this.newAppointmentForm$.value, date });
  };

  setClient = (client: SimpleClient) => {
    this.newAppointmentForm$.next({
      ...this.newAppointmentForm$.value,
      client,
    });
  };

  setPrice = (price: number) => {
    this.newAppointmentForm$.next({ ...this.newAppointmentForm$.value, price });
  };

  setTherapyType = (therapyTypeSlug: string | null) => {
    this.newAppointmentForm$.next({
      ...this.newAppointmentForm$.value,
      therapyTypeSlug,
    });
  };

  setDuration = (durationInMinutes: number | null) => {
    this.newAppointmentForm$.next({
      ...this.newAppointmentForm$.value,
      durationInMinutes,
    });
  };
}

const ModelContext = React.createContext<StateMgr | null>(null);

function useModel() {
  return React.useContext(ModelContext)!;
}

export const CreateNewAppointmentForm: React.FC<{
  onSuccessSave: () => void;
  mbClient?: SimpleClient;
  requestApptId?: string;
}> = ({ mbClient, onSuccessSave, requestApptId }) => {
  const rdPreAppointmentFormData = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.calendar.getDefaultMakeAppointmentData.query()
    ),
    []
  );

  const rdMbRequestAppt = useTaskEither(
    pipe(
      requestApptId,
      O.fromNullable,
      O.fold(
        () => TE.right(undefined),
        (id) =>
          BE.authedTE((tkn) =>
            BE.Api(tkn).hp.calendar.getApptRequest.query({ id })
          )
      )
    ),
    []
  );

  return pipe(
    sequenceT(RD.remoteData)(rdPreAppointmentFormData, rdMbRequestAppt),
    RD.fold(
      () => <></>,
      () => <FullContainerLoadingSpinner />,
      (e) => <div>{JSON.stringify(e)}</div>,
      ([preformData, mbRequestAppt]) => {
        return (
          <CreateNewAppointmentFormPrefilled
            preformData={preformData}
            mbClient={mbClient}
            mbRequestAppt={mbRequestAppt}
            onSuccessSave={onSuccessSave}
          />
        );
      }
    )
  );
};

const CreateNewAppointmentFormPrefilled: React.FC<{
  preformData: PreAppointmentFormData;
  mbRequestAppt: UApiOutput["hp"]["calendar"]["getApptRequest"];
  onSuccessSave: () => void;
  mbClient?: SimpleClient;
}> = ({ preformData, mbClient, onSuccessSave, mbRequestAppt }) => {
  const model = React.useMemo(
    () => new StateMgr(preformData, mbClient, mbRequestAppt?.id),
    [preformData]
  );
  const date = useObservableEagerState(model.newAppointmentForm$).date;
  const [scheduleResult, setScheduleResult] = React.useState<
    RD.RemoteData<DefaultError, unknown>
  >(RD.initial);
  const [paymentMethod, setPaymentMethod] = React.useState("Auto-pay");

  useEffect(() => {
    if (mbRequestAppt) {
      model.setDate(mbRequestAppt.date);
      model.setClient({
        id: mbRequestAppt.requester_id,
        name: mbRequestAppt.requester_name,
      });
    }
  }, [mbRequestAppt]);

  return (
    <ModelContext.Provider value={model}>
      <div className="flex-1 flex flex-col justify-between px-8 py-4 overflow-y-scroll gap-4">
        <div className="flex-1 flex flex-col gap-4">
          <CalendarMenuInput
            initialDate={date ?? undefined}
            onSelection={model.setDate}
            title="Date & time"
          />
          <SelectClientSection
            selectedClient$={model.newAppointmentForm$.pipe(
              RxO.map((v) => v.client)
            )}
            myClients={preformData.myClients}
            onClientSelect={(client) => {
              model.newAppointmentForm$.next({
                ...model.newAppointmentForm$.value,
                client,
              });
            }}
          />
          <SetPaymentSection />
          <FormRadioCard
            title="Payment"
            radioProps={{
              options: [
                {
                  name: "Auto-pay",
                  icon: <img src="/autopay.svg" height={15} width={15} />,
                },
                {
                  name: "Outside platform",
                  icon: (
                    <img src="/outside-platform.svg" height={15} width={15} />
                  ),
                },
              ],
              value: paymentMethod,
              onChange: (v) => {
                console.log("PAYMENT METHOD! ", v);
                setPaymentMethod(v);
              },
            }}
          />

          <SetPrimaryTherapyTypeSection
            therapyTypeSlug$={model.newAppointmentForm$.pipe(
              RxO.map((naf) => naf.therapyTypeSlug)
            )}
            onChange={(tt) => model.setTherapyType(tt)}
          />

          <SetDurationSection
            durationInMinutes$={model.newAppointmentForm$.pipe(
              RxO.map((v) => v.durationInMinutes)
            )}
            onChange={model.setDuration}
          />
        </div>
        <div className="mt-8 w-full">
          <PrimaryButton
            title="Save"
            onClick={() => {
              console.log("Save!");
              BE.authedTE((tkn) =>
                BE.Api(tkn).hp.calendar.scheduleAppointment.mutate(
                  model.newAppointmentForm$.value
                )
              )().then((er) => {
                setScheduleResult(RD.fromEither(er));

                if (E.isRight(er)) {
                  onSuccessSave();
                }
              });
            }}
          />
          {RD.isFailure(scheduleResult) &&
            scheduleResult.error._tag === "FetchError" && (
              <div className="text-red-500">
                {scheduleResult.error.error.message}
              </div>
            )}
        </div>
      </div>
    </ModelContext.Provider>
  );
};

const SetPaymentSection: React.FC = () => {
  const model = useModel();
  const price = useObservableEagerState(model.newAppointmentForm$).price;

  return (
    <div>
      <FormDisclosureContainer
        buttonView={
          <div className="flex flex-col">
            <h1 className="font-sans font-light text-sm text-vid-purple w-full flex justify-start">
              Payment
            </h1>
            {price && (
              <div>
                <h4 className="font-sans font-light text-sm flex w-full justify-start mt-2">{`$${price}`}</h4>
              </div>
            )}
          </div>
        }
        dropdownView={() => (
          <div>
            <input
              type="text"
              className="text-input"
              value={price ?? ""}
              onChange={(e) => {
                model.setPrice(Number(e.target.value));
              }}
            />
          </div>
        )}
      />
    </div>
  );
};
