import { pipe } from "fp-ts/lib/function";
import { useEffect, useState } from "react";
import { StripeExpressBankAccount } from "shared";
import { BE } from "../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../loading";
import { E, O, RD } from "../../../../prelude";

export const HpMyDashboardBillingMethodsPage: React.FC<{}> = ({}) => {
  const [rdMbStripeAccount, setRdMbStripeAccount] = useState<
    RD.RemoteData<any, O.Option<{ stripeAccount: StripeExpressBankAccount }>>
  >(RD.initial);

  useEffect(() => {
    setRdMbStripeAccount(RD.pending);
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.payment.getStripeAccount.query()
    )().then((er) => {
      console.log(er);
      if (E.isRight(er)) {
        const mbAccount = pipe(
          O.fromNullable(er.right.stripeAccount),
          O.map((sa) => ({ stripeAccount: sa }))
        );
        setRdMbStripeAccount(RD.success(mbAccount));
      }
    });
  }, []);

  return (
    <div className="flex-1 bg-bg-gray flex flex-col p-8 gap-8">
      <h1 className="text-2xl font-bold">Billing Methods</h1>
      {pipe(
        rdMbStripeAccount,
        RD.fold(
          () => <></>,
          () => <FullContainerLoadingSpinner />,
          () => <></>,
          (mbStripeAccount) => {
            if (O.isNone(mbStripeAccount)) {
              return (
                <button
                  className="bg-blue-500 text-white rounded-md p-4 self-start"
                  onClick={() => {
                    BE.authedTE((tkn) =>
                      BE.Api(tkn).hp.payment.createStripeAccount.mutate()
                    )().then((er) => {
                      console.log(er);
                      if (E.isRight(er)) {
                        const linkUrl = er.right.stripeAccountLink.url;
                        // open in a new tab
                        window.open(linkUrl, "_blank");
                      }
                    });
                  }}
                >
                  Link a new bank account
                </button>
              );
            } else {
              return (
                <div>
                  <h1 className="font-bold">Linked account</h1>
                  <div>
                    {JSON.stringify(mbStripeAccount.value.stripeAccount)}
                  </div>
                </div>
              );
            }
          }
        )
      )}
    </div>
  );
};
