import * as React from "react";
import { AppChatChannelTypeName } from "shared";
import { E, RD, TE } from "shared/base-prelude";
import * as stream from "stream-chat";
import {
  Channel,
  ChannelHeader,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";

interface Props {
  setupChatTE: (p: { otherUserId: string }) => TE.TaskEither<
    any,
    {
      channel: {
        name: string;
        id?: string | null;
        type: AppChatChannelTypeName;
      };
      apiKey: string;
      token: string;
      allMemberIds: string[];
    }
  >;
  myProfile: { id: string; name: string };
  otherUserId: string;
}

export const PrivateChatFC: React.FC<Props> = ({
  myProfile,
  otherUserId,
  setupChatTE,
}) => {
  const [rdSetup, setChannel] = React.useState<
    RD.RemoteData<
      any,
      {
        cli: stream.StreamChat<stream.DefaultGenerics>;
        channel: stream.Channel;
      }
    >
  >(RD.initial);

  React.useEffect(() => {
    async function setup() {
      const startChatRes = await setupChatTE({ otherUserId })();

      if (E.isLeft(startChatRes)) {
        return RD.failure(startChatRes.left);
      }

      const cli = stream.StreamChat.getInstance(startChatRes.right.apiKey);

      const connUserRes = await cli.connectUser(
        {
          id: myProfile.id,
          name: myProfile.name,
        },
        startChatRes.right.token
      );

      console.log("CONN USER RES! ", connUserRes);

      const channel = cli.getChannelByMembers(startChatRes.right.channel.type, {
        members: startChatRes.right.allMemberIds,
      });
      setChannel(RD.success({ cli, channel }));
    }
    setup().catch();
  }, []);

  if (!RD.isSuccess(rdSetup)) {
    return <div>Loading...</div>;
  }

  return (
    <Chat client={rdSetup.value.cli}>
      {/* <ChannelList /> */}
      <Channel channel={rdSetup.value.channel}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};
