import { Link, Outlet } from "react-router-dom";
import { VidalifyLogoWithText } from "../../assets/vidalify-logo-with-text.fc";

export const OnboardLayout: React.FC = () => {
  return (
    <div className="w-screen h-screen flex flex-col">
      <nav className="flex flex-row justify-between items-center border-b-2 px-8 py-6 shadow-sm">
        <div
          className="cursor-pointer"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <VidalifyLogoWithText />
        </div>
        <div className="flex flex-1" />
        <Link
          to="/login"
          className="bg-default-purple text-white px-4 py-2 rounded-md text-md"
        >
          Login
        </Link>
      </nav>
      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
    </div>
  );
};
