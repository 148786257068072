import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { PublicApiOutput } from "shared/backend-api/trpc-cli";
import { BE } from "../../../backend";
import { ProfileImageSelector } from "../../../components/avatar.tc";
import { ModalitiesOfferedSelector } from "../../../components/modalities-selector.fc";
import { EditableTextInput } from "../../../components/onboard.components";
import { PrimaryButton } from "../../../components/primitives/button";
import { FullContainerLoadingSpinner } from "../../../loading";
import { E, O, RD, RxO } from "../../../prelude";
import { HP_ROUTES } from "../../../routes/hp-routes";
import { convertObservableToBehaviorSubject } from "../../../util";
import { useHpState } from "../hp.state";

type ImportInfoResp = PublicApiOutput["getProfileFromOtherSite"]["info"];

export const HpOnboardSetProfilePage: React.FC = () => {
  const [{ fromUrl }] = useTypedSearchParams(HP_ROUTES.ONBOARD_SET_PROFILE);
  const hpState = useHpState();
  const [rdExistingProfile, setRdExistingProfile] = useState<
    RD.RemoteData<any, O.Option<ImportInfoResp>>
  >(RD.initial);

  useEffect(() => {
    if (fromUrl) {
      async function importAndSetProfile(siteUrl: string) {
        setRdExistingProfile(RD.pending);
        const er = await BE.publicTE(() =>
          BE.PublicApi.getProfileFromOtherSite.query({
            siteUrl,
          })
        )();
        if (E.isRight(er)) {
          const importedProfile = er.right.info;

          if (importedProfile.profilePhotoB64) {
            console.log("GETTING B64 PROF PHOTO! ");
            console.log(
              "GOT B64 PROF PHOTO! ",
              importedProfile.profilePhotoB64
            );
            await hpState.saveProfilePhoto({
              profilePhotoB64String: importedProfile.profilePhotoB64,
            });
          }

          importedProfile.modalities?.forEach((m) => {
            hpState.addModality(m);
          });

          //   await BE.authedTE((tkn) =>
          //     BE.Api(tkn).hp.setMyOnboardProfile.mutate({
          //       firstName: importedProfile.firstName,
          //       lastName: importedProfile.lastName,
          //     })
          //   )();

          setRdExistingProfile(RD.success(O.some(importedProfile)));
        } else {
          setRdExistingProfile(RD.success(O.none));
        }
      }

      importAndSetProfile(fromUrl).catch();
    } else {
      setRdExistingProfile(RD.success(O.none));
    }
  }, [fromUrl]);

  return pipe(
    rdExistingProfile,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner message={"Importing data..."} />,
      (mbProfile) => <SuccessImportedForm mbProfile={mbProfile} />
    )
  );
};

const SuccessImportedForm: React.FC<{
  mbProfile: O.Option<ImportInfoResp>;
}> = ({ mbProfile }) => {
  const { handleSubmit, control, setValue } = useForm<ImportInfoResp>({
    defaultValues: O.isSome(mbProfile) ? mbProfile.value : undefined,
  });
  const hpState = useHpState();
  const myProfile = useObservableEagerState(hpState.myProfile$);
  const myModalities$ = useMemo(
    () =>
      convertObservableToBehaviorSubject(
        hpState.myProfile$.pipe(RxO.map((mp) => mp.modalities)),
        []
      ),
    []
  );
  const nav = useNavigate();

  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <form
        className="flex flex-col gap-4 items-center"
        onSubmit={handleSubmit((values) => {
          console.log(values);
          BE.authedTE((tkn) =>
            BE.Api(tkn).hp.setMyOnboardProfile.mutate({
              firstName: values.firstName,
              lastName: values.lastName,
            })
          )().then((er) => {
            if (E.isRight(er)) {
              nav(HP_ROUTES.MY.DASHBOARD.CLIENTS.path);
            }
          });
        })}
      >
        <ProfileImageSelector
          currentMbProfile={myProfile.profilePhoto}
          onImageChange={(b64String) => {
            console.log("URL !", b64String);
            setValue("profilePhotoB64", b64String);
          }}
        />
        <div className="flex gap-4">
          <EditableTextInput
            name="firstName"
            label="First Name"
            placeholder=""
            hasEditToggle
            control={control}
          />
          <EditableTextInput
            name="lastName"
            label="Last Name"
            placeholder=""
            hasEditToggle
            control={control}
          />
        </div>
        {/* <input className="text-input" type="text" {...register("email")} /> */}
        {/* <input className="text-input" type="text" {...register("phone")} /> */}
        {/* <input className="text-input" type="text" {...register("modalities")} /> */}
        {/* <ChooseModalitiesOfferedFc
          onAdd={(modality) => {
            console.log("ADDED! ", modality);
            hpState.addModality(modality);
          }}
          onRemove={(modality) => {
            console.log("REMOVED! ", modality);
            hpState.removeModality(modality);
          }}
          myModalities$={myModalities$}
        /> */}
        <div className="self-stretch flex">
          <ModalitiesOfferedSelector
            myModalities$={myModalities$}
            onSelect={(modality) => {
              hpState.addModality(modality);
            }}
          />
        </div>
        <PrimaryButton type="submit" title="Next" />
      </form>
    </div>
  );
};
