import { useTaskEither } from "shared/backend-api/api.mgr";
import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { SessionInvoice, UserWithProfilePhoto } from "shared";
import { O, RD } from "shared/base-prelude";
import { match } from "ts-pattern";
import { BE } from "../../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../../loading";
import { useHpState } from "../../../hp.state";
import { CreateCommunityPostForm } from "./create-community-post.form.fc";
import { InvoiceDownloadForm } from "./invoice-download-form.fc";
import { CreateNewAppointmentForm } from "./new-appointment-form.fc";
import { RegisterNewClientForm } from "./register-new-client.form";
import { StartASessionForm } from "./start-a-session-form.fc";
import {
  ViewAppointmentFormContent,
  ViewAppointmentFormTopView,
  ViewAppointmentStateMgr,
  ViewAppointmentStateMgrContext,
} from "./view-appointment.fc";

export type SimpleClient = {
  id: string;
  name: string | null;
};

export type HpDashbaordRightNav =
  | {
      _tag: "START_CLIENT_SESSION";
      client: SimpleClient;
    }
  | { _tag: "REGISTER_NEW_CLIENT" }
  | { _tag: "START_SESSION"; myClients: UserWithProfilePhoto[] }
  | {
      _tag: "NEW_APPOINTMENT";
      client?: { id: string; name: string };
      requestApptId?: string;
    }
  | { _tag: "VIEW_APPOINTMENT"; appointmentId: string }
  | { _tag: "DOWNLOAD_INVOICE"; invoice: SessionInvoice }
  | { _tag: "CREATE_COMMUNITY_POST"; posterId: string; communitySlug: string }
  | { _tag: "CUSTOM"; topView: React.ReactNode; content: React.ReactNode };

export const MatchViewForHpDashRightNav: React.FC<{
  rightNav: HpDashbaordRightNav;
}> = ({ rightNav }) => {
  const dashboardState = useHpState();
  return match(rightNav)
    .with({ _tag: "START_CLIENT_SESSION" }, (res) => (
      <RightNavContentContainer
        topView={<div>Start a session with {res.client.name}</div>}
      >
        <StartASessionForm
          onClose={() => {
            window.location.reload();
          }}
          forClient={{ _tag: "EXISTING", client: res.client }}
        />
      </RightNavContentContainer>
    ))
    .with({ _tag: "REGISTER_NEW_CLIENT" }, () => (
      <RightNavContentContainer topView={<div>Register a new client</div>}>
        <RegisterNewClientForm />
      </RightNavContentContainer>
    ))
    .with({ _tag: "START_SESSION" }, ({ myClients }) => (
      <RightNavContentContainer topView={<div>Start a session</div>}>
        <StartASessionForm
          onClose={() => {
            window.location.reload();
          }}
          forClient={{ _tag: "SELECT", myClients }}
        />
      </RightNavContentContainer>
    ))
    .with({ _tag: "NEW_APPOINTMENT" }, (res) => (
      <RightNavContentContainer
        topView={
          <div className="flex flex-col justify-center items-center">
            <img src="/pending.png" className="w-fit h-fit rounded-full" />
            <p className="text-2xl font-sans font-light mt-2">
              New appointment
            </p>
          </div>
        }
      >
        <CreateNewAppointmentForm
          mbClient={res.client}
          requestApptId={res.requestApptId}
          onSuccessSave={() => {
            dashboardState.closeRightNav();
            dashboardState.showBottomToast({
              msg: "Appointment scheduled!",
              reload: true,
            });
          }}
        />
      </RightNavContentContainer>
    ))
    .with({ _tag: "VIEW_APPOINTMENT" }, (res) => (
      <ViewAppointmentNav appointmentId={res.appointmentId} />
    ))
    .with({ _tag: "DOWNLOAD_INVOICE" }, (res) => (
      <RightNavContentContainer topView={<div>Download Invoice</div>}>
        <InvoiceDownloadForm invoice={res.invoice} />
      </RightNavContentContainer>
    ))
    .with({ _tag: "CREATE_COMMUNITY_POST" }, (res) => (
      <RightNavContentContainer topView={<div>Create a new post</div>}>
        <CreateCommunityPostForm
          communitySlug={res.communitySlug}
          posterId={res.posterId}
        />
      </RightNavContentContainer>
    ))
    .with({ _tag: "CUSTOM" }, (res) => (
      <RightNavContentContainer topView={res.topView}>
        {res.content}
      </RightNavContentContainer>
    ))
    .exhaustive();
};

const ViewAppointmentNav: React.FC<{ appointmentId: string }> = ({
  appointmentId,
}) => {
  const rdAppointment = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.calendar.getAppointment.query({ id: appointmentId })
    )
  );

  return pipe(
    rdAppointment,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      ({ appointment }) => (
        <ViewAppointmentStateMgrContext.Provider
          value={new ViewAppointmentStateMgr({ appointment })}
        >
          <RightNavContentContainer topView={<ViewAppointmentFormTopView />}>
            <ViewAppointmentFormContent />
          </RightNavContentContainer>
        </ViewAppointmentStateMgrContext.Provider>
      )
    )
  );
};

function widthForRightNavView(rightNav: HpDashbaordRightNav): string {
  return match(rightNav)
    .with({ _tag: "DOWNLOAD_INVOICE" }, () => "600px")
    .otherwise(() => "400px");
}

export const RightNav: React.FC<{}> = () => {
  const dashboardModel = useHpState();
  const mbRightNavOpen = useObservableEagerState(dashboardModel.rightNav$);

  return (
    <div
      className={`absolute top-0 right-0 bottom-0 flex flex-col overflow-y-auto z-50 bg-white transition-all duration-300 ease-in-out`}
      style={{
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
        width: O.isSome(mbRightNavOpen)
          ? widthForRightNavView(mbRightNavOpen.value)
          : "0px",
      }}
    >
      {O.isSome(mbRightNavOpen) && (
        <MatchViewForHpDashRightNav rightNav={mbRightNavOpen.value} />
      )}
    </div>
  );
};

const RightNavContentContainer: React.FC<{
  topView: React.ReactNode;
  children: React.ReactNode;
}> = ({ children, topView }) => {
  const dashboardModel = useHpState();
  return (
    <div className="flex-1 flex flex-col">
      <div className="relative w-full h-[200px] bg-white flex bg-[url('/dot-bg.png')]">
        <div className="absolute top-4 right-4 z-20">
          <button
            className="rounded-full w-9 h-9 p-2 border text-xs cursor-pointer bg-white"
            onClick={() => {
              dashboardModel.rightNav$.next(O.none);
            }}
          >
            <img src="/close.svg" width={18} height={18} />
          </button>
        </div>
        <div className="absolute inset-0 flex justify-center items-center">
          {topView}
        </div>
      </div>
      <div className="flex-1 flex flex-col  ">{children}</div>
    </div>
  );
};
