import { useObservableEagerState, useObservableState } from "observable-hooks";
import { useMemo } from "react";
import { IoArrowDownCircle, IoCloseCircle } from "react-icons/io5";
import { Modality } from "shared";
import { BE } from "../backend";
import { RD, Rx, RxO } from "../prelude";
import { createContextAndHook } from "shared/util";

type ViewState = "DROPPED_DOWN" | "SELECTED" | "LOADING";

class StateActions {
  rdAllModalities$ = new Rx.BehaviorSubject<RD.RemoteData<any, Modality[]>>(
    RD.initial
  );
  isDroppedDown$ = new Rx.BehaviorSubject<boolean>(false);
  viewState$: Rx.Observable<ViewState>;

  constructor(
    readonly onSelect: (modality: Modality) => void,
    readonly myModalities$: Rx.BehaviorSubject<Modality[]>
  ) {
    this.viewState$ = Rx.combineLatest([
      this.rdAllModalities$,
      this.isDroppedDown$,
    ]).pipe(
      RxO.map(([rd, isDroppedDown]) => {
        if (RD.isSuccess(rd)) {
          return isDroppedDown ? "DROPPED_DOWN" : "SELECTED";
        }

        return "LOADING";
      })
    );
    this.fetchAndSetAllModalities();
  }

  fetchAndSetAllModalities() {
    this.rdAllModalities$.next(RD.pending);
    BE.publicTE(() => BE.PublicApi.listTherapyTypes.query())().then((er) => {
      this.rdAllModalities$.next(RD.fromEither(er));
    });
  }

  onSelectableModalityClick = (modality: Modality) => {
    this.myModalities$.next([...this.myModalities$.value, modality]);

    this.onSelect(modality);
  };

  unselectModality = (modality: Modality) => {
    this.myModalities$.next(
      this.myModalities$.value.filter((m) => m.slug !== modality.slug)
    );
  };

  setIsDroppedDown = (state: boolean) => {
    this.isDroppedDown$.next(state);
  };
}

const [StateActionsContext, useStateActions] =
  createContextAndHook<StateActions>();

export const ModalitiesOfferedSelector: React.FC<{
  myModalities$: Rx.BehaviorSubject<Modality[]>;
  onSelect: (modality: Modality) => void;
}> = ({ myModalities$, onSelect }) => {
  const saMgr = useMemo(() => new StateActions(onSelect, myModalities$), []);
  const viewState = useObservableState(saMgr.viewState$, "LOADING");

  return (
    <StateActionsContext.Provider value={saMgr}>
      <div className="w-full">
        {viewState === "LOADING" ? (
          <div></div>
        ) : viewState === "DROPPED_DOWN" ? (
          <DroppedDownView />
        ) : (
          <SelectedView />
        )}
      </div>
    </StateActionsContext.Provider>
  );
};

const SelectedView: React.FC = () => {
  const saMgr = useStateActions();
  const myModalities = useObservableEagerState(saMgr.myModalities$);

  return (
    <div className="border border-vid-black-200 rounded-[12px] pl-8 py-4 flex justify-between">
      <div className="flex-1 flex flex-wrap gap-4">
        {myModalities.length === 0 ? (
          <h4>Select modalities you offer</h4>
        ) : (
          myModalities.map((m) => <SelectedModalityPill modality={m} />)
        )}
      </div>
      <div
        className="flex justify-end items-center px-6 cursor-pointer"
        onClick={() => {
          saMgr.setIsDroppedDown(true);
        }}
      >
        <IoArrowDownCircle size={24} />
      </div>
    </div>
  );
};

const SelectedModalityPill: React.FC<{ modality: Modality }> = ({
  modality,
}) => {
  const saMgr = useStateActions();
  return (
    <div
      className="bg-gray-200 rounded-md px-2 py-1 cursor-pointer flex items-center gap-2"
      onClick={() => {
        saMgr.unselectModality(modality);
      }}
    >
      <IoCloseCircle size={14} />
      <span>{modality.name}</span>
    </div>
  );
};

const DroppedDownView: React.FC = () => {
  const saMgr = useStateActions();
  const selectedModalities = useObservableEagerState(saMgr.myModalities$);
  const rdAllModalities = useObservableEagerState(saMgr.rdAllModalities$);

  if (!RD.isSuccess(rdAllModalities)) {
    return <></>;
  }

  return (
    <div className="text-input flex flex-col gap-2 p-4">
      {rdAllModalities.value
        .filter(
          (m) => !selectedModalities.map((sm) => sm.slug).includes(m.slug)
        )
        .map((m) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              saMgr.onSelectableModalityClick(m);
              saMgr.setIsDroppedDown(false);
            }}
          >
            {m.name}
          </div>
        ))}
    </div>
  );
};
