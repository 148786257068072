import { Menu, Switch } from "@headlessui/react";
import { format } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import { useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { RecurringStatus } from "shared";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { E, O, RD } from "shared/base-prelude";
import { FirebaseJsMgr, useFirebaseJs } from "shared/firebase";
import {
  defaultGroupSessionState,
  GroupSessionStateSchema,
} from "shared/session-state/session-state";
import { BE } from "../../../../../../../backend";
import { CalendarMenuInput } from "../../../../../../../components/form/calendar.input";
import {
  TextAreaInput$,
  TextInput$,
} from "../../../../../../../components/form/form-inputs.fc";
import { PrimaryButton } from "../../../../../../../components/primitives/button";
import { FullContainerLoadingSpinner } from "../../../../../../../loading";
import { FirestoreSessionStateMgmt } from "../../../../../../../mgrs/live-session/firestore-live-session.mgmt";
import { Rx } from "../../../../../../../prelude";
import { HP_ROUTES } from "../../../../../../../routes/hp-routes";
import { useHpState } from "../../../../../hp.state";
import { CommunityCardContainer } from "../../components/community.components";
import { useCommunitySam } from "../community.state";

export const CommunityEventsTabLayout: React.FC = () => {
  return (
    <div className="flex flex-col gap-8">
      <Outlet />
    </div>
  );
};

export const CommunityEventsTabAll: React.FC = () => {
  const hpState = useHpState();
  const sam = useCommunitySam();
  const nav = useNavigate();

  const rdAllEvents = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.community.listEvents.query({
        communitySlug: sam.community.slug,
      })
    )
  );

  return pipe(
    rdAllEvents,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (res) => (
        <div className="flex flex-col gap-8 rounded-lg">
          <GoLiveNowButton />
          <div>
            <PrimaryButton
              title="Create event"
              onClick={() => {
                hpState.openRightNav({
                  _tag: "CUSTOM",
                  topView: <div>Create event</div>,
                  content: (
                    <CreateEventForm
                      communitySlug={sam.community.slug}
                      onSuccessSubmit={() => {
                        hpState.closeRightNav();
                        hpState.showBottomToast({
                          msg: "Event created",
                          reload: true,
                        });
                      }}
                    />
                  ),
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-4">
            <h4 className="font-semibold text-lg">Events</h4>

            <div className="flex justify-between max-w-full overflow-x-auto lg:flex-wrap gap-4">
              {res.map((evt) => (
                <CommunityCardContainer
                  key={evt.id}
                  title={evt.title}
                  headerBackgroundType={{ _tag: "EVENT_PURPLE" }}
                  innerCircleComponent={<div></div>}
                  content={
                    <div className="flex flex-col items-center">
                      <h4 className="font-bold">
                        {evt.recurring_status !== "none" &&
                          `Occurs ${evt.recurring_status}`}
                      </h4>
                      {format(evt.start_time, "P p")}
                    </div>
                  }
                  button={{
                    title: "View",
                    onClick: () => {
                      nav(
                        HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.EVENT_ID.buildPath(
                          {
                            community: sam.community.slug,
                            eventId: evt.id,
                          }
                        )
                      );
                    },
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )
    )
  );
};

async function startGroupSession(
  communitySlug: string,
  firebaseJs: FirebaseJsMgr
) {
  const res = await BE.authedTE((tkn) =>
    BE.Api(tkn).u.createGroupSession.mutate({
      communitySlug,
      role: "admin",
    })
  )();

  console.log("RESULT OF CREATING GROUP SESSION! ", res);
  if (E.isRight(res)) {
    const firestoreMgmt = new FirestoreSessionStateMgmt(
      firebaseJs,
      { _tag: "GROUP", groupSessionId: res.right.groupSessionId },
      defaultGroupSessionState,
      GroupSessionStateSchema
    );
    await firestoreMgmt.createSessionStateDoc();
    return E.right(res.right);
  }

  return E.left(res.left);
}

const GoLiveNowButton: React.FC = () => {
  const nav = useNavigate();
  const sam = useCommunitySam();
  const firebaseJs = useFirebaseJs();
  return (
    <div className="flex p-0 lg:p-8 lg:border-4 rounded-lg lg:shadow-md">
      <button
        className="w-full lg:w-[200px] lg:ml-4 bg-vid-purple text-center text-white p-buttons lg:p-3 lg:mb-4 rounded-xl"
        onClick={() => {
          startGroupSession(sam.community.slug, firebaseJs).then((er) => {
            if (E.isRight(er)) {
              nav(
                HP_ROUTES.MY.GROUP_SESSIONS.GROUP_SESSION_ID.WAITING_ROOM.buildPath(
                  {
                    groupSessionId: er.right.groupSessionId,
                  }
                )
              );
            }
          });
        }}
      >
        Go live now
      </button>
    </div>
  );
};

class CreateEventFormMgr {
  startTime$ = new Rx.BehaviorSubject<O.Option<Date>>(O.none);
  endTime$ = new Rx.BehaviorSubject<O.Option<Date>>(O.none);
  name$ = new Rx.BehaviorSubject<string>("");
  description$ = new Rx.BehaviorSubject<string>("");
  recurringStatus$ = new Rx.BehaviorSubject<RecurringStatus>("none");
  rdSubmitRes$ = new Rx.BehaviorSubject<RD.RemoteData<any, any>>(RD.initial);
  sendInviteToAll$ = new Rx.BehaviorSubject<boolean>(false);

  constructor(
    readonly communitySlug: string,
    readonly onSuccessSubmit: () => void
  ) {}

  getFullForm = () => {
    const startTime = O.toNullable(this.startTime$.value);
    const endTime = O.toNullable(this.endTime$.value);
    const name = this.name$.value;
    const description = this.description$.value;
    const recurringStatus = this.recurringStatus$.value;

    return {
      startTime,
      endTime,
      name,
      description,
      recurringStatus,
      sendInviteToAll: this.sendInviteToAll$.value,
    };
  };

  submit = async () => {
    console.log(this.startTime$.value, this.endTime$.value, this.name$.value);
    const {
      startTime,
      endTime,
      name,
      description,
      recurringStatus,
      sendInviteToAll,
    } = this.getFullForm();

    if (startTime === null) {
      alert("Start time is required");
      return;
    }

    this.rdSubmitRes$.next(RD.pending);
    const submitRes = await BE.authedTE((tkn) =>
      BE.Api(tkn).hp.community.createEvent.mutate({
        communitySlug: this.communitySlug,
        title: name,
        description,
        recurringStatus,
        startDate: startTime,
        endDate: endTime,
        sendInviteToAll,
      })
    )();

    this.rdSubmitRes$.next(RD.fromEither(submitRes));

    if (E.isRight(submitRes)) {
      this.onSuccessSubmit();
    }
  };
}

const CreateEventForm: React.FC<{
  communitySlug: string;
  onSuccessSubmit: () => void;
}> = ({ communitySlug, onSuccessSubmit }) => {
  const mgr = useMemo(
    () => new CreateEventFormMgr(communitySlug, onSuccessSubmit),
    []
  );

  return (
    <div className="flex-1 flex flex-col justify-between p-4">
      <div className="flex flex-col gap-8">
        <TextInput$ label="Name" value$={mgr.name$} />
        <TextAreaInput$ label="Description" value$={mgr.description$} />
        <CalendarMenuInput
          title="Start time"
          onSelection={(dt) => {
            mgr.startTime$.next(O.some(dt));
          }}
        />
        <CalendarMenuInput
          title="End time (optional)"
          onSelection={(dt) => {
            mgr.endTime$.next(O.some(dt));
          }}
        />
        <RecurringInput$
          recurringStatus$={mgr.recurringStatus$}
          onSelect={(rs) => {
            mgr.recurringStatus$.next(rs);
          }}
        />
        <SendInviteToAllButton
          enabled$={mgr.sendInviteToAll$}
          onChange={(enabled) => {
            mgr.sendInviteToAll$.next(enabled);
          }}
        />
      </div>
      <PrimaryButton title="Submit" onClick={() => mgr.submit()} />
    </div>
  );
};

const SendInviteToAllButton: React.FC<{
  enabled$: Rx.BehaviorSubject<boolean>;
  onChange: (enabled: boolean) => void;
}> = ({ enabled$, onChange }) => {
  const enabled = useObservableEagerState(enabled$);
  return (
    <div className="flex flex-col gap-4">
      <label className="font-semibold text-vid-purple">
        Send invite to all community members?
      </label>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={`${
          enabled ? "bg-blue-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">Send invite to all community members?</span>
        <span
          className={`${
            enabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
};

const RecurringInput$: React.FC<{
  recurringStatus$: Rx.BehaviorSubject<RecurringStatus>;
  onSelect: (rs: RecurringStatus) => void;
}> = ({ recurringStatus$, onSelect }) => {
  const recurringStatus = useObservableEagerState(recurringStatus$);
  return (
    <Menu as="div" className="text-input">
      <Menu.Button className="flex gap-4">
        <label className="defaultLabel">Recurring (optional)</label>
        <div>{recurringStatus}</div>
      </Menu.Button>
      <Menu.Items>
        {ALL_RECURRING_STATUSES.map((rs) => (
          <Menu.Item>
            <div
              className="cursor-pointer"
              onClick={() => {
                onSelect(rs);
              }}
            >
              {rs}
            </div>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

const ALL_RECURRING_STATUSES: RecurringStatus[] = [
  "none",
  "daily",
  "weekly",
  "monthly",
  "yearly",
];
