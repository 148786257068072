import { TrpcFetchError, publicTrpc, trpcQueryToTE, uTrpc } from "./trpc-cli";

import { CaptureContext } from "@sentry/types";
import { Effect, pipe as effPipe } from "effect";
import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import { RetryPolicy } from "retry-ts";
import { retrying } from "retry-ts/lib/Task";
import * as Rx from "rxjs";
import { E, O, RD, T, TE } from "shared/base-prelude";
import { createContextAndHook } from "../util";

type AuthedApi = ReturnType<ApiMgr["Api"]>;

export type AuthedFetchError<E = unknown> =
  | { _tag: "FirebaseTokenError"; error: Error }
  | { _tag: "FetchError"; error: E };

export class ApiMgr {
  constructor(
    readonly apiUrl: string,
    readonly getAssumedFirebaseTokenTE: TE.TaskEither<Error, string>,
    readonly reportError: (e: {
      message: string;
      extra?: CaptureContext;
    }) => void
  ) {
    console.log("THIS API URL! ", this.apiUrl);
  }

  Api = (jwt: string) => uTrpc({ API_URL: `${this.apiUrl}/trpc`, jwt });

  PublicApi = () => publicTrpc({ API_URL: `${this.apiUrl}/trpc` });

  authedQueryTE = <V>(
    authedTE: (fbToken: string) => TE.TaskEither<TrpcFetchError, V>
  ) => {
    return pipe(
      this.getAssumedFirebaseTokenTE,
      TE.mapLeft(
        (e) =>
          ({
            _tag: "FirebaseTokenError",
            error: e,
          } as AuthedFetchError<TrpcFetchError>)
      ),
      TE.chain((fbToken) => {
        return pipe(
          authedTE(fbToken),
          TE.mapLeft(
            (error) =>
              ({
                _tag: "FetchError",
                error,
              } as AuthedFetchError<TrpcFetchError>)
          )
        );
      })
    );
  };

  mbFetchErrorForSentry = (
    error: AuthedFetchError<TrpcFetchError>
  ): O.Option<{ message: string; extra: CaptureContext }> => {
    {
      console.log("DETERMING MAYBE FETCHED ERROR FOR SENTRY! ", error);
      if (error._tag === "FirebaseTokenError") {
        return O.some({
          message: error.error.message,
          extra: { level: "error" },
        });
      } else {
        if (error.error.data?.code === "INTERNAL_SERVER_ERROR") {
          return O.some({
            message: JSON.stringify(error.error.message),
            extra: {
              level: "error",
              tags: {
                code: error.error.data?.code,
              },
            },
          });
        } else {
          return O.some({
            message: JSON.stringify(error.error.message),
            extra: {
              level: "warning",
              tags: {
                code: error.error.data?.code,
              },
            },
          });
        }
      }
    }
  };

  fetchEndpointTE = <V>(
    trpcquery: (api: AuthedApi) => Promise<V>
  ): TE.TaskEither<AuthedFetchError<TrpcFetchError>, V> => {
    return this.authedTE((fbToken) => trpcquery(this.Api(fbToken)));
  };

  authedTE = <V>(
    trpcquery: (jwt: string) => Promise<V>
  ): TE.TaskEither<AuthedFetchError<TrpcFetchError>, V> => {
    const baseTE = this.authedQueryTE((fbToken) =>
      trpcQueryToTE<V>(() => trpcquery(fbToken))
    );

    return pipe(
      baseTE,
      T.chain((er) => {
        return pipe(
          T.fromIO(() => {
            if (E.isLeft(er)) {
              const mbFetchError = this.mbFetchErrorForSentry(er.left);
              console.log("MB FETCH ERROR! ", mbFetchError);
              if (O.isSome(mbFetchError)) {
                this.reportError(mbFetchError.value);
              }
            }
          }),
          T.chain((_) => T.of(er))
        );
      })
    );
  };

  authedEff = <V>(
    trpcquery: (jwt: string) => Promise<V>
  ): Effect.Effect<V, AuthedFetchError<TrpcFetchError>, unknown> => {
    const self = this;
    return effPipe(
      Effect.promise(() => self.authedTE(trpcquery)()),
      Effect.flatMap((er) =>
        pipe(
          er,
          E.fold(
            (l) =>
              Effect.fail(l) as Effect.Effect<
                V,
                AuthedFetchError<TrpcFetchError>,
                unknown
              >,
            (r) =>
              Effect.succeed(r) as Effect.Effect<
                V,
                AuthedFetchError<TrpcFetchError>,
                unknown
              >
          )
        )
      )
    );
  };

  publicTE<V>(trpcquery: () => Promise<V>): TE.TaskEither<TrpcFetchError, V> {
    return trpcQueryToTE<V>(trpcquery);
  }
}

export function useTaskEither<E, V = unknown>(
  te: TE.TaskEither<E, V>,
  deps?: any[],
  onError?: (e: E) => void
) {
  const rdValue$ = useTaskEither$({ baseTe: te, deps, onError });
  const rdValue = useObservableEagerState(rdValue$);

  return rdValue;
}

export function useTaskEither$<E, V = unknown>(p: {
  baseTe: TE.TaskEither<E, V>;
  deps?: any[];
  onError?: (e: E) => void;
  withRetry?: {
    policy: RetryPolicy;
    isComplete: (e: E.Either<E, V>) => boolean;
  };
}) {
  const { baseTe, deps, onError, withRetry } = p;
  const rdValue$ = React.useMemo(
    () => new Rx.BehaviorSubject<RD.RemoteData<E, V>>(RD.initial),
    []
  );

  useEffect(() => {
    rdValue$.next(RD.pending);

    const te = withRetry
      ? retrying(
          withRetry.policy,
          (status) =>
            pipe(
              baseTe,
              TE.chainFirst((r) =>
                TE.fromIO(() => {
                  console.log("retrying", status, r);
                  return rdValue$.next(RD.success(r));
                })
              )
            ),
          withRetry.isComplete
        )
      : baseTe;
    te().then((er) => {
      rdValue$.next(RD.fromEither(er));

      if (E.isLeft(er) && onError) {
        onError(er.left);
      }
    });
  }, deps ?? []);

  return rdValue$;
}

export const [ApiMgrContext, useApiMgr] = createContextAndHook<ApiMgr>();

export function useAuthedFetchTE<V>(
  trpcQueryP: (api: AuthedApi) => Promise<V>,
  deps: any[]
) {
  const apiMgr = useApiMgr();

  const rdV = useTaskEither(
    apiMgr.authedTE<V>((tkn) => trpcQueryP(apiMgr.Api(tkn))),
    deps
  );

  return rdV;
}
