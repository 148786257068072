import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { UApiInput } from "shared/backend-api/trpc-cli";
import { BE, DefaultError } from "../../../../../backend";
import { E, RD, Rx } from "../../../../../prelude";
import { useHpState } from "../../../hp.state";
import { FeedUploadableFileTypes } from "shared";
import { FullContainerLoadingSpinner } from "../../../../../loading";

type NewCommunityPostPayload = UApiInput["hp"]["community"]["submitPost"];

class StateActions {
  communitySlug$: Rx.BehaviorSubject<string>;
  mainTextContent$ = new Rx.BehaviorSubject<string>("");
  uploadedImageOrVideo$ = new Rx.BehaviorSubject<File | null>(null);

  submitResult$ = new Rx.BehaviorSubject<RD.RemoteData<DefaultError, unknown>>(
    RD.initial
  );

  constructor(
    communitySlug: string,
    readonly posterId: string,
    readonly onSuccessSubmit: () => void
  ) {
    this.communitySlug$ = new Rx.BehaviorSubject<string>(communitySlug);
  }

  setCommunitySlug = (slug: string) => {
    this.communitySlug$.next(slug);
  };

  setMainTextContent = (content: string) => {
    this.mainTextContent$.next(content);
  };

  setUploadedImageOrVideo = (file: File | null) => {
    this.uploadedImageOrVideo$.next(file);
  };

  submit = async () => {
    console.log("COMMUNITY SLUG! ", this.communitySlug$.value);
    const payload: NewCommunityPostPayload = {
      communitySlug: this.communitySlug$.value,
      mainTextContent: this.mainTextContent$.value,
      fileType: this.uploadedImageOrVideo$.value
        ?.type as FeedUploadableFileTypes,
    };

    this.submitResult$.next(RD.pending);

    const submitRes = await BE.authedTE((tkn) =>
      BE.Api(tkn).hp.community.submitPost.mutate(payload)
    )();

    if (E.isRight(submitRes)) {
      console.log("UPLOAD IMAGE OR VIDEO! ", this.uploadedImageOrVideo$.value);
      const inputtedFile = this.uploadedImageOrVideo$.value;
      if (inputtedFile) {
        console.log(
          "UPLOADED IMAGE OR VIDEO! ",
          this.uploadedImageOrVideo$.value
        );

        const euploadUrl = await BE.authedTE((tkn) =>
          BE.Api(tkn).hp.community.getPostFileUploadUrl.query({
            communitySlug: this.communitySlug$.value,
            fileType: inputtedFile.type,
            activityId: submitRes.right.res.id,
          })
        )();

        console.log("UPLAOD URL! ", euploadUrl);

        if (E.isRight(euploadUrl)) {
          console.log("UPLOADING TO S3! ", euploadUrl.right);
          const uploadToS3Result = await fetch(euploadUrl.right.uploadUrl, {
            method: "PUT",
            body: this.uploadedImageOrVideo$.value,
          });

          console.log("UPLOADED TO S3 RESULT! ", uploadToS3Result);
        }
      }
    }

    this.submitResult$.next(RD.fromEither(submitRes));

    if (E.isRight(submitRes)) {
      this.onSuccessSubmit();
    }
  };
}

export const CreateCommunityPostForm: React.FC<{
  communitySlug: string;
  posterId: string;
}> = ({ communitySlug, posterId }) => {
  const dashboardState = useHpState();
  const stateMgr = React.useMemo(
    () =>
      new StateActions(communitySlug, posterId, () => {
        dashboardState.closeRightNav();

        setTimeout(() => {
          dashboardState.showBottomToastSignal$.next({
            msg: "Post created",
            reload: true,
          });
        }, 300);
      }),
    [posterId]
  );

  const mainTextContent = useObservableEagerState(stateMgr.mainTextContent$);
  const rdSubmitResult = useObservableEagerState(stateMgr.submitResult$);
  const uploadedImageOrVideo = useObservableEagerState(
    stateMgr.uploadedImageOrVideo$
  );

  return (
    <div className="flex flex-col p-4">
      <form
        className="flex flex-col gap-4"
        onSubmit={(e) => {
          e.preventDefault();
          stateMgr.submit().catch();
        }}
      >
        <textarea
          value={mainTextContent}
          onChange={(e) => stateMgr.setMainTextContent(e.target.value)}
          className="w-full border rounded-lg px-4 py-2"
        />
        <div className="flex flex-col gap-4">
          <label className="text-lg font-medium">Upload Image or Video</label>
          <input
            type="file"
            accept="image/*,video/*"
            onChange={(e) => {
              const file = e.target.files ? e.target.files[0] : null;
              console.log("CHANGE FILE! ", file);
              if (file) {
                stateMgr.setUploadedImageOrVideo(file);
              }
            }}
          />
          {uploadedImageOrVideo && (
            <img
              src={URL.createObjectURL(uploadedImageOrVideo)}
              alt="Uploaded Image"
            />
          )}
        </div>
        <button
          type="submit"
          className={`
          border-2 border-vid-purple px-4 py-2 rounded-full cursor-pointer hover:bg-vid-purple hover:text-white
          `}
        >
          {RD.isPending(rdSubmitResult) ? (
            <div>
              <FullContainerLoadingSpinner />
            </div>
          ) : (
            <div>Submit</div>
          )}
        </button>
        {RD.isFailure(rdSubmitResult) && (
          <div className="text-red-500">
            {rdSubmitResult.error.error.message}
          </div>
        )}
      </form>
    </div>
  );
};
