import { VidalifySvgIcon } from "./vidalify-logo.fc";

export const VidalifyLogoWithText: React.FC<{}> = () => {
  return (
    <div className="flex items-center gap-2 ">
      <VidalifySvgIcon />
      {/* <div className="mt-1">
        <VidalifyTextIcon />
      </div> */}
    </div>
  );
};
