const discover = [
  {
    img: "/dashboard/carousel-1.png",
    title: "Advanced Hakomi Techniques",
    type: "Hakomi",
    healer: "Ana Mendieta",
    healerImg: "/dashboard/healer-1.png",
    role: "Therapist",
  },
  {
    img: "/dashboard/carousel-1.png",
    title: "Advanced Hakomi Techniques",
    type: "EMDR",
    healer: "Ana Mendieta",
    healerImg: "/dashboard/healer-1.png",
    role: "Therapist",
  },
];

export default discover;
