const welcome = [
    {
        "actionItemName": "Set up your profile",
        "isChecked": true,
        "icon": "/dashboard/profile.svg"
    },
    {
        "actionItemName": "Invite your first client",
        "isChecked": true,
        "icon": "/dashboard/invite.svg"
    },
    {
        "actionItemName": "Create your first session",
        "isChecked": true,
        "icon": "/dashboard/first-session.svg"
    },
    {
        "actionItemName": "Set up billing method",
        "isChecked": false,
        "icon": "/dashboard/billing.svg"
    },
    {
        "actionItemName": "Visit a training",
        "isChecked": false,
        "icon": "/dashboard/training.svg"
    },
]

export default welcome;