import { Menu } from "@headlessui/react";
import { useObservableEagerState } from "observable-hooks";
import { Rx } from "../../prelude";

export const TextInput = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <div className="text-input flex flex-col">
      <label>{label}</label>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export const TextInput$ = ({
  label,
  value$,
}: {
  label: string;
  value$: Rx.BehaviorSubject<string>;
}) => {
  const value = useObservableEagerState(value$);
  return (
    <TextInput
      label={label}
      value={value}
      onChange={(value) => value$.next(value)}
    />
  );
};

export const TextAreaInput = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <div className="text-input flex flex-col">
      <label>{label}</label>
      <textarea value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

export const TextAreaInput$ = ({
  label,
  value$,
}: {
  label: string;
  value$: Rx.BehaviorSubject<string>;
}) => {
  const value = useObservableEagerState(value$);
  return (
    <TextAreaInput
      label={label}
      value={value}
      onChange={(value) => value$.next(value)}
    />
  );
};

export const FormMenuInput: React.FC<{
  buttonView: React.ReactNode;
  dropdownView: (close: () => void) => React.ReactNode;
  height?: number;
}> = ({ buttonView, dropdownView, height }) => {
  return (
    <Menu
      as="div"
      className={`relative border rounded-[12px] flex ${
        height ? `h-${height}` : ""
      }`}
    >
      <Menu.Button className="flex-1 flex items-center gap-4 p-4">
        {buttonView}
      </Menu.Button>
      <Menu.Items className="absolute left-0 border-4 z-20 bg-white rounded-lg">
        <Menu.Item>{({ close }) => <>{dropdownView(close)}</>}</Menu.Item>
      </Menu.Items>
    </Menu>
  );
};
