import { TrpcFetchError } from "shared/backend-api/trpc-cli";

import { Effect } from "effect";
import { ApiMgr, AuthedFetchError } from "shared/backend-api/api.mgr";
import { taskEitherToEff } from "shared/base-prelude";
import { AppConfig, firebaseMgr } from "./app-config";
import { ErrorTracking } from "./error-tracking";

console.log("API URL! ", AppConfig.apiUrl);

const apiMgr = new ApiMgr(
  AppConfig.apiUrl,
  firebaseMgr.getAssumedFirebaseTokenTE,
  ErrorTracking.reportError
);

export type DefaultError = AuthedFetchError<TrpcFetchError>;

export class BE {
  static Api = apiMgr.Api;

  static PublicApi = apiMgr.PublicApi();

  static authedTE = apiMgr.authedTE;
  static fetchEndpointTE = apiMgr.fetchEndpointTE; // This is the authed Api
  static publicTE = apiMgr.publicTE;

  static authedEff = <V>(
    trqpcQuery: (jwt: string) => Promise<V>
  ): Effect.Effect<V, DefaultError, never> =>
    taskEitherToEff(apiMgr.authedTE(trqpcQuery));
}
