import React, { useState } from "react";

type ImageIconProps = {
  src: string;
  alt: string;
  className?: string;
  onClick: () => void;
};

const ImageIcon: React.FC<ImageIconProps> = ({
  src,
  alt,
  className = "",
  onClick,
}) => (
  <button onClick={onClick} className={`focus:outline-none`}>
    <img
      src={src}
      alt={alt}
      loading="lazy"
      className={`w-6 aspect-square ${className}`}
    />
  </button>
);

type ProgressBarProps = {
  onClick: () => void;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ onClick }) => (
  <div
    onClick={onClick}
    className="self-start mt-3 w-20 h-0.5 bg-violet-700 rounded-xl cursor-pointer"
  ></div>
);

type DotIndicatorProps = {
  onClick: () => void;
};

const DotIndicator: React.FC<DotIndicatorProps> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="my-auto w-3.5 h-3.5 bg-violet-700 rounded-[52px] focus:outline-none"
  ></button>
);

type AudioPlayerProps = {};

export const AudioPlayer: React.FC<AudioPlayerProps> = () => {
  const [currentIcon, setCurrentIcon] = useState(0);
  const [currentTrackTime, setCurrentTrackTime] = useState("10:05");

  const icons = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/fd9e31a418380f0b483858568526c51f3d98e0fd4c3feae1620ef102995e3ec3?apiKey=7548643458aa4f489ce4f6ae79dc4302&",
      alt: "Audio control icon 1",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8cfadf9e0af5eae17a1dc5edb1404947bc401067a2581e0356c8ae26691edfd3?apiKey=7548643458aa4f489ce4f6ae79dc4302&",
      alt: "Audio control icon 2",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/04631809ce7a2cbdee203591338c165be0f9789fe80d97512c34dd0ce5508d6f?apiKey=7548643458aa4f489ce4f6ae79dc4302&",
      alt: "Audio control icon 3",
    },
  ];

  const updateTime = () => {
    setCurrentTrackTime("15:30");
  };

  const changeIcon = () => {
    setCurrentIcon((currentIcon + 1) % icons.length);
  };

  return (
    <div className="flex gap-5 justify-between px-5 py-3 bg-white rounded-xl border border-solid border-gray-200 max-w-[941px] md:flex-wrap">
      <header className="flex gap-0 my-auto">
        <div className="flex gap-5 pr-14">
          {icons.map((icon, index) => (
            <ImageIcon
              key={index}
              src={icon.src}
              alt={icon.alt}
              onClick={changeIcon}
            />
          ))}
        </div>
        <ProgressBar onClick={updateTime} />
        <DotIndicator onClick={changeIcon} />
      </header>
      <section className="flex gap-4 justify-between text-sm leading-4 text-center whitespace-nowrap">
        <div className="flex gap-1 my-auto">
          <div className="grow text-zinc-500">{currentTrackTime} /</div>
          <div className="grow text-gray-900">60:00</div>
        </div>
        <ImageIcon
          src={icons[currentIcon].src}
          alt="Current track thumbnail"
          onClick={() => {}}
        />
      </section>
    </div>
  );
};
