import {
  CallingState,
  ParticipantView,
  StreamVideoParticipant,
  useCall,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { useNavigate } from "react-router-dom";
import { E } from "shared/base-prelude";
import { BE } from "../../../../backend";
import { ControlPanelComponents } from "../../../../components/live-session/control-panel.components";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
  WaitingRoomContainer,
} from "../../../../components/live-session/live-session.components";
import { PrimaryButton } from "../../../../components/primitives/button";
import { HP_ROUTES } from "../../../../routes/hp-routes";
import { useGroupSessionId } from "./group-session.state";

export const HpGroupSessionWaitingRoomPage: React.FC = () => {
  const call = useCall()!;
  const groupSessionId = useGroupSessionId();
  const nav = useNavigate();

  return (
    <WaitingRoomContainer
      onBackClick={() => {
        call.endCall().then((_) => {
          nav(HP_ROUTES.MY.DASHBOARD.CLIENTS.path);

          BE.authedTE((tkn) =>
            BE.Api(tkn).u.groupSession.deleteGroupSession.mutate({
              groupSessionId,
            })
          )().catch();
        });
      }}
      leftSide={<MyUILayout />}
      rightSide={
        <div className="border flex flex-col">
          <PrimaryButton
            title="Invite all members to join"
            onClick={() => {
              BE.authedTE((tkn) =>
                BE.Api(tkn).u.groupSession.inviteAllGroupMembersToJoin.mutate({
                  groupSessionId,
                })
              )().then((er) => {
                if (E.isRight(er)) {
                  alert("Invites sent");
                }
              });
              // nav(
              //   HP_ROUTES.MY.GROUP_SESSIONS.GROUP_SESSION_ID.MAIN_ROOM.buildPath(
              //     {
              //       groupSessionId,
              //     }
              //   )
              // );
            }}
          />
        </div>
      }
      onStartLink={HP_ROUTES.MY.GROUP_SESSIONS.GROUP_SESSION_ID.MAIN_ROOM.buildPath(
        { groupSessionId }
      )}
    />
  );
};

const MyUILayout = () => {
  const { useCallCallingState, useLocalParticipant } = useCallStateHooks();

  const callingState = useCallCallingState();
  const localParticipant = useLocalParticipant();
  const { useMicrophoneState, useCameraState } = useCallStateHooks();
  const { microphone, isMute: isAudioMute } = useMicrophoneState();
  const { camera, isMute: isVideoMute } = useCameraState();

  if (callingState !== CallingState.JOINED || localParticipant === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative w-full h-full">
      <ParticipantView
        participant={localParticipant}
        ParticipantViewUI={CustomParticipantViewUI}
        VideoPlaceholder={CustomVideoPlaceholder}
      />
      <div className="absolute bottom-2 right-4 flex gap-2">
        <ControlPanelComponents.MuteAudioButton
          isMuted={isAudioMute}
          onClick={() => {
            microphone?.toggle();
          }}
        />
        <ControlPanelComponents.MuteVideoButton
          isMuted={isVideoMute}
          onClick={() => {
            camera?.toggle();
          }}
        />
      </div>
    </div>
  );
};

export const MyParticipantList = (props: {
  participants: StreamVideoParticipant[];
}) => {
  const { participants } = props;
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
      {participants.map((participant) => (
        <ParticipantView
          participant={participant}
          key={participant.sessionId}
          ParticipantViewUI={CustomParticipantViewUI}
          VideoPlaceholder={CustomVideoPlaceholder}
        />
      ))}
    </div>
  );
};

export const MyFloatingLocalParticipant = (props: {
  participant?: StreamVideoParticipant;
}) => {
  const { participant } = props;

  if (!participant) {
    return <></>;
  }

  return (
    <div className="absolute top-4 left-4 w-60 h-40 shadow-md rounded-lg">
      <ParticipantView
        participant={participant}
        ParticipantViewUI={CustomParticipantViewUI}
        VideoPlaceholder={CustomVideoPlaceholder}
      />
    </div>
  );
};
