import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirebaseJs } from "shared/firebase";

export const AnonConfirmEmailViewOurWorkPage: React.FC = () => {
  const nav = useNavigate();
  const url = useLocation();
  const firebaseJs = useFirebaseJs();
  const emailQueryParam = new URLSearchParams(url.search).get("email")!;
  const clientIdQueryParam = new URLSearchParams(url.search).get("clientId")!;

  useEffect(() => {
    if (isSignInWithEmailLink(firebaseJs.auth, window.location.href)) {
      signInWithEmailLink(
        firebaseJs.auth,
        emailQueryParam,
        window.location.href
      )
        .then((result) => {
          console.log("RESULT: ", result);
          setTimeout(() => {
            nav(`/hp/my/dashboard/clients/${clientIdQueryParam}`);
          }, 1000);
        })
        .catch((error) => {
          // Handle errors
          console.log("UH OH!", error);
        });
    }
  }, []);

  return (
    <div>
      <h1>{`Hi ${emailQueryParam}!. You are being redirected now to your session`}</h1>
    </div>
  );
};
