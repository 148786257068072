import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirebaseJs } from "shared/firebase";
import { CP_ROUTES } from "../../../routes/cp-routes";

export const AnonConfrimEmailInviteJoinSessionPage: React.FC = () => {
  const nav = useNavigate();
  const url = useLocation();
  const firebaseJs = useFirebaseJs();
  const emailQueryParam = new URLSearchParams(url.search).get("email")!;
  const sessionIdQueryParam = new URLSearchParams(url.search).get("sessionId")!;
  // ...

  useEffect(() => {
    if (isSignInWithEmailLink(firebaseJs.auth, window.location.href)) {
      signInWithEmailLink(
        firebaseJs.auth,
        emailQueryParam,
        window.location.href
      )
        .then((result) => {
          console.log("RESULT: ", result);
          setTimeout(() => {
            nav(
              CP_ROUTES.SESSIONS.SESSION_ID.LIVE.MAIN_ROOM.buildPath({
                sessionId: sessionIdQueryParam,
              })
            );
          }, 1000);
        })
        .catch((error) => {
          // Handle errors
          console.log("UH OH!", error);
        });
    }
  }, []);

  return (
    <div>
      <h1>{`Hi ${emailQueryParam}!. You are being redirected now to your session`}</h1>
    </div>
  );
};
