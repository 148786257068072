import React from "react";
import { SessionInvoice } from "shared";

export const InvoiceDownloadForm: React.FC<{ invoice: SessionInvoice }> = ({
  invoice,
}) => {
  return (
    <div className="flex flex-col items-center border-2 border-green-400">
      <h1 className="text-2xl font-bold">Invoice</h1>
      <div className="flex justify-between self-stretch">
        <div className="flex border rounded-full">
          {invoice.from.profilePhotoDownloadUrl && (
            <img
              src={invoice.from.profilePhotoDownloadUrl}
              className="w-8 h-8 rounded-full"
            />
          )}
          <div className="flex flex-col gap-2">
            <span className="bg-gray-200">FROM</span>
            <span className="text-2xl">{invoice.from.name}</span>
          </div>
        </div>
        <div className="flex border rounded-full">
          <div className="flex">
            {invoice.client.profilePhotoDownloadUrl && (
              <img
                src={invoice.client.profilePhotoDownloadUrl}
                className="w-8 h-8 rounded-full"
              />
            )}
            <div className="flex flex-col gap-2">
              <span className="bg-gray-200">TO</span>
              <span className="text-2xl">{invoice.client.name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
