import React, { useMemo } from "react";
import { IoMdArrowUp, IoMdRemove } from "react-icons/io";
import { RD } from "shared/base-prelude";
import * as stream from "stream-chat";
import {
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelPreviewUIComponentProps,
  Chat,
  DefaultStreamChatGenerics,
  MessageInput,
  MessageList,
  Thread,
  useTranslationContext,
  Window,
} from "stream-chat-react";
import { match } from "ts-pattern";
import { useRdConnectClient } from "./chat.hooks";

interface Props {
  myProfile: { id: string; name: string };
  onMinimizeClick: () => void;
  isMinimized: boolean;
}

type ChannelViewState =
  | { _tag: "LIST" }
  | { _tag: "CHANNEL"; channel: stream.Channel };

export const CommunityTabMessenger: React.FC<Props> = ({
  myProfile,
  onMinimizeClick,
  isMinimized,
}) => {
  const [viewState, setViewState] = React.useState<ChannelViewState>({
    _tag: "LIST",
  });
  const rdConnectClient = useRdConnectClient({ myProfile });

  if (!RD.isSuccess(rdConnectClient)) {
    return <div>Loading...</div>;
  }

  const onChannelListItemClick = (channel: stream.Channel) => {
    setViewState({ _tag: "CHANNEL", channel });
  };

  return (
    <div className="flex flex-col ">
      <div className="flex justify-between p-4">
        <button
          onClick={() => {
            setViewState({ _tag: "LIST" });
          }}
        >
          {viewState._tag === "CHANNEL" ? `<-` : ""}
        </button>
        <button onClick={() => onMinimizeClick()}>
          {isMinimized ? <IoMdArrowUp size={24} /> : <IoMdRemove size={24} />}
        </button>
      </div>
      <Chat client={rdConnectClient.value.cli}>
        {match(viewState)
          .with({ _tag: "LIST" }, () => (
            <ChannelList
              filters={{ members: { $in: [myProfile.id] } }}
              Preview={(props) => (
                <CustomChannelPreview
                  {...props}
                  onChannelListItemClick={onChannelListItemClick}
                />
              )}
            />
          ))
          .with({ _tag: "CHANNEL" }, (res) => (
            <Channel channel={res.channel}>
              <Window>
                <ChannelHeader />
                <MessageList />
                <MessageInput />
              </Window>
              <Thread />
            </Channel>
          ))
          .exhaustive()}
      </Chat>
    </div>
  );
};

const CustomChannelPreview: React.FC<
  ChannelPreviewUIComponentProps<DefaultStreamChatGenerics> & {
    onChannelListItemClick: (channel: stream.Channel) => void;
  }
> = (props) => {
  const {
    channel,
    activeChannel,
    displayImage,
    displayTitle,
    latestMessage,
    setActiveChannel,
    onChannelListItemClick,
  } = props;
  const latestMessageAt = channel.state.last_message_at;
  const isSelected = channel.id === activeChannel?.id;
  const { userLanguage } = useTranslationContext();

  const timestamp = useMemo(() => {
    if (!latestMessageAt) {
      return "";
    }
    const formatter = new Intl.DateTimeFormat(userLanguage, {
      timeStyle: "short",
    });
    return formatter.format(latestMessageAt);
  }, [latestMessageAt, userLanguage]);

  const handleClick = () => {
    console.log("HANDLING CLICK! ", channel);
    setActiveChannel?.(channel);
    onChannelListItemClick(channel);
  };

  return (
    <button
      className={`channel-preview ${
        isSelected ? "channel-preview_selected" : ""
      } border w-full p-4 cursor-pointer`}
      // disabled={isSelected}
      onClick={handleClick}
    >
      <img className="channel-preview__avatar" src={displayImage} alt="" />
      <div className="channel-preview__main">
        <div className="channel-preview__header">
          {displayTitle}
          <time
            dateTime={latestMessageAt?.toISOString()}
            className="channel-preview__timestamp"
          >
            {timestamp}
          </time>
        </div>
        <div className="channel-preview__message">{latestMessage}</div>
      </div>
    </button>
  );
};
