import {
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { pipe } from "fp-ts/lib/function";
import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { O, RD } from "shared/base-prelude";
import { useFirebaseJs } from "shared/firebase";
import { BE } from "../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../loading";
import {
  GroupSessionState,
  GroupSessionStateContext,
  useGroupSessionId,
  useGroupSessionState,
} from "./group-session.state";

export const HpGroupSessionLayout: React.FC<{}> = () => {
  const groupSessionId = useGroupSessionId();
  const rdVideoSetup = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).u.groupSession.getGroupSession.query({
        groupSessionId,
      })
    ),
    [groupSessionId]
  );

  return pipe(
    rdVideoSetup,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (res) => <LoadedView {...res} groupSessionId={groupSessionId} />
    )
  );
};

type GetGroupSessionResp = UApiOutput["u"]["groupSession"]["getGroupSession"];

const LoadedView: React.FC<GetGroupSessionResp & { groupSessionId: string }> = (
  props
) => {
  const { apiKey, token, streamUser, callId, callType } = props;
  const [hasJoined, setHasJoined] = useState(false);
  const groupSessionId = useGroupSessionId();
  const client = useMemo(
    () => new StreamVideoClient({ apiKey, user: streamUser, token }),
    []
  );
  const firebaseJs = useFirebaseJs();
  const call = useMemo(() => client.call(callType, callId), []);
  const stateMgr = useMemo(
    () => new GroupSessionState(props, firebaseJs),
    [props]
  );

  useEffect(() => {
    call.join({ create: true }).then(() => {
      setHasJoined(true);
    });

    // setInterval(() => {
    //   console.log("PINGING!");

    //   BE.fetchEndpointTE((api) =>
    //     api.u.groupSession.registerGroupSessionPing.mutate({
    //       groupSessionId,
    //     })
    //   )().then((er) => {
    //     console.log("RESULT OF PINGING! ", er);
    //   });
    // }, 30 * 1000);
  }, [groupSessionId]);

  if (!hasJoined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <StreamVideo client={client}>
      <StreamCall call={call}>
        <GroupSessionStateContext.Provider value={stateMgr}>
          <Content />
        </GroupSessionStateContext.Provider>
      </StreamCall>
    </StreamVideo>
  );
};

const Content: React.FC = () => {
  const gsmgr = useGroupSessionState();
  const { useScreenShareState } = useCallStateHooks();
  const { status: screenshareStatus } = useScreenShareState();

  useEffect(() => {
    gsmgr.screenshareState$.next(screenshareStatus);
  }, [screenshareStatus]);

  return <Outlet />;
};
