import React from "react";

// Define TypeScript types for the props
type Column = {
  key: string;
  header: string;
  showArrow: boolean;
};

const TableHeader: React.FC<{ columns: Column[] }> = ({ columns }) => {
  return (
    <thead className="bg-vid-black-100">
      <tr>
        {columns.map((col) => (
          <th
            key={col.header}
            scope="col"
            className="px-6 py-3 text-left text-sm font-medium text-black font-sans"
          >
            <div className="flex items-center hover:cursor-pointer">
              <p className="mr-4">{col.header}</p>
              {col.showArrow && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.6 7.45837L11.1666 12.8917C10.525 13.5334 9.47496 13.5334 8.8333 12.8917L3.39996 7.45837"
                    stroke="#161616"
                    strokeWidth="1.2"
                    strokeMiterlimit="10"
                    stroke-linecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

type GenericTableProps = {
  columns: Column[];
  data: { [key: string]: React.ReactNode }[];
};

export const GenericTable: React.FC<GenericTableProps> = ({
  columns,
  data,
}) => {
  return (
    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <TableHeader columns={columns} />
        <tbody
          className="bg-white divide-y divide-gray-200"
          style={{ maxHeight: "100px", overflowY: "auto" }}
        >
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column) => (
                <td
                  key={column.key}
                  className="px-6 py-4 whitespace-nowrap text-sm font-medium font-sans text-black"
                >
                  {row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
