import { pipe } from "fp-ts/lib/function";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { E, RD } from "shared/base-prelude";
import { BE } from "../../../../../backend";

export const RegisterNewClientForm: React.FC = () => {
  const { register, handleSubmit } = useForm<{ email: string }>();
  const [submitResult, setSubmitResult] = useState<
    RD.RemoteData<unknown, unknown>
  >(RD.initial);

  return (
    <div className="w-[400px] py-16 px-8 flex flex-col mt-8 gap-8">
      <form
        className="flex flex-col gap-8"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          setSubmitResult(RD.pending);
          BE.authedTE((tkn) =>
            BE.Api(tkn).hp.requestClient.mutate({ clientEmail: data.email })
          )().then((er) => {
            console.log(er);
            setSubmitResult(
              pipe(
                RD.fromEither(er),
                RD.mapLeft((f) => f.error.message)
              )
            );

            if (E.isRight(er)) {
              close();
              window.location.reload();
            }
          });
        })}
      >
        <h5>
          {`Enter your client's email address. We'll send them an email with a link to register.`}
        </h5>
        <input
          type="email"
          placeholder="email"
          className="text-input"
          autoCapitalize="off"
          autoCorrect="off"
          {...register("email", { required: true })}
        />
        <button disabled={RD.isPending(submitResult)} className="btn-light">
          Add client
        </button>
      </form>
      {pipe(
        submitResult,
        RD.fold(
          () => <></>,
          () => <div>Submitting...</div>,
          (e: unknown) => <div>{JSON.stringify(e)}</div>,
          () => <div>Successfly added client!</div>
        )
      )}
    </div>
  );
};
