import { UApiOutput } from "shared/backend-api/trpc-cli";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import { useOutletContext } from "react-router-dom";
import "stream-chat-react/dist/css/v2/index.css";
import { BE } from "../../../../../../../../backend";
import { PrivateChatFC } from "../../../../../../../../components/chat/private-chat";
import { useHpState } from "../../../../../../hp.state";

const setupChatTE = (p: { otherUserId: string }) =>
  BE.authedTE((tkn) =>
    BE.Api(tkn).hp.communities.startChatChannelWithMember.mutate({
      memberUserId: p.otherUserId,
    })
  );

type MemberGlobalProfileResp =
  UApiOutput["hp"]["communities"]["getMemberGlobalProfile"];

export const GlobalMemberChatTab: React.FC = () => {
  const dashboardState = useHpState();
  const myProfile = useObservableEagerState(dashboardState.myProfile$);
  const otherUser = useOutletContext<MemberGlobalProfileResp["member"]>();

  return (
    <div className="flex-1 flex flex-col">
      <PrivateChatFC
        setupChatTE={setupChatTE}
        myProfile={{ id: myProfile.id, name: myProfile.name! }}
        otherUserId={otherUser.id}
      />
    </div>
  );
};
