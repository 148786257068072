import { Effect } from "effect";
import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { O, RD } from "shared/base-prelude";
import { match } from "ts-pattern";
import { BE } from "../../../../../../backend";
import { Avatar } from "../../../../../../components/avatar.tc";
import { PrimaryButton } from "../../../../../../components/primitives/button";
import { FullContainerLoadingSpinner } from "../../../../../../loading";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import { useHpState } from "../../../../hp.state";
import { CommunityPost } from "../components/community-post.fc";
import {
  MemberCard,
  MyProfileCard,
  TabView,
  TopBanner,
} from "../components/community.components";
import {
  CommunityPageTab,
  CommunitySam,
  CommunitySamContext,
  useCommunitySam,
} from "./community.state";

type AllMembersList = UApiOutput["hp"]["community"]["listAllMembers"];

export const HpDashboardCommunityPageLayout: React.FC = () => {
  const communitySlug = useParams().community!;
  const rdCommunity = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.community.getCommunity.query({ communitySlug })
    )
  );

  return pipe(
    rdCommunity,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (community) => {
        return (
          <CommunitySamContext.Provider value={new CommunitySam(community)}>
            <HpDashboardCommunityTabLayout />
          </CommunitySamContext.Provider>
        );
      }
    )
  );
};

const HpDashboardCommunityTabLayout: React.FC = () => {
  const sam = useCommunitySam();
  return (
    <div className="min-h-0 flex-1 p-[46px] flex flex-col gap-8 max-w-[1180px] relative overflow-y-auto">
      <TopBanner
        circleImageUrl={null}
        title={sam.community.name}
        nextToTitleFc={
          <Link
            to={
              HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.MEMBERS
                .path
            }
            className="flex gap-2 p-1 my-auto text-sm leading-4 bg-slate-50 rounded-full text-zinc-700 cursor-pointer"
          >
            {sam.community.recentMembers.slice(0, 3).map((m) => {
              return (
                <Avatar key={m.id} mbProfilePhoto={m.profilePhoto} size={40} />
              );
            })}
            <div className="grow my-auto">{`${sam.community.recentMembers.length} Members`}</div>
          </Link>
        }
        rightView={
          <button
            className="justify-center self-end px-10 py-4 lg:mt-16 text-base font-medium leading-5 text-center text-violet-700 whitespace-nowrap bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Purple,#690DFF)] max-md:px-5"
            onClick={() => {
              BE.authedTE((tkn) =>
                BE.Api(tkn).hp.community.joinCommunity.mutate({
                  communitySlug: sam.community.slug,
                  isJoining: !sam.community.amIJoined,
                })
              )().then((er) => {
                console.log("JOIN COMMUNITY RESULT: ", er);
                window.location.reload();
              });
            }}
          >
            {sam.community.amIJoined ? "Joined" : "Join"}
          </button>
        }
      />
      <Tabs />
      <div className="flex gap-8 self-stretch">
        <div className="flex-1 flex flex-col max-w-full">
          {sam.community.amIJoined ? (
            <Outlet />
          ) : (
            <div className="flex-1 flex flex-col py-8 items-center">
              Join to view the discussion
            </div>
          )}
        </div>
        <MyProfile />
      </div>
    </div>
  );
};

const Tab: React.FC<{ tab: CommunityPageTab; isCurrent?: boolean }> = ({
  tab,
}) => {
  const sam = useCommunitySam();
  const { pathname } = useLocation();
  const isCurrent = React.useMemo(
    () => pathname.toLowerCase().includes(tab.toLowerCase()),
    [pathname, tab]
  );

  return (
    <TabView
      title={tab}
      linkTo={match(tab)
        .with("Discussion", () =>
          HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
            { community: sam.community.slug }
          )
        )
        .with("Learning", () =>
          HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.LEARNING.buildPath(
            { community: sam.community.slug }
          )
        )
        .with("Members", () =>
          HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.MEMBERS.buildPath(
            { community: sam.community.slug }
          )
        )
        .with("Events", () =>
          HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL.buildPath(
            { community: sam.community.slug }
          )
        )
        .exhaustive()}
      isCurrent={isCurrent}
    />
  );
};

const Tabs: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-4">
      <Tab tab="Discussion" />
      <Tab tab="Events" />
      <Tab tab="Members" />
      <Tab tab="Learning" />
    </div>
  );
};

export const CommunityLearningTab: React.FC = () => {
  return <div>Learning!</div>;
};

export const CommunityDiscussionFeed: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col gap-8 overflow-y-auto overscroll-x-none">
      <WriteAPostSection />
      <PostsSection />
    </div>
  );
};

export const CommunityMembersTab: React.FC = () => {
  const sam = useCommunitySam();
  const rdAllMembers = useObservableEagerState(sam.rdAllMembers$);

  return pipe(
    rdAllMembers,
    RD.fold(
      () => <></>,
      () => <FullContainerLoadingSpinner />,
      (e) => <div className="text-red-400">{JSON.stringify(e)}</div>,
      (members) => <AllMembersTabContent members={members} />
    )
  );
};

const AllMembersTabContent: React.FC<{ members: AllMembersList }> = ({
  members,
}) => {
  const InviteSomeoneSection: React.FC = () => {
    const sam = useCommunitySam();
    const [email, setEmail] = React.useState("");
    return (
      <div className="flex flex-col border-2">
        <input
          type="email"
          className="border-2"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PrimaryButton
          title="Invite someone"
          onClick={() => {
            Effect.match(
              BE.authedEff((tkn) =>
                BE.Api(tkn).hp.community.sendEmailInvite.mutate({
                  communitySlug: sam.community.slug,
                  email,
                })
              ),
              {
                onFailure: (e) => {
                  console.log("INVITE SOMEONE FAILURE: ", e);
                },
                onSuccess: (r) => {
                  console.log("INVITE SOMEONE SUCCESS: ", r);
                  alert("Invite sent!" + JSON.stringify(r));
                },
              }
            ).pipe(Effect.runSync);

            // nav(HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.INVITE_SOMEONE.buildPath({
            //   community: sam.community.slug
            // }))
          }}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-4 flex-wrap">
        {members.map((m) => {
          return (
            <MemberCard
              userId={m.id}
              name={m.name!}
              profilePhoto={m.profilePhoto}
              isAvailable={m.is_available_for_practice}
              modalities={[]}
            />
          );
        })}
      </div>
      <InviteSomeoneSection />
    </div>
  );
};

const MyProfile: React.FC = () => {
  const hpState = useHpState();
  const me = useObservableEagerState(hpState.myProfile$);
  const sam = useCommunitySam();

  React.useEffect(() => {
    hpState.myProfile$.subscribe((mp) => {
      console.log("MY PROFILE ON MY PROFILE CARD! ", mp);
    });
  }, []);

  return (
    <MyProfileCard
      name={me.name!}
      profilePhoto={me.profilePhoto}
      isAvailable={me.isAvailableForPractice}
      bio={me.bio}
      modalities={me.modalities}
      editBioLink={HP_ROUTES.MY.DASHBOARD.SETTINGS.path}
      communitySlug={sam.community.slug}
    />
  );
};

const WriteAPostSection: React.FC = () => {
  const dashboardState = useHpState();
  const sam = useCommunitySam();
  const myProfile = useObservableEagerState(dashboardState.myProfile$);

  return (
    <div
      className="flex gap-4 justify-between px-5 py-4 bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)] max-md:flex-wrap max-md:pl-5 cursor-pointer"
      onClick={() => {
        dashboardState.openRightNav({
          _tag: "CREATE_COMMUNITY_POST",
          posterId: myProfile.id,
          communitySlug: sam.community.slug,
        });
      }}
    >
      <div className="flex gap-3.5 justify-between w-full">
        <div className="self-stretch flex flex-col justify-center">
          <Avatar mbProfilePhoto={myProfile.profilePhoto} size={40} />
        </div>
        <div className="grow justify-center items-start py-5 pr-16 pl-7 text-base font-medium leading-5 text-start whitespace-nowrap bg-slate-50 rounded-[500px] text-neutral-400 max-md:px-5 max-md:max-w-full">
          Write a post
        </div>
      </div>
      <div className="flex gap-0 pr-3.5 my-auto text-sm font-medium leading-4 text-center text-violet-800 whitespace-nowrap">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/66b4435ae7ea40d1c30cb8991b7be7ea34172d0a6d6874a790170499abf47bbc?"
          className="my-auto w-6 aspect-square"
        />
        <div className="grow justify-center px-1.5 py-3 bg-white rounded-xl">
          Add photo/video{" "}
        </div>
      </div>
    </div>
  );
};

const PostsSection: React.FC = () => {
  const sam = useCommunitySam();
  const rdRecentPosts = useObservableEagerState(sam.rdRecentPosts$);

  return (
    <div className="flex flex-col gap-8">
      {pipe(
        rdRecentPosts,
        RD.toOption,
        O.fold(
          () => <FullContainerLoadingSpinner />,
          ({ posts }) => (
            <div className="flex flex-col gap-8">
              {posts.map((post) => (
                <CommunityPost
                  key={post.id}
                  post={post}
                  communitySlug={sam.community.slug}
                  onLike={(isLiked) => {
                    BE.authedTE((tkn) =>
                      BE.Api(tkn).hp.community.toggleLikePost.mutate({
                        communitySlug: sam.community.slug,
                        postId: post.id,
                        isLiked,
                      })
                    )().then((er) => {
                      console.log("LIKE POST RESULT: ", er);
                    });
                  }}
                />
              ))}
            </div>
          )
        )
      )}
    </div>
  );
};
