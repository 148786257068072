import { Items } from "./dashboard-card";

export const DashboardCardItem = ({ img, title, subtitle, link }: Items) => {
  return (
    <a href={link}>
      <div className="flex justify-between items-center my-4">
        <div className="flex items-center">
          <img
            src={img}
            className="w-14 bg-white h-14 rounded-full object-cover mr-4"
          />
          <div className="flex flex-col">
            <p className="text-sm">{title}</p>
            <p className="text-sm text-vid-gray">{subtitle}</p>
          </div>
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
            stroke="#1D1626"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </a>
  );
};
