import { useObservableEagerState } from "observable-hooks";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { BE } from "../../../../../../backend";
import { PrivateChatFC } from "../../../../../../components/chat/private-chat";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import { useHpState } from "../../../../hp.state";

export const HpDashboardClientMessagesPage: React.FC = () => {
  const cpId = useTypedParams(
    HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.CHAT
  ).clientId;
  const hpState = useHpState();
  const myProfile = useObservableEagerState(hpState.myProfile$);

  return (
    <div className="flex-1 flex flex-col p-8">
      <PrivateChatFC
        myProfile={{
          id: myProfile.id,
          name: myProfile.name!,
        }}
        otherUserId={cpId}
        setupChatTE={(p: { otherUserId: string }) =>
          BE.authedTE((tkn) =>
            BE.Api(tkn).hp.clients.client.startPrivateChatWithCp.mutate({
              clientId: p.otherUserId,
            })
          )
        }
      />
    </div>
  );
};
