import * as RD from "@devexperts/remote-data-ts";
import { Effect, pipe as epipe } from "effect";
import * as Arr from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as Rearr from "fp-ts/lib/ReadonlyArray";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/pipeable";
import * as Rx from "rxjs";

const erp = Effect.runPromise;

function runEff<V, E>(p: {
  eff: Effect.Effect<V, E, never>;
  onSuccess: (v: V) => void;
  onFailure: (e: E) => void;
}) {
  const vf = Effect.match(p.eff, {
    onFailure: p.onFailure,
    onSuccess: p.onSuccess,
  });

  Effect.runPromise(vf).catch();
}

export function taskEitherToEff<E, V>(
  te: TE.TaskEither<E, V>
): Effect.Effect<V, E, never> {
  return epipe(
    Effect.promise(te),
    Effect.flatMap((eres) =>
      E.isLeft(eres) ? Effect.fail(eres.left) : Effect.succeed(eres.right)
    )
  );
}

export async function runTaskEither<E, V>(te: TE.TaskEither<E, V>): Promise<V> {
  const er = await te();

  if (E.isLeft(er)) {
    throw er.left;
  }

  return er.right;
}

export { Arr, E, O, RD, Rearr, Rx, T, TE, epipe, erp, pipe, runEff };
