import { useTaskEither } from "shared/backend-api/api.mgr";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { BE } from "../../../../../backend";
import { InversePrimaryButton } from "../../../../../components/primitives/button";
import { FullContainerLoadingSpinner } from "../../../../../loading";
import { RD } from "../../../../../prelude";
import { useHpState } from "../../../hp.state";
import { SimpleClient } from "../right-drawer-action/right-nav";
import {
  ClientsListContainer,
  ClientsListRowItemContainer,
} from "./clients-page.components";
import { HP_ROUTES } from "../../../../../routes/hp-routes";

export const HpMyDashboardClientsPage: React.FC = () => {
  const dashboardState = useHpState();

  const rdClients = useTaskEither(
    BE.authedTE((tkn) => BE.Api(tkn).hp.clients.getClients.query())
  );

  return pipe(
    rdClients,
    RD.fold(
      () => <></>,
      () => <FullContainerLoadingSpinner />,
      (e: unknown) => <div>{JSON.stringify(e)}</div>,
      (r) => {
        if (
          r.pastSessions.length === 0 &&
          r.pendingClients.length === 0 &&
          r.recentlyApproved.length === 0 &&
          r.clients.length === 0
        ) {
          return <EmptyState />;
        }

        return (
          <div className="p-8 relative">
            <div className="flex justify-between items-center pb-8">
              <h3 className="font-outfit font-[500] text-2xl lg:text-4xl text-vid-black-900">
                Clients
              </h3>
              <div>
                <button
                  className="lg:p-buttons rounded-buttons text-vid-purple lg:border border-vid-purple "
                  onClick={() => {
                    dashboardState.openRightNav({
                      _tag: "REGISTER_NEW_CLIENT",
                    });
                  }}
                >
                  Invite a client
                </button>
              </div>
            </div>

            <div className="border border-vid-black-200 rounded-tr-[12px] rounded-tl-[12px]">
              {r.upcomingApptClientsWithProfPhotos.length > 0 && (
                <ClientsListContainer
                  title={"Upcoming"}
                  rows={r.upcomingApptClientsWithProfPhotos.map((c) => () => (
                    <ClientsListRowItemContainer
                      linkTo={`/hp/my/dashboard/clients/${c.client_user_id}`}
                      name={c.name!}
                      profilePhotoUrl={c.profilePhoto}
                      title="Upcoming"
                      appt={{ date: c.start_time, id: c.id }}
                      // sessionType="EMDR Therapy"
                    >
                      <div className="flex flex-col lg:flex-row items-center">
                        <StartSessionButton
                          client={{
                            id: c.client_user_id,
                            name: c.name!,
                          }}
                        />
                      </div>
                    </ClientsListRowItemContainer>
                  ))}
                />
              )}
              {r.clients.length > 0 && (
                <ClientsListContainer
                  title={"My Clients"}
                  rows={r.clients.map((c) => () => (
                    <ClientsListRowItemContainer
                      linkTo={HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.INFO.buildPath(
                        {
                          clientId: c.id,
                        }
                      )}
                      name={c.name!}
                      profilePhotoUrl={c.profilePhoto}
                      title="Upcoming"
                      // sessionType="EMDR Therapy"
                    >
                      <div className="lg:flex-1 flex items-center justify-center mt-4 lg:mt-0 w-full lg:w-fit">
                        <StartSessionButton
                          client={{
                            id: c.id,
                            name: c.name!,
                          }}
                        />
                      </div>
                    </ClientsListRowItemContainer>
                  ))}
                />
              )}

              {r.pendingClients.length > 0 && (
                <ClientsListContainer
                  title="Pending"
                  rows={r.pendingClients.map((c) => () => (
                    <ClientsListRowItemContainer
                      linkTo={`/hp/my/dashboard/clients/${c.id}`}
                      name={c.client_email}
                      profilePhotoUrl={null}
                      title="Pending"
                    >
                      <div className="flex flex-col lg:flex-row items-center">
                        <div className="flex-1"></div>
                        <InversePrimaryButton
                          title="Resend invitation"
                          onClick={() => {
                            BE.authedTE((tkn) =>
                              BE.Api(tkn).hp.resendInviteEmail.mutate({
                                clientEmail: c.client_email,
                              })
                            )().then((er) => {
                              console.log("RESULT OF RESENDING INVITE! ", er);
                            });
                            dashboardState.showBottomToastSignal$.next({
                              msg: "Successfuly resent invitation",
                            });
                          }}
                        />
                      </div>
                    </ClientsListRowItemContainer>
                  ))}
                />
              )}
              {r.pastSessions.length > 0 && (
                <ClientsListContainer
                  title={"Past"}
                  rows={r.pastSessions.map((c) => () => (
                    <ClientsListRowItemContainer
                      linkTo={`/hp/my/sessions/${c.id}/review`}
                      name={c.name!}
                      profilePhotoUrl={c.profilePhoto}
                      title=""
                    >
                      <div className="lg:flex-1 flex lg:items-center">
                        <div className="flex-1"></div>
                      </div>
                    </ClientsListRowItemContainer>
                  ))}
                />
              )}
            </div>
          </div>
        );
      }
    )
  );
};

const StartSessionButton: React.FC<{
  client: SimpleClient;
}> = ({ client }) => {
  const dashboardModel = useHpState();
  return (
    <button
      onClick={() => {
        dashboardModel.openRightNav({
          _tag: "START_CLIENT_SESSION",
          client,
        });
      }}
      className={`lg:min-w-[200px] w-full
      bg-white text-vid-purple border border-vid-purple rounded-buttons px-2 py-1 lg:p-buttons
      hover:bg-vid-purple hover:text-white
      `}
    >
      Start session
    </button>
  );
};

// const KebabMenuButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
//   return (
//     <button onClick={onClick} className="h-6 w-6 flex flex-col gap-[1px]">
//       <div className="h-1 w-1 rounded-full bg-gray-500"></div>
//       <div className="h-1 w-1 rounded-full bg-gray-500"></div>
//       <div className="h-1 w-1 rounded-full bg-gray-500"></div>
//     </button>
//   );
// };

const EmptyState: React.FC = () => {
  const dashboardState = useHpState();
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <div className="flex gap-8">
        <AddAClientCard
          onClick={() => {
            dashboardState.openRightNav({ _tag: "REGISTER_NEW_CLIENT" });
          }}
        />
      </div>
    </div>
  );
};

const AddAClientCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col justify-center items-center cursor-pointer p-8"
    >
      <h4 className="font-outfit font-medium text-default-purple text-4xl leading-9 mb-4">
        Invite your first client
      </h4>
      <p className="font-sans font-light text-md mb-4">
        We'll send an invitation and let you know when its accepted.
      </p>
      <div className="bg-vid-purple p-buttons px-24 rounded-buttons text-white flex justify-center items-center">
        {`Invite`}
      </div>
    </div>
  );
};
