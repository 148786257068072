import { Discover } from "../../../../components/dashboard/Discover";
import { DashboardCard } from "../../../../components/dashboard/dashboard-card";
import { GetVidalifyProCard } from "../../../../components/dashboard/vidalify-pro-card";
import { WelcomeChecklist } from "../../../../components/dashboard/welcome-checklist";
import discover from "./mocks/discover";
import sessions from "./mocks/sessions";
import training from "./mocks/training";
import welcome from "./mocks/welcome";

const HpDashboardHomePage = () => {
  return (
    <div className="max-h-screen w-full">
      <div className="flex-1 flex flex-col ">
        <MainContent />
      </div>
    </div>
  );
};

const MainContent: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col gap-6 p-9 justify-between">
      <MainContentTopRow />
      <MainContentBottomRow />
    </div>
  );
};

const MainContentTopRow: React.FC = () => {
  return (
    <div className="flex-1 items-stretch flex gap-6">
      <div className="flex-1 flex flex-col min-h-[300px]">
        <Discover discoverList={discover} />
      </div>
      <div className="flex-1 flex flex-col border rounded-lg">
        <WelcomeChecklist welcomeList={welcome} />
      </div>
    </div>
  );
};

const MainContentBottomRow: React.FC = () => {
  return (
    <div className="flex-1 flex gap-6">
      <div className="flex-1 border rounded-lg max-h-[430px]">
        <DashboardCard
          title="Sessions"
          background="bg-transparent"
          list={sessions}
        />
      </div>
      <div className="flex-1 border rounded-lg max-h-[430px]">
        <DashboardCard
          title="Training"
          background="bg-vid-black-100"
          list={training}
        />
      </div>
      <div className="flex-1 max-h-[430px]">
        <GetVidalifyProCard />
      </div>
    </div>
  );
};

export default HpDashboardHomePage;
