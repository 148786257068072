import { useTaskEither } from "shared/backend-api/api.mgr";
import { Outlet } from "react-router-dom";
import { BE } from "../../../backend";
import React, { useEffect, useMemo } from "react";
import { RD } from "shared/base-prelude";
import { FullContainerLoadingSpinner } from "../../../loading";
import { pipe } from "fp-ts/lib/function";
import * as Sentry from "@sentry/react";
import { HpState, HpStateContext } from "../hp.state";
import { HpProfile } from "shared";

export const MyLayout: React.FC = () => {
  const rdMyProfile = useTaskEither(
    BE.authedTE((tkn) => BE.Api(tkn).hp.getMyProfile.query())
  );

  useEffect(() => {
    if (RD.isSuccess(rdMyProfile)) {
      console.log("MY PROFILE! ", rdMyProfile.value);
      const { id, email } = rdMyProfile.value;
      Sentry.setUser({ id, email });
    }
    if (RD.isFailure(rdMyProfile)) {
      console.log(rdMyProfile);
      window.location.href = "/";
    }
  }, [rdMyProfile]);

  return pipe(
    rdMyProfile,
    RD.fold(
      () => <></>,
      () => <FullContainerLoadingSpinner />,
      (e) => <div>{JSON.stringify(e)}</div>,
      (myProfile) => <MyLayoutContent myProfile={myProfile} />
    )
  );
};

const MyLayoutContent: React.FC<{ myProfile: HpProfile }> = ({ myProfile }) => {
  const state = useMemo(() => new HpState({ myProfile }), [myProfile]);
  return (
    <HpStateContext.Provider value={state}>
      <Outlet />
    </HpStateContext.Provider>
  );
};
