import { z } from "zod";

export const KnownMeditationVisualSchema = z.union([
  z.literal("Mandala"),
  z.literal("Flame"),
  z.literal("None"),
]);
export type KnownMeditationVisual = z.infer<typeof KnownMeditationVisualSchema>;

export const MeditationStateSchema = z.object({
  playState: z.union([
    z.literal("PLAYING"),
    z.literal("PAUSED"),
    z.literal("STOPPED"),
  ]),
  timerMinutesSetting: z.nullable(z.number()),
  countdownTimerSeconds: z.nullable(z.number()),
  visual: KnownMeditationVisualSchema,
});

export type MeditationState = z.infer<typeof MeditationStateSchema>;

export const defaultMeditationState: MeditationState = {
  playState: "STOPPED",
  timerMinutesSetting: null,
  countdownTimerSeconds: null,
  visual: "None",
};

export const BaseSessionStateSchema = z.object({
  hpRoom: z.nullable(z.union([z.literal("waiting"), z.literal("main")])),

  playingMediaFile: z.string().nullable(),

  meditationState: z.nullable(z.string()), // this will be decoded using MeditationStateSchema later
});

export type BaseSessionState = z.infer<typeof BaseSessionStateSchema>;

const defaultBaseSessionState: BaseSessionState = {
  hpRoom: null,
  playingMediaFile: null,
  meditationState: null,
};

const PrivateSessionStateSpecificSchema = z.object({
  hpIsScreenBlurred: z.boolean(),

  cpApprovedToJoinAt: z.nullable(z.string()),

  cpIsScreenBlurred: z.boolean(),

  sessionIsEnded: z.boolean(),

  emdrShowBouncingBallAt: z.nullable(z.string()),
  emdrBallFrequency: z.number(),

  isChatting: z.boolean(),

  isShowingNextScheduleReminder: z.boolean(),
  showedNextScheduleReminderAt: z.nullable(z.string()),

  isRecording: z.boolean(),

  isRecordingSectionId: z.string().nullable(),
});

export type PrivateSessionState = z.infer<
  typeof PrivateSessionStateSpecificSchema
>;

export const SessionStateSchema = BaseSessionStateSchema.extend(
  PrivateSessionStateSpecificSchema.shape
);

export type SessionState = z.infer<typeof SessionStateSchema>;

export const defaultNormalSessionState: SessionState = {
  ...defaultBaseSessionState,
  hpIsScreenBlurred: false,

  cpApprovedToJoinAt: null,

  cpIsScreenBlurred: false,

  sessionIsEnded: false,

  emdrShowBouncingBallAt: null,
  emdrBallFrequency: 1,

  isChatting: false,

  isShowingNextScheduleReminder: false,
  showedNextScheduleReminderAt: null,

  isRecording: false,

  isRecordingSectionId: null,
};

export const GroupSessionStateSpecificSchema = z.object({});

export type GroupSessionStateSpecific = z.infer<
  typeof GroupSessionStateSpecificSchema
>;

export const GroupSessionStateSchema = z.intersection(
  BaseSessionStateSchema,
  GroupSessionStateSpecificSchema
);

export type GroupSessionState = z.infer<typeof GroupSessionStateSchema>;

export const defaultGroupSessionState: GroupSessionState = {
  ...defaultBaseSessionState,
};
