import React from "react";
import { E, O } from "shared/base-prelude";
import { BE } from "../../../backend";
import { Rx } from "../../../prelude";

type ShowBottomToast = {
  msg: string;
  reload?: boolean;
  onUndo?: () => boolean;
};

type CpDashboardRightNav = "APPROVE";

type CpProfile = { id: string; name: string; profilePhoto: string | null };

export class CpDashboardState {
  myUserId: string;
  rightNav$ = new Rx.BehaviorSubject(O.none as O.Option<CpDashboardRightNav>);
  showBottomToastSignal$ = new Rx.Subject<ShowBottomToast>();
  myProfile$: Rx.BehaviorSubject<CpProfile>;

  constructor(p: { myProfile: CpProfile }) {
    this.myUserId = p.myProfile.id;
    this.myProfile$ = new Rx.BehaviorSubject(p.myProfile);
  }

  refreshMyProfile = () => {
    BE.authedTE((tkn) => BE.Api(tkn).cp.getMyProfile.query())().then((er) => {
      if (E.isRight(er)) {
        this.myProfile$.next(er.right);
      }
    });
  };

  showBottomToast = (toast: ShowBottomToast) => {
    this.showBottomToastSignal$.next(toast);
  };

  closeRightNav = () => {
    this.rightNav$.next(O.none);
  };

  openRightNav = (nav: CpDashboardRightNav) => {
    if (O.isSome(this.rightNav$.value)) {
      this.closeRightNav();
      setTimeout(() => {
        this.rightNav$.next(O.some(nav));
      }, 500);
    } else {
      // make the div with id dashboard-content scroll to the top of the window first
      const dashboardContent = document.getElementById("dashboard-content");
      if (dashboardContent) {
        dashboardContent.scrollTo(0, 0);
      }
      this.rightNav$.next(O.some(nav));
    }
  };
}

export const CpDashboardStateContext =
  React.createContext<CpDashboardState | null>(null);

export function useCpDashboardState() {
  return React.useContext(CpDashboardStateContext)!;
}
