import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

interface DiscoverItemCarousel {
  img: string;
  title: string;
  type: string;
  healer: string;
  healerImg: string;
  role: string;
}

interface DiscoverProps {
  discoverList: DiscoverItemCarousel[];
}

export const Discover = ({ discoverList }: DiscoverProps) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Slider {...settings} className="w-[750px] h-[350px]">
      {discoverList.map((item, i) => (
        <div key={i} className="relative">
          <div className="absolute inset-0">
            <img
              className="h-full w-full object-cover bg-center rounded-lg"
              src={item.img}
              width={1357}
              height={641}
              alt={item.title}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gradient-purple opacity-50 rounded-lg"></div>
          </div>
          <div className="relative p-6 h-[350px]">
            <div className="flex flex-col h-full justify-between">
              <h2 className="text-xl font-light text-white">Discover</h2>
              <div className="flex flex-col">
                <div className="bg-white py-1 px-3 rounded-full w-fit text-xs font-semibold mb-2">
                  {item.type}
                </div>
                <div
                  className="text-xl shadow-black drop-shadow-xl text-white mb-2"
                  style={{
                    textShadow: "0px 1px 5px rgba(0, 0, 0, 0.70)",
                  }}
                >
                  {item.title}
                </div>
                <div className="flex items-center bg-black/50 rounded-full w-fit py-1 px-1">
                  <span className="flex h-6 w-6 rounded-full mr-2">
                    <img
                      className="aspect-square h-full w-full"
                      alt="Ana Mendieta"
                      src={item.healerImg}
                    />
                  </span>
                  <div className="text-sm text-white mr-1">
                    <div className=" font-medium">
                      {item.healer} •{" "}
                      <span className=" text-gray-300">{item.role}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};
