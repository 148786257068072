import { zodResolver } from "@hookform/resolvers/zod";
import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { RD } from "shared/base-prelude";
import { z } from "zod";
import { BE, DefaultError } from "../../../../../backend";
import { CalendarMenuInput } from "../../../../../components/form/calendar.input";
import { PrivateChatFC } from "../../../../../components/chat/private-chat";
import { SetDurationSection } from "../../../../../components/session/session-form.components";
import { Rx } from "../../../../../prelude";
import { CP_ROUTES } from "../../../../../routes/cp-routes";
import { useCpDashboardState } from "../../cpdashboard.state";

export const CpHpDetailsPage = () => {
  const hpId = useTypedParams(CP_ROUTES.DASHBOARD.HPS.HP_ID.DETAILS).hpId;
  const dashboardState = useCpDashboardState();
  const myProfile = useObservableEagerState(dashboardState.myProfile$);

  return (
    <div className="flex-1 flex flex-col gap-8 p-8">
      <RequestAnAppointmentForm hpId={hpId} />
      <CreateAnAppointmentForm hpId={hpId} />
      <PrivateChatFC
        myProfile={myProfile}
        otherUserId={hpId}
        setupChatTE={() =>
          BE.authedTE((tkn) =>
            BE.Api(tkn).cp.startChatWithTherapist.query({ hpId: hpId })
          )
        }
      />
    </div>
  );
};

const RequestAnApptFormZSchema = z.object({
  date: z.string(),
  message: z.string().optional(),
});

type RequestAnApptFormSchema = z.infer<typeof RequestAnApptFormZSchema>;

const RequestAnAppointmentForm: React.FC<{ hpId: string }> = ({ hpId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestAnApptFormSchema>({
    resolver: zodResolver(RequestAnApptFormZSchema),
  });
  const [rdSubmitResult, setRdSubmitResult] = useState<
    RD.RemoteData<DefaultError, any>
  >(RD.initial);

  const onSubmit = (data: RequestAnApptFormSchema) => {
    console.log(data);
    // Here you would handle the form submission, e.g., sending data to an API

    BE.authedTE((tkn) =>
      BE.Api(tkn).cp.requestAnAppointment.mutate({
        hpId,
        date: data.date,
        message: data.message,
      })
    )().then((er) => {
      console.log("RESULT OF REQUEST AN APPT", er);
      setRdSubmitResult(RD.fromEither(er));
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 border-4 border-black p-8"
    >
      <h4 className="text-2xl font-bold">Request an appt</h4>
      <div>
        <label
          htmlFor="date"
          className="block text-sm font-medium text-gray-700"
        >
          Date
        </label>
        <input
          type="datetime-local"
          {...register("date")}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        {errors.date && (
          <span className="text-sm text-red-600">{errors.date.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="message"
          className="block text-sm font-medium text-gray-700"
        >
          Message
        </label>
        <textarea
          {...register("message")}
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        ></textarea>
        {errors.message && (
          <span className="text-sm text-red-600">{errors.message.message}</span>
        )}
      </div>
      <button
        type="submit"
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Submit
      </button>
      <div>
        {pipe(
          rdSubmitResult,
          RD.fold(
            () => <></>,
            () => <div>Loading...</div>,
            (e) => <div className="text-red-400">{e.error.message}</div>,
            (s) => <div>{s.message}</div>
          )
        )}
      </div>
    </form>
  );
};

class CreateAnApptFormState {
  startDate$ = new Rx.BehaviorSubject<Date | null>(null);
  durationInMinutes$ = new Rx.BehaviorSubject<number | null>(null);
  rdSubmitResult$ = new Rx.BehaviorSubject<RD.RemoteData<DefaultError, any>>(
    RD.initial
  );

  constructor(readonly hpId: string) {
    this.startDate$.subscribe((sd) => {
      console.log("SD! ", sd?.toISOString());
    });
  }

  setStartDate = (date: Date) => {
    this.startDate$.next(date);
  };

  setDurationInMinutes = (minutes: number | null) => {
    this.durationInMinutes$.next(minutes);
  };

  submit = () => {
    this.rdSubmitResult$.next(RD.pending);

    console.log("DATE! ", this.durationInMinutes$.value, this.startDate$.value);

    BE.authedTE((tkn) =>
      BE.Api(tkn).cp.createAnAppointment.mutate({
        hpId: this.hpId,
        date: this.startDate$.value?.toISOString() ?? "",
        durationInMinutes: this.durationInMinutes$.value,
      })
    )().then((er) => {
      this.rdSubmitResult$.next(RD.fromEither(er));
    });
  };
}

const CreateAnAppointmentForm: React.FC<{ hpId: string }> = ({ hpId }) => {
  const formState = useMemo(() => new CreateAnApptFormState(hpId), [hpId]);
  const rdSubmitResult = useObservableEagerState(formState.rdSubmitResult$);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        console.log("SUBMITTING! ");
        formState.submit();
      }}
      className="flex flex-col gap-4 border-4 border-black p-8"
    >
      <h4 className="text-2xl font-bold">Create an appt</h4>
      <div>
        <label
          htmlFor="date"
          className="block text-sm font-medium text-gray-700"
        >
          Date
        </label>
        <input
          type="datetime-local"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => {
            console.log("TARGET! ", e.target.value, new Date(e.target.value));
            formState.setStartDate(new Date(e.target.value));
          }}
        />
      </div>
      <CalendarMenuInput
        title="Date"
        onSelection={(dt) => {
          formState.setStartDate(dt);
        }}
      />
      <SetDurationSection
        durationInMinutes$={formState.durationInMinutes$}
        onChange={(duration) => {
          formState.setDurationInMinutes(duration);
        }}
      />
      <button
        type="submit"
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Submit
      </button>
      <div>
        {pipe(
          rdSubmitResult,
          RD.fold(
            () => <></>,
            () => <div>Loading...</div>,
            (e) => <div className="text-red-400">{e.error.message}</div>,
            (s) => <div>{s.message}</div>
          )
        )}
      </div>
    </form>
  );
};
