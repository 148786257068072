import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import React, { useEffect, useMemo } from "react";
import {
  ChronologicalSummary,
  FormattedTranscriptJson,
  NotesAndBookmarks,
  SessionGenericFullSummary,
} from "shared";
import { createContextAndHook } from "shared/util";
import { match } from "ts-pattern";
import { BE } from "../../../backend";
import { FullContainerLoadingSpinner } from "../../../loading";
import { RD, Rx } from "../../../prelude";
import { TranscriptTab } from "./transcript-tab";

type Invoice = {};

type Tab = "Transcription" | "Notes" | "Invoice";

class StateActionsMgr {
  sessionId: string;
  curTab$ = new Rx.BehaviorSubject<Tab>("Notes");
  rdMbTranscript$ = new Rx.BehaviorSubject<
    RD.RemoteData<any, FormattedTranscriptJson | null>
  >(RD.initial);
  rdNotes$ = new Rx.BehaviorSubject<RD.RemoteData<any, NotesAndBookmarks>>(
    RD.initial
  );
  rdChronSummary$ = new Rx.BehaviorSubject<
    RD.RemoteData<any, ChronologicalSummary | null>
  >(RD.initial);
  rdGenericFullSummary$ = new Rx.BehaviorSubject<
    RD.RemoteData<any, SessionGenericFullSummary | null>
  >(RD.initial);
  invoice: Invoice;

  constructor(p: { invoice: Invoice; sessionId: string }) {
    this.invoice = p.invoice;
    this.sessionId = p.sessionId;
  }

  async fetchAll() {
    return Promise.all([
      this.fetchMbTranscript(),
      this.fetchNotes(),
      this.fetchChronologicalSummary(),
      this.fetchGenericFullSummary(),
    ]);
  }

  fetchMbTranscript = () => {
    this.rdMbTranscript$.next(RD.pending);
    BE.fetchEndpointTE((Api) =>
      Api.u.session.review.getMbTranscript.query({ sessionId: this.sessionId })
    )().then((er) => {
      this.rdMbTranscript$.next(RD.fromEither(er));
    });
  };

  fetchNotes = () => {
    this.rdNotes$.next(RD.pending);
    BE.fetchEndpointTE((Api) =>
      Api.u.session.review.getNotesAndBookmarks.query({
        sessionId: this.sessionId,
      })
    )().then((er) => {
      this.rdNotes$.next(RD.fromEither(er));
    });
  };

  fetchChronologicalSummary = () => {
    this.rdChronSummary$.next(RD.pending);
    BE.fetchEndpointTE((Api) =>
      Api.u.session.review.getOrCreateChronologicalSummary.mutate({
        sessionId: this.sessionId,
        // forceRefresh: true,
      })
    )().then((er) => {
      this.rdChronSummary$.next(pipe(er, RD.fromEither));
    });
  };

  fetchGenericFullSummary = () => {
    this.rdGenericFullSummary$.next(RD.pending);
    BE.fetchEndpointTE((Api) =>
      Api.u.session.review.getSessionBreakdownSummary.query({
        sessionId: this.sessionId,
        // forceRefresh: true,
      })
    )().then((er) => {
      this.rdGenericFullSummary$.next(pipe(er, RD.fromEither));
    });
  };

  setCurTab = (tab: Tab) => {
    this.curTab$.next(tab);
  };
}

const [StateActionsContext, useStateActionsMgr] =
  createContextAndHook<StateActionsMgr>("StateActionsMgr");

export const ReviewPageContent: React.FC<{
  sessionType:
    | { _tag: "PRIVATE"; sessionId: string }
    | { _tag: "GROUP"; groupSessionId: string };
  hideTabs: Tab[];
}> = ({ sessionType, hideTabs }) => {
  return match(sessionType)
    .with({ _tag: "PRIVATE" }, ({ sessionId }) => (
      <ReviewFullPrivateSession sessionId={sessionId} hideTabs={hideTabs} />
    ))
    .with({ _tag: "GROUP" }, ({ groupSessionId }) => (
      <div>TODO: {groupSessionId}</div>
    ))
    .exhaustive();
};

export const ReviewFullPrivateSession: React.FC<{
  sessionId: string;
  hideTabs: Tab[];
}> = ({ sessionId, hideTabs }) => {
  const fakeInvoice = {};
  const sa = React.useMemo(
    () => new StateActionsMgr({ invoice: fakeInvoice, sessionId }),
    [fakeInvoice, sessionId]
  );

  useEffect(() => {
    sa.fetchAll();
  }, []);

  return (
    <StateActionsContext.Provider value={sa}>
      <div className="flex-1 flex flex-col items-center px-16 gap-4 py-4">
        <ReviewPageRadioTabs hideTabs={hideTabs} />
        <PageSection />
      </div>
    </StateActionsContext.Provider>
  );
};

const PageSection: React.FC = () => {
  const mgr = useStateActionsMgr();
  const curTab = useObservableEagerState(mgr.curTab$);

  return (
    <div>
      {match(curTab)
        .with("Transcription", () => (
          <TranscriptTab
            rdMbTranscript$={mgr.rdMbTranscript$}
            rdNotesAndBookmarks$={mgr.rdNotes$}
          />
        ))
        .with("Invoice", () => <InvoicePage />)
        .with("Notes", () => (
          <NotesPage
            rdChronologicalSummary$={mgr.rdChronSummary$}
            rdGenericFullSummary$={mgr.rdGenericFullSummary$}
          />
        ))
        .exhaustive()}
    </div>
  );
};

const InvoicePage: React.FC = () => {
  return <div>Invoice</div>;
};

const NotesPage: React.FC<{
  rdChronologicalSummary$: Rx.Observable<
    RD.RemoteData<any, ChronologicalSummary | null>
  >;
  rdGenericFullSummary$: Rx.Observable<
    RD.RemoteData<any, SessionGenericFullSummary | null>
  >;
}> = ({ rdChronologicalSummary$, rdGenericFullSummary$ }) => {
  const rdChronologicalSummary = useObservableState(
    rdChronologicalSummary$,
    RD.initial
  );
  const rdGenericFullSummary = useObservableState(
    rdGenericFullSummary$,
    RD.initial
  );
  return (
    <div className="flex flex-col">
      {pipe(
        rdChronologicalSummary,
        RD.fold(
          () => <></>,
          () => <FullContainerLoadingSpinner />,
          (e) => <div>{JSON.stringify(e)}</div>,
          (mbChronSummary) => {
            if (!mbChronSummary) {
              return <div>No summary</div>;
            }
            return (
              <div className="flex flex-col">
                {mbChronSummary.chronologicalSummary.map((s) => (
                  <div key={s.startingMinute} className="border p-4">
                    <h2>{`(${s.startingMinute}m)`}</h2>
                    <p>{s.summary}</p>
                  </div>
                ))}
                <div className="mt-8">
                  {pipe(
                    rdGenericFullSummary,
                    RD.fold(
                      () => <></>,
                      () => <FullContainerLoadingSpinner />,
                      (e) => <div>{JSON.stringify(e)}</div>,
                      (mbFullSummary) =>
                        mbFullSummary ? (
                          <FullSessionBreakdown opusSummary={mbFullSummary} />
                        ) : (
                          <></>
                        )
                    )
                  )}
                </div>
              </div>
            );
          }
        )
      )}
    </div>
  );
};

const ReviewPageRadioTabs: React.FC<{
  hideTabs: Tab[];
}> = ({ hideTabs }) => {
  return (
    <div className="w-[941px] h-[70px] px-9 py-4 bg-white rounded-[60px] border border-zinc-200 justify-between items-start inline-flex">
      {!hideTabs.includes("Transcription") && (
        <ReviewPageRadioTab tab="Transcription" />
      )}
      {!hideTabs.includes("Notes") && <ReviewPageRadioTab tab={"Notes"} />}
      {!hideTabs.includes("Invoice") && <ReviewPageRadioTab tab={"Invoice"} />}
    </div>
  );
};

const ReviewPageRadioTab: React.FC<{ tab: Tab }> = ({ tab }) => {
  const mgr = useStateActionsMgr();
  const curTab = useObservableEagerState(mgr.curTab$);
  const isSelected = useMemo(() => curTab === tab, [curTab, tab]);

  return (
    <div
      className="self-stretch justify-start items-center gap-4 flex cursor-pointer"
      onClick={() => {
        mgr.setCurTab(tab);
      }}
    >
      <div className="w-6 h-6 relative">
        {isSelected && (
          <div className="w-6 h-6 left-0 top-0 absolute bg-violet-700 rounded-full border-4 border-white" />
        )}
        <div className="w-6 h-6 left-0 top-0 absolute rounded-full border border-black" />
      </div>
      <div
        className={`${
          isSelected ? "text-violet-700" : "text-zinc-500"
        } text-2xl font-normal font-['Roboto Flex'] leading-[38.40px]`}
      >
        {tab}
      </div>
    </div>
  );
};

export const FullSessionBreakdown: React.FC<{
  opusSummary: SessionGenericFullSummary;
}> = ({ opusSummary }) => {
  return (
    <div className="grid grid-cols-2 gap-4 flex-1 overflow-auto">
      <div className="flex flex-col gap-4 items-center border-2 border-blue-400">
        <FullSessionBreakdownCard title="Techniques Used">
          <div className="flex flex-col">
            {opusSummary.techniquesUsed.map((t) => (
              <div
                key={t.modality + t.technique}
                className="flex gap-2 items-center"
              >
                <div className="font-bold">{t.modality}</div>
                <div>{t.technique}</div>
              </div>
            ))}
          </div>
        </FullSessionBreakdownCard>
        <FullSessionBreakdownCard title="Session Activities">
          <div className="flex flex-col gap-8">
            {opusSummary.sessionActivities.map((activity) => (
              <div key={activity.type} className="flex flex-col">
                <h4 className="font-bold">{activity.type}</h4>
                <p>{activity.instructions}</p>
              </div>
            ))}
          </div>
        </FullSessionBreakdownCard>
        <FullSessionBreakdownCard title="Potential Homework">
          <div className="flex flex-col gap-8">
            {opusSummary.potentialHomework.map((activity) => (
              <div key={activity.type} className="flex flex-col">
                <h4 className="font-bold">{activity.type}</h4>
                <p>{activity.instructions}</p>
              </div>
            ))}
          </div>
        </FullSessionBreakdownCard>
      </div>
    </div>
  );
};

const FullSessionBreakdownCard: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-4 border rounded-lg shadow-md">
      <div className="px-4 py-6 bg-vid-black-100">
        <h1 className="font-semibold text-sm ">{title}</h1>
      </div>
      <div className="flex flex-col p-4">{children}</div>
    </div>
  );
};
