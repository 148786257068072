import { useEffect, useState } from "react";

export const PrimaryButton: React.FC<{
  onClick?: () => void;
  title: string;
  disabled?: boolean;
  type?: "button" | "submit";
}> = ({ onClick, title, disabled: propDisabled, type }) => {
  const [isDisabled, setIsDisabled] = useState(propDisabled);
  const baseClasses =
    "font-sans w-full rounded-buttons p-buttons flex justify-center items-center";
  const disabledClasses = "bg-vid-black-50 cursor-not-allowed";
  const enabledClasses = "bg-vid-purple text-white";
  const className = isDisabled
    ? `${baseClasses} ${disabledClasses}`
    : `${baseClasses} ${enabledClasses}`;

  useEffect(() => {
    setIsDisabled(propDisabled);
  }, [propDisabled]);

  const handleClick = () => {
    if (onClick) {
      onClick();
      setIsDisabled(true); // Disable the button on click
      setTimeout(() => setIsDisabled(false), 500); // Re-enable after 500ms
    }
  };

  return (
    <button
      onClick={handleClick}
      className={className}
      disabled={isDisabled}
      type={type}
    >
      {title}
    </button>
  );
};

export const InversePrimaryButton: React.FC<{
  onClick: () => void;
  title: string;
  disabled?: boolean;
}> = ({ onClick, title, disabled: propDisabled }) => {
  const [isDisabled, setIsDisabled] = useState(propDisabled);

  const handleClick = () => {
    if (onClick) {
      onClick();
      setIsDisabled(true); // Disable the button on click
      setTimeout(() => setIsDisabled(false), 500); // Re-enable after 500ms
    }
  };
  return (
    <button
      onClick={handleClick}
      disabled={isDisabled}
      className={`lg:min-w-[200px]
      bg-white text-vid-purple mt-4 lg:mt-0 border border-vid-purple rounded-buttons py-2 px-8 lg:p-buttons
      hover:bg-vid-purple hover:text-white
      flex justify-center items-center
      `}
    >
      {title}
    </button>
  );
};
