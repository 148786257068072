import {
  BackgroundFiltersProvider,
  StreamCall,
  StreamVideo,
  StreamVideoClient,
} from "@stream-io/video-react-sdk";
import { pipe } from "fp-ts/lib/function";
import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { O, RD } from "shared/base-prelude";
import { BE } from "../../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../../loading";
import { CP_ROUTES } from "../../../../../routes/cp-routes";

export const CpLivePrivateSessionLayout = () => {
  const sessionId = useTypedParams(CP_ROUTES.SESSIONS.SESSION_ID.LIVE)
    .sessionId!;
  const rdVideoSetup = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).u.session.getSession.query({
        sessionId,
      })
    ),
    [sessionId]
  );
  return pipe(
    rdVideoSetup,
    RD.toOption,
    O.fold(
      () => <div>Loading...</div>,
      (setup) => <LoadedView {...setup} sessionId={sessionId} />
    )
  );
};

type GetSessionResp = UApiOutput["u"]["session"]["getSession"];

const LoadedView: React.FC<GetSessionResp & { sessionId: string }> = ({
  apiKey,
  token,
  streamUser,
  callId,
  callType,
  sessionId,
}) => {
  const [hasJoined, setHasJoined] = useState(false);
  const client = useMemo(
    () => new StreamVideoClient({ apiKey, user: streamUser, token }),
    []
  );
  const call = useMemo(() => client.call(callType, callId), []);

  useEffect(() => {
    call.join().then(() => {
      setHasJoined(true);
    });
  }, [sessionId]);

  if (!hasJoined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <StreamVideo client={client}>
      <StreamCall call={call}>
        <BackgroundFiltersProvider>
          <Outlet />
        </BackgroundFiltersProvider>
      </StreamCall>
    </StreamVideo>
  );
};
