import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ApiMgr, ApiMgrContext } from "shared/backend-api/api.mgr";
import { E } from "shared/base-prelude";
import { FirebaseJsContext } from "shared/firebase";
import { AppConfig, firebaseMgr } from "./app-config";
import { BE } from "./backend";
import { ErrorTracking } from "./error-tracking";
import { HP_ROUTES } from "./routes/hp-routes";

export const IndexLayout: React.FC = () => {
  const location = useLocation();
  const nav = useNavigate();
  const apiMgr = useMemo(
    () =>
      new ApiMgr(
        AppConfig.apiUrl,
        firebaseMgr.getAssumedFirebaseTokenTE,
        ErrorTracking.reportError
      ),
    [AppConfig.apiUrl, AppConfig.appEnv, AppConfig.authAppEnv]
  );

  useEffect(() => {
    if (location.pathname === "/") {
      BE.fetchEndpointTE((Api) => Api.u.me.getMe.query())().then((er) => {
        if (E.isRight(er)) {
          console.log("ME: ", er.right);
          const myPrimaryRole = er.right.me.primary_role;
          const HP_DASH_CLIENTS_PATH = HP_ROUTES.MY.DASHBOARD.CLIENTS.path;
          switch (myPrimaryRole) {
            case "client":
              nav("/cp/dashboard/home");
              break;
            case "hp":
              nav(HP_DASH_CLIENTS_PATH);
              break;
            default:
              nav(HP_DASH_CLIENTS_PATH);
              break;
          }
        } else {
          nav("/onboard/portal-selection");
        }
      });
    }
  }, [location]);

  return (
    <ApiMgrContext.Provider value={apiMgr}>
      <FirebaseJsContext.Provider value={firebaseMgr}>
        <div className="w-screen h-screen flex flex-col justify-center">
          <Outlet />
        </div>
      </FirebaseJsContext.Provider>
    </ApiMgrContext.Provider>
  );
};
