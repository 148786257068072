import React from "react";
import { HpProfile, Modality } from "shared";
import { E, O } from "shared/base-prelude";
import { BE } from "../../backend";
import { Rx } from "../../prelude";
import { HpDashbaordRightNav } from "./my/dashboard/right-drawer-action/right-nav";
import { base64ToBlob } from "../../util";
import { ErrorTracking } from "../../error-tracking";

type ShowBottomToast = {
  msg: string;
  reload?: boolean;
  onUndo?: () => boolean;
};

export type TopAlert = {
  msg: string;
  onClose: () => void;
  actionContent?: React.ReactNode;
  durationSeconds?: number;
};

export class HpState {
  myUserId: string;
  rightNav$ = new Rx.BehaviorSubject(O.none as O.Option<HpDashbaordRightNav>);
  showTopAlert$ = new Rx.BehaviorSubject(O.none as O.Option<TopAlert>);
  showBottomToastSignal$ = new Rx.Subject<ShowBottomToast>();
  myProfile$: Rx.BehaviorSubject<HpProfile>;

  constructor(p: { myProfile: HpProfile }) {
    this.myUserId = p.myProfile.id;
    this.myProfile$ = new Rx.BehaviorSubject(p.myProfile);
  }

  refreshMyProfile = () => {
    this.refreshMyProfileP().catch();
  };

  refreshMyProfileP = async () => {
    const r = await BE.authedTE((tkn) => BE.Api(tkn).hp.getMyProfile.query())();
    console.log("REFRESH MY PROFILE! ", r);
    if (E.isRight(r)) {
      this.myProfile$.next(r.right);
    }

    return r;
  };

  saveProfilePhoto = async (p: { profilePhotoB64String: string }) => {
    const eUploadUrl = await BE.authedTE((tkn) =>
      BE.Api(tkn).hp.getUploadProfilePhotoUrl.query()
    )();

    if (E.isRight(eUploadUrl)) {
      await fetch(eUploadUrl.right.presignedUploadUrl, {
        method: "PUT",
        body: base64ToBlob(p.profilePhotoB64String, "image/jpeg"),
      });

      await BE.authedTE((tkn) =>
        BE.Api(tkn).hp.confirmUploadedProfilePhoto.mutate()
      )();

      await this.refreshMyProfileP();

      console.log(
        "MY PROFILE AFTER REFRESH! ",
        this.myProfile$.value.profilePhoto
      );
    }
  };

  checkIfIsInLiveSession = async () => {
    const isInSessionRes = await BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.getIsCurrentlyInSession.query()
    )();

    if (E.isLeft(isInSessionRes)) {
      ErrorTracking.reportError({
        message: "Error checking if user is in live session",
        extra: { tags: { rawError: JSON.stringify(isInSessionRes.left) } },
      });
      return O.none;
    }
    return O.fromNullable(isInSessionRes.right);
  };

  saveOnboardProfile = async (p: { firstName: string; lastName: string }) => {
    this.myProfile$.next({
      ...this.myProfile$.value,
      firstName: p.firstName,
      lastName: p.lastName,
    });
    const saveResult = await BE.authedTE((tkn) =>
      BE.Api(tkn).hp.setMyOnboardProfile.mutate(p)
    )();
    if (E.isRight(saveResult)) {
      this.refreshMyProfile();
    }
  };

  showBottomToast = (toast: ShowBottomToast) => {
    this.showBottomToastSignal$.next(toast);
  };

  showTopAlert = (alert: TopAlert) => {
    this.showTopAlert$.next(O.some(alert));
  };

  hideTopAlert = () => {
    this.showTopAlert$.next(O.none);
  };

  closeRightNav = () => {
    this.rightNav$.next(O.none);
  };

  openRightNav = (nav: HpDashbaordRightNav) => {
    if (O.isSome(this.rightNav$.value)) {
      this.closeRightNav();
      setTimeout(() => {
        this.rightNav$.next(O.some(nav));
      }, 500);
    } else {
      // make the div with id dashboard-content scroll to the top of the window first
      const dashboardContent = document.getElementById("dashboard-content");
      if (dashboardContent) {
        dashboardContent.scrollTo(0, 0);
      }
      this.rightNav$.next(O.some(nav));
    }
  };

  setMyProfile = (profile: HpProfile) => {
    this.myProfile$.next(profile);
  };

  addModality = (modality: Modality) => {
    const curProfile = this.myProfile$.value;
    this.myProfile$.next({
      ...curProfile,
      modalities: [...curProfile.modalities, modality],
    });
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.settings.addModalityToMySkills.mutate({
        modality,
      })
    )().then((er) => {
      console.log("ER! ", er);
      this.refreshMyProfile();
    });
  };

  removeModality = (modality: Modality) => {
    const curProfile = this.myProfile$.value;
    this.myProfile$.next({
      ...curProfile,
      modalities: curProfile.modalities.filter((m) => m.slug !== modality.slug),
    });
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.settings.removeModalityFromMySkills.mutate({
        modalitySlug: modality.slug,
      })
    )().then((er) => {
      console.log("ER! ", er);
      this.refreshMyProfile();
    });
  };
}

export const HpStateContext = React.createContext<HpState | null>(null);

export function useHpState() {
  return React.useContext(HpStateContext)!;
}
