import { Link, useLocation } from "react-router-dom";
import { VidalifyLogoWithText } from "../../assets/vidalify-logo-with-text.fc";

export namespace DashboardFCs {
  export const UNSELECTED_COLOR = "#161616";
  export const SELECTED_COLOR = "#5500C2";

  interface LeftMenuLinkProps {
    to: string;
    hash?: string;
    children: React.ReactNode;
    onClick?: () => void;
    // the icon is a react component that takes an isSelected prop
    icon?: (isSelected: boolean) => React.ReactNode;
  }

  export const LeftMenuLink: React.FC<LeftMenuLinkProps> = ({
    to,
    children,
    icon,
    onClick,
  }) => {
    const location = useLocation();
    const isSelected = location.pathname + location.hash === to;
    return (
      <Link
        to={to}
        className="p-4 flex gap-4 items-center mx-4 rounded-xl"
        onClick={onClick}
        style={{
          backgroundColor: isSelected ? "#F8F8FF" : "white",
        }}
      >
        {icon && <div className="h-6 w-6">{icon(isSelected)}</div>}
        <div
          style={{
            color: isSelected ? SELECTED_COLOR : UNSELECTED_COLOR,
            fontWeight: 500,
          }}
        >
          {children}
        </div>
      </Link>
    );
  };

  export const LogoutLeftMenuLink: React.FC<{ onClick?: () => void }> = ({
    onClick,
  }) => {
    return (
      <LeftMenuLink
        to=""
        icon={(isSelected) => <LogoutIcon isSelected={isSelected} />}
        onClick={onClick}
      >
        Logout
      </LeftMenuLink>
    );
  };

  type LeftMenuProps = {
    links: (() => React.ReactNode)[];
    onSignout: () => void;
    onLogoClick: () => void;
  };
  export const LeftMenu: React.FC<LeftMenuProps> = ({
    links,
    onSignout,
    onLogoClick,
  }) => {
    return (
      <div
        className="hidden min-h-screen w-[230px] lg:flex flex-col items-center justify-between py-2 bg-white"
        style={{
          boxShadow: "0px 3.141px 13.12px 0px rgba(99, 100, 113, 0.17)",
        }}
      >
        <div className="w-full flex-1 flex flex-col items-center gap-8 py-4">
          <div
            className="cursor-pointer self-stretch flex justify-center items-center"
            onClick={() => {
              onLogoClick();
            }}
          >
            <VidalifyLogoWithText />
          </div>
          <div className="flex-1 w-full flex flex-col gap-3">
            {links.map((link, key) => (
              <div key={key}>{link()}</div>
            ))}
          </div>
          <div className="basis-[200px] self-stretch flex flex-col justify-end">
            <LogoutLeftMenuLink
              onClick={() => {
                onSignout();
              }}
            />
          </div>
        </div>
      </div>
    );
  };
}

export const LogoutIcon: React.FC<{ isSelected: boolean }> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.90002 7.56023C9.21002 3.96023 11.06 2.49023 15.11 2.49023H15.24C19.71 2.49023 21.5 4.28023 21.5 8.75023V15.2702C21.5 19.7402 19.71 21.5302 15.24 21.5302H15.11C11.09 21.5302 9.24002 20.0802 8.91002 16.5402"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12H3.62"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
        stroke="#1D1626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
