import { Disclosure, Menu } from "@headlessui/react";
import {
  ParticipantView,
  useBackgroundFilters,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { format } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import upArrowSrc from "../../../../../public/up-arrow.svg";
import { BE } from "../../../../backend";
import { Avatar } from "../../../../components/avatar.tc";
import { ControlPanelComponents } from "../../../../components/live-session/control-panel.components";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
  WaitingRoomContainer,
} from "../../../../components/live-session/live-session.components";
import { FullContainerLoadingSpinner } from "../../../../loading";
import { O, RD } from "../../../../prelude";
import { HP_ROUTES } from "../../../../routes/hp-routes";
import {
  HpPrivateSessionStateMgr,
  useHpPrivateSessionState,
  usePluckSessionState,
} from "../../sessions/[sessionId]/session.state";

type PastSessionReviews =
  UApiOutput["hp"]["sessions"]["getRecentSessionShortReviews"];

export const HpLivePrivateSessionWaitingRoomPage: React.FC = () => {
  const sessionMgr = useHpPrivateSessionState();
  const rdPastSessionReviews = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.sessions.getRecentSessionShortReviews.query({
        cpUserId: sessionMgr.session.client_user_id,
      })
    )
  );

  return pipe(
    rdPastSessionReviews,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (reviews) => {
        return <LoadedView reviews={reviews} />;
      }
    )
  );
};

const LoadedView: React.FC<{ reviews: PastSessionReviews }> = ({ reviews }) => {
  const sessionMgr = useHpPrivateSessionState();
  // const hasVideo = usePluckSessionState(
  //   sessionMgr.sessionState$,
  //   "hpHasVideo",
  //   false
  // );
  // setTimeout(() => {
  //   sessionMgr
  //     .joinAndPublish()
  //     .then((r) => {
  //       console.log("JOINED AND PUBLISHED! ", r);
  //     })
  //     .catch((e) => {
  //       console.log("ERROR JOINING AND PUBLISHING!!!!! ", e);
  //     });
  // }, 400);

  return (
    <WaitingRoomContainer
      onBackClick={() => {
        leaveAndDeleteSession(sessionMgr).then((er) => {
          console.log("DELETED SESSION! ", er);
        });
        window.location.href = "/hp/my/dashboard/clients";
      }}
      leftSide={<LeftSide />}
      rightSide={
        <PastSessionReviewSection
          reviews={reviews}
          clientProfilePhoto={sessionMgr.session.clientProfilePhoto}
        />
      }
      onStartLink={HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID.LIVE.MAIN_ROOM.buildPath(
        {
          sessionId: sessionMgr.session.id,
        }
      )}
    />
  );
};

const PastSessionReviewSection: React.FC<{
  reviews: PastSessionReviews;
  clientProfilePhoto: string | null;
}> = ({ reviews, clientProfilePhoto }) => {
  return (
    <div className="hidden md:flex flex-col border border-vid-black-200 self-stretch h-[600px] rounded-lg ">
      <div className="px-4 py-2 font-semibold text-sm bg-vid-black-100 rounded-t-lg">
        Past session notes
      </div>
      {reviews.length > 0 ? (
        reviews.map(({ review, sessionInfo }, idx) => {
          return (
            <Disclosure key={idx}>
              <div className="flex flex-col">
                <Disclosure.Button className="py-4 px-4 border flex">
                  <div className="flex-1 self-stretch flex gap-4 items-center">
                    <Avatar mbProfilePhoto={clientProfilePhoto} size={40} />
                    <div>
                      <div className="rounded-full w-[170px] py-2 bg-vid-black-100 text-vid-black-900 text-center">
                        {format(sessionInfo.endedAt, "MMM dd, yyyy")}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center px-8 py-4">
                    <div className="w-8 h-8 flex justify-center items-center rounded-full border border-vid-black-200">
                      <img src={upArrowSrc} className="w-4 h-4" />
                    </div>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                  {review.autoSummary && (
                    <div className="overflow-y-auto py-2 px-4  flex flex-col gap-2">
                      {review.autoSummary.map((s) => {
                        return <p>{`* ${s}`}</p>;
                      })}
                    </div>
                  )}
                </Disclosure.Panel>
              </div>
            </Disclosure>
          );
        })
      ) : (
        <div className="flex-1 flex justify-center items-center">
          No past sessions
        </div>
      )}
    </div>
  );
};

const LeftSide: React.FC = () => {
  const { useRemoteParticipants } = useCallStateHooks();
  const remoteParticipants = useRemoteParticipants();
  return (
    <div className="flex flex-col items-center gap-8">
      <VideoSection />
      {remoteParticipants.length > 0 && (
        <div>{`${remoteParticipants[0].name} is waiting`}</div>
      )}
    </div>
  );
};

const VideoSettingsButton: React.FC = () => {
  const sessionMgr = useHpPrivateSessionState();
  const {
    disableBackgroundFilter, // disables the filter
    applyBackgroundBlurFilter, // applies the blur filter
  } = useBackgroundFilters();
  const isBackgroundBlurred = usePluckSessionState(
    sessionMgr.remoteStateMgr.sessionState$,
    "hpIsScreenBlurred",
    true
  );

  return (
    <Menu>
      <Menu.Button className="flex flex-col justify-center items-center rounded-full h-[60px] w-[60px] bg-white gap-1">
        <div className="rounded-full w-[6px] h-[6px] bg-vid-black-900" />
        <div className="rounded-full w-[6px] h-[6px] bg-vid-black-900" />
        <div className="rounded-full w-[6px] h-[6px] bg-vid-black-900" />
      </Menu.Button>
      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <div
              className={`${
                active ? "bg-violet-500 text-white" : "text-gray-900"
              } group flex w-full rounded-md px-2 py-4 cursor-pointer `}
              onClick={() => {
                if (isBackgroundBlurred) {
                  disableBackgroundFilter();
                  sessionMgr.remoteStateMgr.updateSessionState({
                    hpIsScreenBlurred: false,
                  });
                } else {
                  applyBackgroundBlurFilter("high");
                  sessionMgr.remoteStateMgr.updateSessionState({
                    hpIsScreenBlurred: true,
                  });
                }
                // if (isBackgroundBlurred) {
                //   sessionMgr.disableBackground();
                // } else {
                //   sessionMgr.blurBackground();
                // }
              }}
            >
              {isBackgroundBlurred
                ? "Disable Background Blur"
                : "Enable Background Blur"}
            </div>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

const VideoSection: React.FC<{}> = ({}) => {
  const sessionMgr = useHpPrivateSessionState();
  const { useLocalParticipant, useMicrophoneState, useCameraState } =
    useCallStateHooks();
  const { microphone, isMute: isAudioMute } = useMicrophoneState();
  const { camera, isMute: isVideoMute } = useCameraState();
  const me = useLocalParticipant();

  if (!me) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="self-stretch flex-1 flex justify-end pr-0 md:pr-24">
      <div className="basis-[500px] grow-0 shrink min-w-0 h-[500px] relative rounded-xl overflow-hidden">
        <ParticipantView
          participant={me}
          ParticipantViewUI={CustomParticipantViewUI}
          VideoPlaceholder={CustomVideoPlaceholder}
        />
        <div className="absolute top-4 right-4 w-16 h-16 z-20">
          <VideoSettingsButton />
        </div>
        <div className="absolute bottom-4 right-28 w-16 h-16 z-20">
          <div className="flex gap-8 items-center">
            <ControlPanelComponents.MuteAudioButton
              onClick={() => {
                microphone.toggle();
              }}
              isMuted={isAudioMute}
            />
            {!sessionMgr.session.audio_only && (
              <ControlPanelComponents.MuteVideoButton
                onClick={() => {
                  camera.toggle();
                }}
                isMuted={isVideoMute}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

async function leaveAndDeleteSession(sessionMgr: HpPrivateSessionStateMgr) {
  const sessionId = sessionMgr.session.id;
  const leaveResult = await sessionMgr.leaveAndEndSession();
  const deleteResult = await BE.authedTE((tkn) =>
    BE.Api(tkn).hp.sessions.session.deleteSession.mutate({
      sessionId,
    })
  )();

  console.log("LEAVE RESULT: ", leaveResult);
  console.log("DELETE RESULT: ", deleteResult);

  return deleteResult;
}
