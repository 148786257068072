export const HpDashboardSettingsBillingPage: React.FC = () => {
  return (
    <div className="flex flex-col gap-4 p-8">
      <p className="font-bold text-2xl">
        Coming soon!{" "}
        <span role="img" aria-label="popcorn">
          🍿
        </span>
      </p>
    </div>
  );
};
