import { RadioGroup } from "@headlessui/react";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import React from "react";
import { SimpleUserWithProfilePhoto } from "shared";
import { BE } from "../../backend";
import { SimpleClient } from "../../pages/hp/my/dashboard/right-drawer-action/right-nav";
import { E, RD, Rx, RxO } from "../../prelude";
import { FormDisclosureContainer } from "../disclosures";
import { RadioGroupCircle } from "../primitives/radio-buttons";
import { pipe } from "fp-ts/lib/function";
import { Arr, O } from "shared/base-prelude";
import { useTaskEither } from "shared/backend-api/api.mgr";

export namespace SessionFormComponents {
  export const DurationRadioGroup: React.FC<{
    durationInMinutes$: Rx.Observable<number | null>;
    onChange: (v: number | null) => void;
  }> = ({ onChange, durationInMinutes$ }) => {
    const durationInMinutes = useObservableEagerState(durationInMinutes$);

    return (
      <RadioGroup
        value={durationInMinutes}
        onChange={(v) => {
          onChange(v);
        }}
        className={`flex flex-col gap-4 p-4`}
      >
        <RadioGroup.Label className="flex flex-col">
          <div className={`font-bold text-lg`}>Duration</div>
          <div className="italic">
            This will only be used to assist in sending notifications. It will
            not close the session.
          </div>
        </RadioGroup.Label>
        <div className="flex flex-col gap-2">
          <RadioGroup.Option value={90}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="90 mins" icon="clock" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={60}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="60 mins" icon="clock" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={30}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="30 mins" icon="clock" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={10}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="10 mins" icon="clock" />
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    );
  };

  export const EndingSessionReminder: React.FC<{
    endingSessionReminder$: Rx.Observable<number | null>;
    onChange: (v: number | null) => void;
  }> = ({ onChange, endingSessionReminder$ }) => {
    const endingSessionReminder = useObservableEagerState(
      endingSessionReminder$
    );

    return (
      <RadioGroup
        value={endingSessionReminder}
        onChange={(v) => {
          onChange(v);
        }}
        className={`flex flex-col gap-4 p-4`}
      >
        <div className="flex flex-col gap-2">
          <RadioGroup.Option value={5}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="5 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={10}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="10 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={15}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="15 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={20}>
            {({ checked }) => (
              <RadioGroupCircle checked={checked} text="20 mins" />
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    );
  };

  export const StartSessionButton: React.FC<{
    clientUserId: string;
    onSuccess: (p: { sessionId: string }) => void;
    sessionConfig: {
      durationInMinutes: number | null;
      sendInviteEmail: boolean;
      minutesBeforeEndReminder: number | null;
    };
  }> = ({ clientUserId, sessionConfig, onSuccess }) => {
    return (
      <button
        className="bg-btn-purple p-4 rounded-xl text-white self-stretch mt-8"
        onClick={() => {
          console.log("STARTING SESSION FOR CLIENT! ", clientUserId);
          const {
            durationInMinutes,
            sendInviteEmail,
            minutesBeforeEndReminder,
          } = sessionConfig;
          BE.authedTE((tkn) =>
            BE.Api(tkn).hp.sessions.startSession.mutate({
              clientUserId,
              durationInMinutes,
              sendEmail: sendInviteEmail,
              minutesBeforeEndReminder,
            })
          )().then((er) => {
            console.log("RESULT OF STARTING SESSION! ", er);
            if (E.isRight(er)) {
              onSuccess({ sessionId: er.right.session.id });
            }
          });
        }}
      >
        Start session
      </button>
    );
  };
}

export const SetDurationSection: React.FC<{
  durationInMinutes$: Rx.Observable<number | null>;
  onChange: (v: number | null) => void;
}> = ({ durationInMinutes$, onChange }) => {
  const durationInMinutes = useObservableState(
    durationInMinutes$.pipe(RxO.map((v) => (v ? v.toString() : null))),
    ""
  );

  return (
    <div>
      <FormDisclosureContainer
        buttonView={
          <div className="flex flex-col">
            <h1 className="font-sans font-light text-sm flex w-full justify-start text-vid-purple">
              Duration
            </h1>
            {durationInMinutes && (
              <div>
                <p className="font-sans font-light text-sm mt-2">{`${durationInMinutes} minutes`}</p>
              </div>
            )}
          </div>
        }
        dropdownView={(close) => (
          <div>
            <SessionFormComponents.DurationRadioGroup
              durationInMinutes$={durationInMinutes$}
              onChange={(v) => {
                onChange(v);
                close();
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export const SetPrimaryTherapyTypeSection: React.FC<{
  therapyTypeSlug$: Rx.Observable<string | null>;
  onChange: (v: string | null) => void;
}> = ({ therapyTypeSlug$, onChange }) => {
  const rdAllTherapyTypes = useTaskEither(
    BE.publicTE(() => BE.PublicApi.listTherapyTypes.query())
  );

  const therapyTypeSlug = useObservableEagerState(therapyTypeSlug$);
  return (
    <div>
      <FormDisclosureContainer
        buttonView={
          <div className="flex-1 flex flex-col items-start">
            <h1 className="text-vid-purple font-sans font-light text-sm">
              Session Type
            </h1>
            {therapyTypeSlug && (
              <div className="flex items-center">
                <img
                  src="/dashboard/training-2.png"
                  className="-ml-[10px] object-scale-down"
                  width={40}
                  height={40}
                />
                <p className="font-sans text-sm font-light">
                  {pipe(
                    rdAllTherapyTypes,
                    RD.map((tts) =>
                      pipe(
                        tts,
                        Arr.filterMap((tt) =>
                          tt.slug === therapyTypeSlug ? O.some(tt.name) : O.none
                        ),
                        Arr.head
                      )
                    ),
                    RD.toOption,
                    O.flatten,
                    O.getOrElse(() => "")
                  )}
                </p>
              </div>
            )}
          </div>
        }
        dropdownView={(closeFn) => (
          <div className="flex flex-col">
            {RD.isSuccess(rdAllTherapyTypes) &&
              rdAllTherapyTypes.value.map((tt) => (
                <div
                  className="cursor-pointer py-4 px-2"
                  onClick={() => {
                    onChange(tt.slug);
                    closeFn();
                  }}
                >
                  {tt.name}
                </div>
              ))}
          </div>
        )}
      />
    </div>
  );
};

export const SetEndingSessionReminderSection: React.FC<{
  endingSessionReminder$: Rx.Observable<number | null>;
  onChange: (v: number | null) => void;
}> = ({ endingSessionReminder$, onChange }) => {
  const endingSessionReminder = useObservableEagerState(endingSessionReminder$);
  return (
    <div>
      <FormDisclosureContainer
        buttonView={
          <div className="flex">
            <h1>Ending session reminder</h1>
            {endingSessionReminder && (
              <div>
                <h4>{` - ${endingSessionReminder} minutes`}</h4>
              </div>
            )}
          </div>
        }
        dropdownView={(close) => (
          <div>
            <SessionFormComponents.EndingSessionReminder
              endingSessionReminder$={endingSessionReminder$}
              onChange={(v) => {
                onChange(v);
                close();
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

type SelectClientSectionProps = {
  selectedClient$: Rx.Observable<SimpleClient | null>;
  myClients: SimpleUserWithProfilePhoto[];
  onClientSelect: (client: SimpleClient) => void;
};
export const SelectClientSection: React.FC<SelectClientSectionProps> = ({
  selectedClient$,
  myClients,
  onClientSelect,
}) => {
  const selectedClient = useObservableState(selectedClient$, null);
  return (
    <FormDisclosureContainer
      buttonView={
        <div className="flex gap-1 items-center flex-col">
          <h1 className="font-sans font-light text-sm text-vid-purple w-full flex justify-start">
            Client
          </h1>
          {selectedClient && (
            <p className="font-light text-sm font-sans">{`${
              selectedClient.name ?? ""
            }`}</p>
          )}
        </div>
      }
      dropdownView={(close) => (
        <SelectClient
          myClients={myClients}
          close={close}
          onClientSelect={onClientSelect}
        />
      )}
    />
  );
};

const SelectClient: React.FC<{
  myClients: SimpleUserWithProfilePhoto[];
  onClientSelect: (client: SimpleUserWithProfilePhoto) => void;
  close: () => void;
}> = ({ close, myClients, onClientSelect }) => {
  const [search, setSearch] = React.useState("");

  const MatchingClientsView = React.useCallback(() => {
    return (
      <div className="flex flex-col ">
        {myClients.map((client) => (
          <div
            key={client.id}
            className="p-2 cursor-pointer"
            onClick={() => {
              onClientSelect(client);
              close();
            }}
          >
            {client.name}
          </div>
        ))}
      </div>
    );
  }, [myClients, search]);

  return (
    <div>
      <input
        type="text"
        className="border p-2 w-full"
        placeholder="Search for client"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <MatchingClientsView />
    </div>
  );
};
