import { PrimaryButton } from "../../../primitives/button";

export const ReviewTools: React.FC<{
  onViewFullReview: () => void;
}> = ({ onViewFullReview }) => {
  return (
    <div>
      <PrimaryButton title="View Full Review" onClick={onViewFullReview} />
    </div>
  );
};
