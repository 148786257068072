import { TRPCClientError, createTRPCClient, httpBatchLink } from "@trpc/client";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import {
  RootAuthedRouter,
  RootPublicRouter,
} from "backend/src/Routers/Root.Router";
import { TE } from "backend/src/prelude";
import { pipe } from "fp-ts/lib/function";
import superjson from "superjson";

export const uTrpc = (p: { API_URL: string; jwt: string }) =>
  createTRPCClient<RootAuthedRouter>({
    links: [
      httpBatchLink({
        transformer: superjson,
        url: p.API_URL,
        headers: {
          Authorization: `Bearer ${p.jwt}`,
        },
      }),
    ],
  });

export type UApiOutput = inferRouterOutputs<RootAuthedRouter>;
export type UApiInput = inferRouterInputs<RootAuthedRouter>;

export type PublicApiOutput = inferRouterOutputs<RootPublicRouter>;

export const publicTrpc = (p: { API_URL: string }) => {
  console.log("CREATING PUBLIC TRPC CLIENT, ", p);
  return createTRPCClient<RootPublicRouter>({
    links: [
      httpBatchLink({
        transformer: superjson,
        url: p.API_URL,
      }),
    ],
  });
};

export function trpcQueryToTE<V>(
  trpcQuery: () => Promise<V>
): TE.TaskEither<TrpcFetchError, V> {
  return pipe(
    TE.tryCatch(trpcQuery, (e) => {
      return e as TRPCClientError<RootAuthedRouter>;
    })
  );
}

export type TrpcFetchError = TRPCClientError<RootAuthedRouter>;

// export function isTRPCClientError(
//   cause: unknown
// ): cause is TRPCClientError<RootAuthedRouter> {
//   return cause instanceof TRPCClientError;
// }
