import {DashboardCardItem} from "./dashboard-card-item";

export interface Items {
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface DashboardCardProps {
    title: string;
    background: string;
    list: Items[];
}

export const DashboardCard = ({ title, background, list} : DashboardCardProps) => {
  return (
    <div className={`${background} flex flex-col p-6 max-h-[430px]`}>
        <div className="md:flex justify-between items-end">
            <p className="text-xl font-light">{title}</p>
            <p className=" text-vid-purple hover:cursor-pointer">View all</p>
        </div>
        {list.map((item, i) => (
          <div key={i} id={`${i}`}>
            <DashboardCardItem 
              img={item.img}
              title={item.title}
              subtitle={item.subtitle}
              link={item.link}
              />
            </div>
        ))}
    </div>
  )
}
