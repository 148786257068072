import DateTimePicker from "react-datetime-picker";

export const FormCardContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className={`flex flex-col border rounded-lg p-6`}>{children}</div>
  );
};

export namespace FormComponents {
  export const Input: React.FC<{
    label: string;
    value: string;
    onChange: (value: string) => void;
    type?: string;
    placeholder?: string;
  }> = ({ label, value, onChange, type = "text", placeholder }) => (
    <div className="flex flex-col gap-2 rounded-full">
      <label className="text-gray-500">{label}</label>
      <input
        className="border border-gray-200 rounded-lg p-4"
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  export const RadioBtn: React.FC<{ checked: boolean; text?: string }> = ({
    checked,
    text,
  }) => (
    <div
      className={`flex items-center}
      `}
    >
      <div
        className={`flex justify-center items-center border-[1.6px] rounded-full w-8 h-8 cursor-pointer text-lg
      ${checked ? "bg-default-purple text-default-purple" : ""}
      `}
      />
      {text && <div className="ml-3 text-vid-black-900">{text}</div>}
    </div>
  );

  type CalendarPickerProps = {
    onChange: (date: Date | null) => void;
    value: Date | null;
  };

  export const CalendarPicker: React.FC<CalendarPickerProps> = ({
    onChange,
    value,
  }) => {
    return <DateTimePicker onChange={onChange} value={value} />;
  };
}
