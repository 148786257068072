import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirebaseJs } from "shared/firebase";
import { match } from "ts-pattern";
import { HP_ROUTES } from "../../../routes/hp-routes";
import { CP_ROUTES } from "../../../routes/cp-routes";

type Source = "PRIVATE_CHAT_HP_CP" | "HP_NEW_CLIENT_INVITE";

type OtherQueryParams = {
  clientId: string | null;
};

function pathForSource(source: Source, otherQueryParams: OtherQueryParams) {
  return match(source)
    .with("PRIVATE_CHAT_HP_CP", () =>
      HP_ROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.CHAT.buildPath({
        clientId: otherQueryParams.clientId!,
      })
    )
    .with("HP_NEW_CLIENT_INVITE", () => CP_ROUTES.DASHBOARD.HOME.path)
    .exhaustive();
}

export const AnonConfirmEmailInviteRedirectPage: React.FC = () => {
  const nav = useNavigate();
  const url = useLocation();
  const firebaseJs = useFirebaseJs();
  const emailQueryParam = new URLSearchParams(url.search).get("email")!;
  const redirectToQueryParam = new URLSearchParams(url.search).get(
    "redirectTo"
  );
  const sourceQueryParam = new URLSearchParams(url.search).get("source")!;
  const otherQueryParams: OtherQueryParams = {
    clientId: new URLSearchParams(url.search).get("clientId"),
  };
  // ...

  useEffect(() => {
    const siwl = isSignInWithEmailLink(firebaseJs.auth, window.location.href);
    console.log("IS SIGN IN WITH EMAIL LINK! ", siwl);
    if (siwl) {
      setTimeout(() => {
        console.log("signInWithEmailLink", emailQueryParam);
        signInWithEmailLink(
          firebaseJs.auth,
          emailQueryParam,
          window.location.href
        )
          .then((result) => {
            console.log("RESULT: ", result);
            setTimeout(() => {
              console.log("OTHER QUERY PARAMS! ", otherQueryParams);
              if (redirectToQueryParam) {
                console.log("REDIRECTING TO! ", redirectToQueryParam);
                window.location.href = redirectToQueryParam;
              } else {
                nav(
                  pathForSource(sourceQueryParam as Source, otherQueryParams)
                );
              }
            }, 800);
          })
          .catch((error) => {
            // Handle errors
            console.log("UH OH!", error);
          });
      }, 1000);
    }
  }, []);

  return (
    <div>
      <h1>{`Hi ${emailQueryParam}!. You are being redirected`}</h1>
    </div>
  );
};
