import React, { useState } from "react";
import { Control, Controller, Path } from "react-hook-form";
import { FaPencilAlt } from "react-icons/fa";
import { IoEye, IoEyeOff } from "react-icons/io5";

export type EmailPasswordForm = {
  email: string;
  password: string;
};

interface Props<TFormValues extends EmailPasswordForm> {
  control: Control<TFormValues>;
  name: Path<TFormValues>;
}

export const PasswordInput = <TFormValues extends EmailPasswordForm>({
  control,
  name,
}: Props<TFormValues>) => {
  const [isShowingPwd, setIsShowingPwd] = React.useState(false);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field }) => (
        <div className="relative text-input">
          <input
            type={isShowingPwd ? "text" : "password"}
            placeholder="Password"
            className="border-none focus:outline-none"
            autoCapitalize="none"
            autoComplete="none"
            autoCorrect="none"
            id="password"
            {...field}
          />
          <button
            onClick={() => setIsShowingPwd(!isShowingPwd)}
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            <span className="icon-eye">
              {isShowingPwd ? <IoEyeOff /> : <IoEye />}
            </span>
          </button>
        </div>
      )}
    />
  );
};

type EditableTextInputProps = {
  name: Path<any>;
  label: string;
  placeholder: string;
  control: Control<any>;
  hasEditToggle?: boolean;
};

export const EditableTextInput = ({
  name,
  label,
  placeholder,
  control,
  hasEditToggle,
}: EditableTextInputProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className="flex items-center text-input">
      <div className="flex-grow">
        <label
          htmlFor={"firstName"}
          className="text-vid-purple text-sm font-bold mb-2"
        >
          {label}
        </label>
        <div className="relative">
          <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                id={name}
                className="appearance-none  w-full py-2 pr-8 text-gray-700 leading-tight focus:outline-none "
                placeholder={placeholder}
                onBlur={handleBlur}
              />
            )}
          />
          {!isEditing && hasEditToggle && (
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-2 text-gray-400 hover:text-gray-600 focus:outline-none"
              onClick={handleEdit}
            >
              <FaPencilAlt />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
