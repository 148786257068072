import {
  DocumentData,
  DocumentReference,
  Firestore,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { FirebaseJsMgr } from "shared/firebase";
import {
  BaseSessionState,
  SessionState,
  SessionStateSchema,
  defaultNormalSessionState,
} from "shared/session-state/session-state";
import { O, Rx, RxO, TE } from "../../prelude";
import { isNonNull } from "../../util";
import { match } from "ts-pattern";
import meditationMp3 from "./resources/meditation.mp3";
import { z } from "zod";

type SessionType =
  | { _tag: "PRIVATE"; sessionId: string }
  | { _tag: "GROUP"; groupSessionId: string };

export class FirestoreSessionStateMgmt<ExtraSessionState> {
  syncedSessionState$ = new Rx.BehaviorSubject<
    O.Option<BaseSessionState & ExtraSessionState>
  >(O.none);
  sessionDoc: DocumentReference<DocumentData, DocumentData>;

  sessionState$ = this.syncedSessionState$.pipe(
    RxO.map(O.toNullable),
    RxO.filter(isNonNull),
    RxO.distinctUntilChanged(
      (prev, cur) => JSON.stringify(prev) === JSON.stringify(cur)
    )
  );

  baseSessionState$: Rx.Observable<BaseSessionState> = this.sessionState$.pipe(
    RxO.map((ss) => ({
      hpRoom: ss.hpRoom,
      meditationState: ss.meditationState,
      playingMediaFile: ss.playingMediaFile,
    }))
  );

  audioPlayer: HTMLAudioElement | null = null;

  docForSessionType(
    sessionType: SessionType,
    firestore: Firestore
  ): DocumentReference<DocumentData, DocumentData> {
    return match(sessionType)
      .with({ _tag: "PRIVATE" }, ({ sessionId }) =>
        doc(firestore, "sessions", sessionId)
      )
      .with({ _tag: "GROUP" }, ({ groupSessionId }) =>
        doc(firestore, "group-sessions", groupSessionId)
      )
      .exhaustive();
  }

  constructor(
    mgr: FirebaseJsMgr,
    sessionType: SessionType,
    readonly initialSessionState: BaseSessionState & ExtraSessionState,
    schema: z.Schema<BaseSessionState & ExtraSessionState>
  ) {
    this.sessionDoc = this.docForSessionType(sessionType, mgr.firestore);

    onSnapshot(this.sessionDoc, (ssr) => {
      const sessionState = schema.parse(ssr.data());
      this.syncedSessionState$.next(O.some(sessionState));
    });

    this.sessionState$
      .pipe(RxO.map((ss) => ss.playingMediaFile))
      .subscribe((mbMediaFile) => {
        if (mbMediaFile) {
          this.audioPlayer = new Audio(
            sourceForKnownMediaFile(mbMediaFile as KnownMediaFile)
          );
          this.audioPlayer.play();
        } else {
          this.audioPlayer?.pause();
          this.audioPlayer = null;
        }
      });
  }

  createSessionStateDoc() {
    return setDoc(this.sessionDoc, defaultNormalSessionState);
  }

  deleteSessionStateDoc() {
    return deleteDoc(this.sessionDoc);
  }

  async getCurSessionState(): Promise<SessionState> {
    return getDoc(this.sessionDoc).then((ssr) => {
      return SessionStateSchema.parse(ssr.data());
    });
  }

  async updateSessionState(update: Partial<SessionState>) {
    const updateResult = await updateDoc(this.sessionDoc, update);
    return updateResult;
  }

  updateSessionStateTE = (updates: Partial<SessionState>) => {
    return TE.tryCatch(
      () => this.updateSessionState(updates),
      (e) => e as any
    );
  };

  runUpdateSessionState(updates: Partial<SessionState>) {
    this.updateSessionStateTE(updates)().catch((e) => {
      console.error("Error updating session state", e);
    });
  }

  updateWithRemoteSessionState = (
    updates: (curState: SessionState) => Partial<SessionState>
  ) => {
    this.getCurSessionState().then((curState) => {
      console.log("UPDATE REMOTE STATE! ", updates(curState));
      return this.updateSessionState(updates(curState));
    });
  };
}

type KnownMediaFile = "meditation.mp3";

function sourceForKnownMediaFile(file: KnownMediaFile): string {
  return match(file)
    .with("meditation.mp3", () => meditationMp3)
    .exhaustive();
}
