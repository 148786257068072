import { Switch } from "@headlessui/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BE } from "../../../../../../backend";
import { Avatar } from "../../../../../../components/avatar.tc";
import { InversePrimaryButton } from "../../../../../../components/primitives/button";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import { match } from "ts-pattern";

type MyProfileCardProps = {
  name: string;
  profilePhoto: string | null;
  isAvailable: boolean;
  bio: string;
  modalities: { name: string; slug: string }[];
  editBioLink: string;
  communitySlug: string;
};

export const MyProfileCard: React.FC<MyProfileCardProps> = ({
  name,
  profilePhoto,
  isAvailable,
  bio,
  modalities,
  editBioLink,
  communitySlug,
}) => {
  return (
    <div className="hidden lg:flex flex-col max-w-[345px] rounded-lg min-h-0 self-start">
      <div className="flex flex-col items-center pb-8 w-full bg-white rounded-xl border  border-vid-black-200">
        <div
          className="h-[151px] self-stretch flex flex-col justify-center items-center"
          style={{
            background:
              "linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), var(--Vidalify-Purple, #690DFF)",
          }}
        >
          <Avatar mbProfilePhoto={profilePhoto} size={50} />
        </div>
        <div className="flex flex-col px-8">
          <div className="justify-center px-2 mt-4 text-2xl leading-7 text-center text-black whitespace-nowrap">
            {name}
          </div>
          <AvailableForPracticeSwitch
            isAvailable={isAvailable}
            isToggleble
            onFinishedToggling={(v) => {
              // TODO!: Make available per community
              console.log("SETTING IS AVAILABLE! ", v);
              BE.authedTE((tkn) =>
                BE.Api(tkn).hp.community.setIsAvailableForPractice.mutate({
                  communitySlug,
                  isAvailable: v,
                })
              )().then((er) => {
                console.log("RESULT OF MAKING AVAILBLE! ", er);
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              });
            }}
          />
          <div className="flex flex-col self-stretch p-6 mt-4 w-full rounded-xl">
            <div className="text-sm font-medium leading-4 text-gray-900">
              About
            </div>
            <div className="mt-4 text-base leading-6 text-violet-700">
              {bio}
            </div>
          </div>
          <div className="flex flex-col self-stretch p-6 w-full text-base leading-5 text-black">
            <div className="text-sm font-medium text-gray-900">
              Modalities offered
            </div>
            <div className="flex flex-wrap gap-2">
              {modalities.map((m) => {
                return (
                  <div
                    key={m.slug}
                    className="flex gap-3.5 justify-between px-6 py-3.5 rounded-3xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)]"
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/825848ad326a0fc7a3e2e461deeea59f9c0bec51a146a512e5d339548ba3e7a0?"
                      className="w-6 aspect-square"
                    />
                    <div className="self-start mt-1">{m.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <Link
            to={editBioLink}
            className="justify-center items-center px-16 py-6 mt-4 w-full text-lg font-medium leading-5 text-center text-violet-700 whitespace-nowrap bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Purple,#690DFF)] max-w-[292px]"
          >
            Edit
          </Link>
        </div>
      </div>
    </div>
  );
};

type MemberCardProps = {
  userId: string;
  name: string;
  profilePhoto: string | null;
  isAvailable?: boolean;
  modalities: string[];
};

export const MemberCard: React.FC<MemberCardProps> = ({
  userId,
  name,
  profilePhoto,
  isAvailable,
  modalities,
}) => {
  const nav = useNavigate();
  return (
    <div
      className="flex flex-col items-center pb-6 w-[220px] h-[300px] bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)] cursor-pointer"
      onClick={() => {
        nav(
          HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
            { userId }
          )
        );
      }}
    >
      <div className="basis-[100px] self-stretch relative border-2 mb-8 bg-blue-200">
        <div className="absolute -bottom-[15px] left-0 right-0 h-[30px] flex justify-center items-center">
          <Avatar mbProfilePhoto={profilePhoto} size={54} />
        </div>
      </div>
      <div className="flex flex-col gap-4 px-4">
        <div className="mt-2.5 text-base leading-6 text-center text-black whitespace-nowrap cursor-pointer">
          {name}
        </div>
        {isAvailable && <AvailableForPracticeTag />}
        <div className="mt-5 text-xs leading-5 text-center text-neutral-400">
          {modalities.join(" | ")}
        </div>
        {/* <div className="justify-center px-10 py-4 mt-6 text-base font-medium leading-5 text-center text-violet-700 whitespace-nowrap bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Purple,#690DFF)]">
            Follow
          </div> */}
      </div>
    </div>
  );
};

const AvailableForPracticeSwitch: React.FC<{
  onFinishedToggling: (b: boolean) => void;
  isAvailable?: boolean;
  isToggleble?: boolean;
}> = ({ isAvailable, isToggleble, onFinishedToggling }) => {
  const [enabled, setEnabled] = useState(isAvailable);
  const [isDisabled, setIsDisabled] = useState(false);

  const onChange = (value: boolean) => {
    if (isToggleble) {
      setEnabled(value);
      onFinishedToggling(value);
      setIsDisabled(true);

      setTimeout(() => {
        setIsDisabled(false);
      }, 1000);
    }
  };

  return (
    <div className="flex items-center gap-2 px-3 py-2 bg-slate-50 rounded-[30px]">
      <Switch
        checked={enabled}
        disabled={isDisabled}
        onChange={onChange}
        className={`${enabled ? "bg-green-500" : "bg-gray-400"}
          relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${enabled ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <div
        className={`text-green-500 whitespace-nowrap font-medium text-sm ${
          enabled ? "text-green-500" : "text-gray-400"
        }`}
      >
        {enabled ? "Available for practice" : "Not available"}
      </div>
    </div>
  );
};

export const AvailableForPracticeTag: React.FC = () => {
  return (
    <div className="flex items-center gap-2 justify-between mt-2 text-green-500 text-xs lg:text-base">
      <div className="w-2 h-2 rounded-full bg-green-500"></div>
      {/* <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5093556ced65e4ea6ff16911ed3bb990d1ee74469d1178b3a8405502aca84859?"
        className="self-start aspect-square w-[15px]"
      /> */}
      <div className="grow">Available for practice</div>
    </div>
  );
};

interface TopBannerProps {
  circleImageUrl: string | null;
  title: string;
  nextToTitleFc: React.ReactNode;
  rightView: React.ReactNode;
}
export const TopBanner: React.FC<TopBannerProps> = ({
  circleImageUrl,
  title,
  nextToTitleFc,
  rightView,
}) => {
  return (
    <section className="flex flex-col">
      <header className="flex flex-col justify-center w-full bg-violet-700 rounded-xl max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc04addd764ad8e8a96e0f2d32b56477025ed2959f96775d55c6914e349c57fe?apiKey=7548643458aa4f489ce4f6ae79dc4302&"
          alt=""
          className="w-full aspect-[3.57] max-md:max-w-full"
        />
      </header>
      <div className="flex z-10 gap-5 justify-between pr-9 -mt-10 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
        <div className="max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
            <aside className="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
              <div className="mx-auto border-2 border-solid bg-slate-50 border-[color:var(--White,#FFF)] lg:h-[125px] rounded-full w-12 h-12 lg:w-[125px] max-md:mt-5">
                {circleImageUrl ? (
                  <img
                    src={circleImageUrl}
                    className="w-full h-full rounded-full"
                  />
                ) : (
                  <></>
                )}
              </div>
            </aside>
            <div className="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col lg:flex-row gap-4 mt-4 lg:mt-16 font-medium whitespace-nowrap">
                <h2 className="grow text-2xl lg:text-4xl text-gray-900 leading-[57.6px]">
                  {title}
                </h2>
                {nextToTitleFc}
              </div>
            </div>
          </div>
        </div>
        <div>{rightView}</div>
      </div>
    </section>
  );
};

export const TabView: React.FC<{
  title: string;
  isCurrent: boolean;
  linkTo: string;
}> = ({ title, isCurrent, linkTo }) => {
  return (
    <Link
      to={linkTo}
      className={`
      text-gray-900 text-2xl font-normal font-['Roboto Flex'] leading-[28.80px] py-4 px-2 cursor-pointer
      ${isCurrent ? "text-vid-purple border-b-2 border-vid-purple" : ""}
      `}
    >
      {title}
    </Link>
  );
};

function communityColorCardItemBgColor(idx: number) {
  switch (idx) {
    case 0:
      return "#23CE24";
    case 1:
      return "#690DFF";
    case 2:
      return "#FFA8A4";
    case 3:
      return "#FFCF26";
    default:
      return "#23CE24";
  }
}

type HeaderBackgroundType =
  | { _tag: "INDEXED"; index: number }
  | { _tag: "EVENT_PURPLE" };

function headerBackground(type: HeaderBackgroundType): string {
  return match(type)
    .with({ _tag: "INDEXED" }, ({ index }) => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), ${communityColorCardItemBgColor(
        index
      )}`;
    })
    .with({ _tag: "EVENT_PURPLE" }, () => {
      return `linear-gradient(105deg, rgba(255, 255, 255, 0.20) 1.14%, rgba(255, 255, 255, 0.00) 102.16%), var(--Vidalify-Purple, #690DFF)`;
    })
    .exhaustive();
}

export const CommunityCardContainer: React.FC<{
  innerCircleComponent: React.ReactNode;
  title: string;
  content: React.ReactNode;
  headerBackgroundType: HeaderBackgroundType;
  button: {
    title: string;
    onClick: () => void;
  };
}> = ({
  innerCircleComponent,
  title,
  content,
  button,
  headerBackgroundType,
}) => {
  return (
    <div className=" aspect-3/4 lg:aspect-auto w-[180px] lg:h-[361px] lg:w-[220px] flex flex-col border-vid-black-200 rounded-[12px]  border">
      <div
        className={`
        border-tr-1 relative aspect-3/4 lg:aspect-auto h-[150px] lg:h-[112px] border rounded-tr-[12px] rounded-tl-[12px] 
        `}
        style={{
          background: headerBackground(headerBackgroundType),
        }}
      >
        <div className="absolute -bottom-[15px] left-0 right-0 h-[30px] flex justify-center items-center">
          {innerCircleComponent}
        </div>
      </div>
      <div className="flex-1 flex flex-col items-center text-xs lg:text-base mt-2 lg:mt-[40px] p-4 lg:p-[22px]">
        <div className="font-semibold whitespace-nowrap">{title}</div>
        <div className="flex-1 flex flex-col">{content}</div>
        <InversePrimaryButton onClick={button.onClick} title={button.title} />
      </div>
    </div>
  );
};
