import {
  ParticipantView,
  useCall,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { taskEitherToEff } from "shared/base-prelude";
import { useFirebaseJs } from "shared/firebase";
import {
  defaultNormalSessionState,
  PrivateSessionState,
  SessionStateSchema,
} from "shared/session-state/session-state";
import { BE } from "../../../../../backend";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
} from "../../../../../components/live-session/live-session.components";
import { MainRoomContainer } from "../../../../../components/live-session/main-room/main-room-container";
import { FullContainerLoadingSpinner } from "../../../../../loading";
import { FirestoreSessionStateMgmt } from "../../../../../mgrs/live-session/firestore-live-session.mgmt";
import { MainRoomStateMgr } from "../../../../../mgrs/live-session/main-room/main-room.statemgr";
import { CP_ROUTES } from "../../../../../routes/cp-routes";
import { hasScreenShare } from "../../../../../util";

type RegisterPingRes = UApiOutput["cp"]["liveSession"]["registerPing"];

type TopPreviewState = {};

export const CpLivePrivateSessionMainRoomPage: React.FC = () => {
  const nav = useNavigate();
  const call = useCall()!;
  const { useLocalParticipant, useRemoteParticipants } = useCallStateHooks();
  const me = useLocalParticipant();
  const remoteParticipants = useRemoteParticipants();
  const firebaseJs = useFirebaseJs();
  const remoteStateMgr = useMemo(() => {
    return new FirestoreSessionStateMgmt<PrivateSessionState>(
      firebaseJs,
      { _tag: "PRIVATE", sessionId: call.id },
      defaultNormalSessionState,
      SessionStateSchema
    );
  }, []);
  const mainRoomMgr = useMemo(
    () =>
      new MainRoomStateMgr<
        RegisterPingRes,
        TopPreviewState,
        PrivateSessionState
      >({
        sendPingEff: taskEitherToEff(
          BE.fetchEndpointTE((Api) =>
            Api.cp.liveSession.registerPing.mutate({ sessionId: call.id })
          )
        ),
        remoteStateMgr,
      }),
    []
  );
  if (!me) {
    return <FullContainerLoadingSpinner />;
  }

  const participantInSpotlight =
    remoteParticipants.length > 0 ? remoteParticipants[0] : me;

  return (
    <MainRoomContainer
      mainRoomStateMgr={mainRoomMgr}
      knownTools={[]}
      leftControls={[]}
      topBarLeft={{
        memberProfilePhotos: [],
      }}
      onEndCall={() => onEndCall(nav)}
      stageContent={
        <div className="flex-1 md:aspect-16/9 min-w-0 min-h-0 relative border-2 rounded-[22px] max-w-screen">
          <ParticipantView
            participant={participantInSpotlight}
            trackType={
              hasScreenShare(participantInSpotlight)
                ? "screenShareTrack"
                : "videoTrack"
            }
            ParticipantViewUI={<CustomParticipantViewUI />}
            VideoPlaceholder={CustomVideoPlaceholder}
          />
          {remoteParticipants.length > 0 && (
            <div className="absolute bottom-8 right-8 w-[200px] aspect-16/9 z-30 rounded-[22px] border-2 ">
              <ParticipantView
                participant={me}
                ParticipantViewUI={<CustomParticipantViewUI />}
                VideoPlaceholder={CustomVideoPlaceholder}
              />
            </div>
          )}
        </div>
      }
      topPreviewDisplay={(_) => <></>}
    />
  );
};

async function onEndCall(nav: NavigateFunction) {
  nav(CP_ROUTES.DASHBOARD.HOME.path);
}
