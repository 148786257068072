function EllipseIcon(p: { size: 2 | 3; fill: "gray" | "black" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={p.size.toString()}
      height={p.size.toString()}
      viewBox={`0 0 ${p.size} ${p.size}`}
      fill="none"
    >
      <circle
        cx={p.size}
        cy={p.size}
        r={p.size}
        fill={p.fill === "black" ? "#161616" : "#DBDAEC"}
      />
    </svg>
  );
}

export const EllipseMenuIcon: React.FC<{
  size: 2 | 3;
  fill: "gray" | "black";
}> = (p) => {
  return (
    <div className="flex flex-col gap-[2px]">
      <EllipseIcon {...p} />
      <EllipseIcon {...p} />
      <EllipseIcon {...p} />
    </div>
  );
};
