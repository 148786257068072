import { formatDistanceToNowStrict } from "date-fns";
import * as React from "react";
import { Link } from "react-router-dom";
import { CommunityPostInfo, CommunityPostReply } from "shared";
import { E } from "shared/base-prelude";
import { BE } from "../../../../../../backend";
import { Avatar } from "../../../../../../components/avatar.tc";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import { AvailableForPracticeTag } from "./community.components";

type Poster = {
  id: string;
  name: string;
  profilePhoto: string | null;
  isAvailableForPractice: boolean;
};

type CommunityPostProps = {
  communitySlug?: string;
  post: CommunityPostInfo;
  onLike: (isLiked: boolean) => void;
};

export const CommunityPost: React.FC<CommunityPostProps> = ({
  communitySlug,
  post,
  onLike,
}) => {
  const { likes, author, mainText, mainImageUrl, fileType } = post;
  const [numLikes, setNumLikes] = React.useState(likes);
  const [isLiked, setIsLiked] = React.useState(post.haveILikedPost);
  const [isLikeDisabled, setIsLikeDisabled] = React.useState(false);
  const [replies, setReplies] = React.useState(post.replies);
  return (
    <div className="flex flex-col max-w-[800px] lg:border lg:border-vid-black-200 rounded-lg lg:p-4">
      <PosterInfoSection postedAt={post.postedAt} poster={author} />
      {/* <div className="flex gap-5 justify-between px-6 py-4 w-full text-sm font-medium leading-4 whitespace-nowrap bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="my-auto w-6 h-6" />
      </div> */}
      <div className="px-1 py-2 lg:py-8 lg:px-4 text-sm lg:text-base">{mainText}</div>
      {mainImageUrl && fileType ? (
        <div className="relative w-full h-[300px]">
          {fileType.includes("video") ? (
            <video
              src={mainImageUrl}
              className="absolute inset-0 w-full h-full object-contain lg:object-cover"
              controls
              muted
              loop
              playsInline
            />
          ) : (
            <img
              src={mainImageUrl}
              className="absolute inset-0 w-full h-full object-contain lg:object-cover"
            />
          )}
        </div>
      ) : (
        <div className="justify-center items-center px-16 h-16 w-full text-7xl font-medium text-center text-gray-900 whitespace-nowrap bg-zinc-300 leading-[96px] max-md:px-5 max-md:py-10 max-md:max-w-full max-md:text-4xl"></div>
      )}
      <div className="flex gap-5 justify-between p-3 lg:p-6 w-full text-sm font-medium leading-4 whitespace-nowrap bg-white border border-[color:var(--Vidalify-Black-200,#DBDAEC)] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="self-stretch">
          {numLikes > 0 && (
            <div className="flex gap-3 justify-between text-gray-900">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e009f647cc2c68c63e731310e044ad885937197095639739574fdec9df478ca?"
                className="w-6 aspect-square"
              />
              <div className="justify-center px-px py-1 my-auto aspect-[0.94]">
                {numLikes}
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-5 justify-between text-neutral-400">
          {!isLiked ? (
            <button
              className="flex gap-2 justify-between"
              // disabled={isLikeDisabled}
              onClick={() => {
                console.log("IS LIKED?! ", isLiked);
                setNumLikes((l) => l + 1);
                setIsLiked(true);
                onLike(true);
                setIsLikeDisabled(true);
                setTimeout(() => {
                  setIsLikeDisabled(false);
                }, 2000);
              }}
            >
              <UnlikedHeartSvg />
              <div className="hidden lg:flex justify-center px-px py-1 my-auto lg:aspect-[1.53]">
                Like
              </div>
            </button>
          ) : (
            <button
              disabled={isLikeDisabled}
              onClick={() => {
                console.log("IS LIKED?! ", isLiked);
                setNumLikes((l) => l - 1);
                setIsLiked(false);
                onLike(false);
                setIsLikeDisabled(true);
                setTimeout(() => {
                  setIsLikeDisabled(false);
                }, 2000);
              }}
            >
              <LikedHeartSvg />
            </button>
          )}
          <div className="flex gap-2 justify-between cursor-pointer">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/dd92debdbd1ee3fc4e91c304661aa4208cbb663e6423b216aaf4fb351bc7cab3?"
              className="w-6 aspect-square"
            />
            <div className="hidden lg:flex justify-center px-px py-1 my-auto aspect-[3.71]">
              Comment
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 pt-4">
        <div className="flex flex-col gap-6">
          {replies.reverse().map((r) => (
            <ReplyItem key={r.id} reply={r} />
          ))}
        </div>
        <div>
          <PostCommentView
            communitySlug={communitySlug}
            postId={post.id}
            onSuccessReply={(reply) => {
              setReplies((r) => [reply, ...r]);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const PostCommentView: React.FC<{
  communitySlug?: string;
  postId: string;
  onSuccessReply: (reply: CommunityPostReply) => void;
}> = ({ communitySlug, postId, onSuccessReply }) => {
  const [inputValue, setInputValue] = React.useState("");
  return (
    <div className="flex gap-4 items-center max-md:flex-wrap">
      <Avatar mbProfilePhoto={null} size={40} />
      <div className="flex-1 h-14 relative">
        <textarea
          className="absolute inset-0  bg-slate-50 rounded-[500px] flex items-center py-4 pl-4 pr-24"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <div
          className="absolute right-6 text-sm lg:text-base  top-0 bottom-0 flex flex-col justify-center w-8 cursor-pointer"
          onClick={() => {
            if (inputValue.trim() === "") {
              return;
            }
            if (communitySlug) {
              BE.authedTE((tkn) =>
                BE.Api(tkn).hp.community.replyToPost.mutate({
                  communitySlug,
                  postId,
                  reply: inputValue,
                })
              )().then((er) => {
                console.log("Result of replying!: ", er);
                if (E.isRight(er)) {
                  onSuccessReply(er.right.reply);
                  setInputValue("");
                }
              });
            }
          }}
        >
          Reply
        </div>
      </div>
    </div>
  );
};

const ReplyItem: React.FC<{ reply: CommunityPostReply }> = ({ reply }) => {
  return (
    <div className="flex gap-4 items-start lg:items-center">
      <Avatar mbProfilePhoto={reply.author.profilePhoto} size={40} />
      <div className="flex-1 bg-slate-50 rounded-lg p-2 lg:p-4 text-sm lg:text-base">{reply.content}</div>
    </div>
  );
};

const PosterInfoSection: React.FC<{ postedAt: Date; poster: Poster }> = ({
  postedAt,
  poster,
}) => {
  return (
    <Link
      to={HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
        { userId: poster.id }
      )}
      className="flex gap-4 justify-between cursor-pointer"
    >
      {poster.profilePhoto && (
        <img
          src={poster.profilePhoto}
          className="w-10 h-10 lg:h-14 lg:w-14 aspect-square rounded-full object-cover bg-gray-100"
        />
      )}
      <div className="flex flex-col flex-1 my-auto">
        <div className="flex flex-col lg:flex-row gap-0 lg:gap-2 text-gray-900">
          <div className="font-semibold grow text-sm lg:text-base">{poster.name}</div>
          <div className="hidden lg:block">•</div>
          <div className="text-neutral-400 text-xs lg:text-base">
            {formatDistanceToNowStrict(postedAt, { addSuffix: false })
              .replace(/ minutes?/, "m")
              .replace(/ hours?/, "h")
              .replace(/ days?/, "d")
              .replace(/about /, "")
              .replace(/^1 day ago$/, "Yesterday")}
          </div>
        </div>
        {poster.isAvailableForPractice && <AvailableForPracticeTag />}
      </div>
    </Link>
  );
};

const UnlikedHeartSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        stroke="#1D1626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 014.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12z"
      ></path>
    </svg>
  );
};

const LikedHeartSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#690DFF"
        stroke="#690DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 014.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12z"
      ></path>
    </svg>
  );
};
