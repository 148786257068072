import { Effect, Either } from "effect";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BE } from "../../../backend";
import {
  EmailPasswordForm,
  PasswordInput,
} from "../../../components/onboard.components";
import {
  FirebaseAuthError,
  FirebaseJsMgr,
  messageForFirebaseAuthError,
  useFirebaseJs,
} from "shared/firebase";
import { RD } from "../../../prelude";
import { HP_ROUTES } from "../../../routes/hp-routes";
import { match } from "ts-pattern";
import { UserCredential } from "firebase/auth";

export const OnboardHpRegisterPage: React.FC = () => {
  const nav = useNavigate();
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <AuthinCard
        onSuccessLogin={() => {
          nav(HP_ROUTES.MY.DASHBOARD.CLIENTS.path);
        }}
        onSuccessRegister={() => {
          nav(HP_ROUTES.ONBOARD_CHOOSE_PROFILE_METHOD.path);
        }}
      />
    </div>
  );
};

type AuthinCardProps = {
  onSuccessRegister: () => void;
  onSuccessLogin: () => void;
};

function registerViaApiEff(p: { email: string; firebaseUid: string }) {
  return Effect.tryPromise({
    try: () => BE.PublicApi.auth.register.mutate({ ...p, primaryRole: "hp" }),
    catch: (e) => e as Error,
  });
}

type PerfRegisterResult =
  | { _tag: "REGISTERED"; res: unknown }
  | {
      _tag: "EXISTING_USER";
      res: Either.Either<UserCredential, FirebaseAuthError>;
    }
  | { _tag: "SHOW_ERROR_MESSAGE"; errorMessage: string };

type RegisterForm = EmailPasswordForm & {
  firstName: string;
  lastName: string;
};

const perfRegisterEff =
  (firebaseJs: FirebaseJsMgr) =>
  (data: RegisterForm): Effect.Effect<PerfRegisterResult, any, never> =>
    Effect.gen(function* (_) {
      const eRes = yield* _(
        Effect.either(firebaseJs.createUserWithEmailAndPasswordEff(data))
      );

      console.log("ERES! ", eRes);

      if (Either.isLeft(eRes)) {
        const errorMessage = messageForFirebaseAuthError(eRes.left.code);
        return { _tag: "SHOW_ERROR_MESSAGE", errorMessage };
      }

      switch (eRes.right._tag) {
        case "ALREADY_CREATED":
          const signinRes = yield* _(
            Effect.either(firebaseJs.signinWithEmailPasswordEff(data))
          );

          return { _tag: "EXISTING_USER", res: signinRes };
        case "SUCCESS":
          const registerRes = yield* _(
            registerViaApiEff({
              ...data,
              firebaseUid: eRes.right.user.user.uid,
            })
          );
          return { _tag: "REGISTERED", res: registerRes };
      }
    });

const AuthinCard: React.FC<AuthinCardProps> = ({
  onSuccessRegister,
  onSuccessLogin,
}) => {
  const { register, handleSubmit, control } = useForm<RegisterForm>();
  const [rdCreateResult, setRdCreateResult] = useState<
    RD.RemoteData<
      | { _tag: "SIMPLE_ERROR"; errorMessage: string }
      | { _tag: "EXISTING_USER"; error?: FirebaseAuthError },
      unknown
    >
  >(RD.initial);
  const firebaseJs = useFirebaseJs();

  return (
    <div className="p-8 flex flex-col items-center gap-16 mb-32">
      <h1 className="font-bold text-5xl text-default-purple">Create Account</h1>
      <form
        className="flex flex-col gap-2 max-w-screen"
        onSubmit={handleSubmit((data) => {
          setRdCreateResult(RD.pending);

          Effect.runPromise(perfRegisterEff(firebaseJs)(data))
            .then((r) => {
              switch (r._tag) {
                case "REGISTERED":
                  onSuccessRegister();
                  setRdCreateResult(RD.success(r.res));
                  break;
                case "EXISTING_USER":
                  if (Either.isLeft(r.res)) {
                    setRdCreateResult(
                      RD.failure({ _tag: "EXISTING_USER", error: r.res.left })
                    );
                  } else {
                    onSuccessLogin();
                  }
                  break;
                case "SHOW_ERROR_MESSAGE":
                  setRdCreateResult(
                    RD.failure({
                      _tag: "SIMPLE_ERROR",
                      errorMessage: r.errorMessage,
                    })
                  );
              }
            })
            .catch((e) => {
              setRdCreateResult(
                RD.failure({
                  _tag: "SIMPLE_ERROR",
                  errorMessage: `Unknown Error ${JSON.stringify(e)}`,
                })
              );
            });
        })}
      >
        <input
          type="email"
          placeholder="Email"
          className="text-input"
          id="email"
          {...register("email", { required: true })}
        />
        <PasswordInput control={control} name="password" />

        {RD.isFailure(rdCreateResult) &&
          match(rdCreateResult.error)
            .with({ _tag: "EXISTING_USER" }, ({ error }) => {
              return (
                <div className="text-red-500">
                  {error && messageForFirebaseAuthError(error?.code)}
                </div>
              );
            })
            .with({ _tag: "SIMPLE_ERROR" }, (r) => {
              return <div className="text-red-500">{r.errorMessage}</div>;
            })
            .exhaustive()}
        <button
          type="submit"
          className="bg-default-purple text-white px-8 py-4 rounded-lg font-bold"
        >
          {RD.isPending(rdCreateResult) ? "Submitting..." : "Create an account"}
        </button>
      </form>
    </div>
  );
};
