import { pipe } from "fp-ts/lib/function";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { RD } from "shared/base-prelude";
import { BE } from "../../../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../../../loading";
import { HP_ROUTES } from "../../../../../../routes/hp-routes";
import { useHpState } from "../../../../hp.state";
import { CommunityPost } from "../components/community-post.fc";
import {
  CommunityCardContainer,
  MyProfileCard,
} from "../components/community.components";

export const CommunitiesLandingPage: React.FC = () => {
  const mgr = useHpState();
  const me = useObservableEagerState(mgr.myProfile$);

  const rdLandingData = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.communities.communitiesLandingHome.query()
    )
  );

  return (
    <div className="flex-1 flex flex-col gap-8 p-8 static lg:relative max-w-full">
      {pipe(
        rdLandingData,
        RD.fold(
          () => <></>,
          () => <FullContainerLoadingSpinner />,
          () => <></>,
          ({
            communitiesIveJoined,
            suggestedCommunitiesToJoin,
            timelineFeedPosts,
          }) => (
            <div className="flex-1 flex gap-16">
              <div className="flex flex-col gap-4 max-w-full lg:w-[714px]">
                <h4 className="text-xl font-medium">Communities</h4>
                <CommunitiesListSection
                  communitiesIveJoined={communitiesIveJoined}
                  suggestedCommunitiesToJoin={suggestedCommunitiesToJoin}
                />
                <div className="flex flex-col gap-4 mt-8">
                  <h4 className="text-xl font-medium leading-6 ">
                    Recent posts
                  </h4>
                  {timelineFeedPosts.map((post) => (
                    <CommunityPost
                      key={post.id}
                      communitySlug={post.communitySlug}
                      post={post}
                      onLike={() => {}}
                    />
                  ))}
                </div>
              </div>
              <MyProfileCard
                name={me.name!}
                profilePhoto={me.profilePhoto!}
                isAvailable={me.isAvailableForPractice}
                bio={me.bio}
                modalities={me.modalities}
                editBioLink={""}
                communitySlug=""
              />
            </div>
          )
        )
      )}
    </div>
  );
};

type CommunitiesIveJoined =
  UApiOutput["hp"]["communities"]["communitiesLandingHome"]["communitiesIveJoined"];
type SuggestedCommunitiesToJoin =
  UApiOutput["hp"]["communities"]["communitiesLandingHome"]["suggestedCommunitiesToJoin"];

const CommunitiesListSection: React.FC<{
  communitiesIveJoined: CommunitiesIveJoined;
  suggestedCommunitiesToJoin: SuggestedCommunitiesToJoin;
}> = ({ communitiesIveJoined, suggestedCommunitiesToJoin }) => {
  const nav = useNavigate();
  return (
    <div className="self-stretch flex justify-between gap-4 lg:min-w-0 overflow-x-auto">
      {communitiesIveJoined.map((c, idx) => (
        <CommunityCardContainer
          key={c.slug}
          headerBackgroundType={{ _tag: "INDEXED", index: idx }}
          innerCircleComponent={
            <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
          }
          title={c.name}
          content={
            <div className="flex flex-col gap-2">
              <div></div>
              <div className="text-vid-black-400">members</div>
            </div>
          }
          button={{
            title: "View",
            onClick: () => {
              nav(
                HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
                  {
                    community: c.slug,
                  }
                )
              );
            },
          }}
        />
      ))}
      {suggestedCommunitiesToJoin.map((c, idx) => (
        <CommunityCardContainer
          key={c.slug}
          headerBackgroundType={{
            _tag: "INDEXED",
            index: idx + communitiesIveJoined.length,
          }}
          innerCircleComponent={
            <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
          }
          title={c.name}
          content={
            <div className="flex flex-col gap-2">
              <div></div>
              <div className="text-vid-black-400">members</div>
            </div>
          }
          button={{
            title: "Join",
            onClick: () => {
              nav(
                HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
                  {
                    community: c.slug,
                  }
                )
              );
            },
          }}
        />
      ))}
    </div>
  );
};
