import { getAuth } from "firebase/auth";
import { FirebaseJsMgr } from "shared/firebase";
import { parseAppEnv } from "shared/index";

const apiUrl = import.meta.env.VITE_API_URL;
const appEnvStr = import.meta.env.VITE_APP_ENV;
const authAppEnvStr = import.meta.env.VITE_AUTH_ENV;
const mbAppNameStr = import.meta.env.VITE_APP_NAME as
  | "Vidalify"
  | "InsightLive"
  | undefined;

const appEnv = parseAppEnv(appEnvStr);

const authAppEnv = parseAppEnv(authAppEnvStr);

export const AppConfig = {
  apiUrl,
  appEnv,
  authAppEnv,
};

export const firebaseMgr = new FirebaseJsMgr(
  AppConfig.authAppEnv,
  getAuth,
  mbAppNameStr ?? "Vidalify"
);

console.log("APP CONFIG! ", AppConfig);
