import { pipe } from "fp-ts/lib/function";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { TE } from "shared/base-prelude";
import * as stream from "stream-chat";
import { BE } from "../../backend";

const connectUserTE = (
  myProfile: { id: string; name: string },
  getTokenTE?: TE.TaskEither<any, { token: string; apiKey: string }>
) =>
  pipe(
    getTokenTE ?? BE.authedTE((tkn) => BE.Api(tkn).u.getUserChatToken.query()),
    TE.mapLeft((e) => `fetch getUserChatTokenError! ${JSON.stringify(e)}`),
    TE.map((res) => ({
      ...res,
      cli: new stream.StreamChat<stream.DefaultGenerics>(res.apiKey),
    })),
    TE.chain(({ cli, token }) =>
      pipe(
        TE.tryCatch(
          () =>
            cli.connectUser({ id: myProfile.id, name: myProfile.name }, token),
          (err) => `${JSON.stringify(err)}`
        ),
        TE.map((conRes) => ({
          ...conRes,
          cli,
        })),
        TE.mapLeft((e) => `connectUserError! ${JSON.stringify(e)}`)
      )
    )
  );

export function useRdConnectClient(p: {
  myProfile: { id: string; name: string };
  getTokenTE?: TE.TaskEither<any, { token: string; apiKey: string }>;
}) {
  const rdConnectClient = useTaskEither(
    connectUserTE(p.myProfile, p.getTokenTE)
  );
  return rdConnectClient;
}
