import {
  BackgroundFiltersProvider,
  StreamCall,
  StreamVideo,
  StreamVideoClient,
} from "@stream-io/video-react-sdk";
import { Effect, Schedule } from "effect";
import { sequenceT } from "fp-ts/lib/Apply";
import { pipe } from "fp-ts/lib/function";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { UApiOutput } from "shared/backend-api/trpc-cli";
import { O, RD, T, erp } from "shared/base-prelude";
import { useFirebaseJs } from "shared/firebase";
import { BE } from "../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../loading";
import { HP_ROUTES } from "../../../../routes/hp-routes";
import {
  HpPrivateSessionStateContext,
  HpPrivateSessionStateMgr,
  useHpPrivateSessionState,
} from "./session.state";

export const HpPrivateSessionLayout: React.FC<{}> = () => {
  const sessionId = useTypedParams(HP_ROUTES.MY.PRIVATE_SESSIONS.SESSION_ID)
    .sessionId!;
  const rdVideoSetup = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).u.session.getSession.query({
        sessionId,
      })
    ),
    [sessionId]
  );

  const firebaseJs = useFirebaseJs();
  const rdHpPrivateSessionStateMgr = useMemo(
    () =>
      pipe(
        rdVideoSetup,
        RD.map(
          (vs) =>
            new HpPrivateSessionStateMgr({
              session: vs.session,
              firebaseJsMgr: firebaseJs,
            })
        )
      ),
    [firebaseJs, rdVideoSetup]
  );

  return pipe(
    sequenceT(RD.remoteData)(rdHpPrivateSessionStateMgr, rdVideoSetup),
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      ([hpPrivateSessionStateMgr, videoSetup]) => (
        <HpPrivateSessionStateContext.Provider value={hpPrivateSessionStateMgr}>
          <LoadedView {...videoSetup} sessionId={sessionId} />
        </HpPrivateSessionStateContext.Provider>
      )
    )
  );
};

type GetSessionResp = UApiOutput["u"]["session"]["getSession"];

const LoadedView: React.FC<GetSessionResp & { sessionId: string }> = ({
  apiKey,
  token,
  streamUser,
  callId,
  callType,
  sessionId,
}) => {
  const [hasJoined, setHasJoined] = useState(false);
  const nav = useNavigate();
  const client = useMemo(
    () => new StreamVideoClient({ apiKey, user: streamUser, token }),
    [apiKey, streamUser, token]
  );
  const call = useMemo(() => client.call(callType, callId), []);
  const mgr = useHpPrivateSessionState();

  useEffect(() => {
    if (!call) return;

    // Subscribe to the call.ended event
    const unsubscribe = call.on("call.ended", () => {
      console.log("CALL ENDED! ");
      nav(HP_ROUTES.MY.DASHBOARD.CLIENTS.path);
    });

    return () => {
      unsubscribe();
    };
  }, [call]);

  useEffect(() => {
    const joinCallTask: T.Task<void> = pipe(
      T.fromIO(() => {
        console.log("JOINING CALL! ");
      }),
      T.chain((_) => () => call.join())
    );
    erp(
      Effect.retry(
        Effect.promise(() => joinCallTask()),
        Schedule.fixed("200 millis")
      )
    ).then((_) => {
      setHasJoined(true);

      mgr.mainRoomStateMgr.sendPing();
    });
  }, [sessionId]);

  if (!hasJoined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <StreamVideo client={client}>
      <StreamCall call={call}>
        <BackgroundFiltersProvider
          isBlurringEnabled={true} // enables blurring
          backgroundFilter="blur" // initial filter
        >
          <Outlet />
        </BackgroundFiltersProvider>
      </StreamCall>
    </StreamVideo>
  );
};
