const training = [
  {
    img: "/dashboard/training-1.png",
    title: "Beginner Hakomi Techniques",
    subtitle: "10am - 11am",
    link: "",
  },
  {
    img: "/dashboard/training-2.png",
    title: "Advanced Hakomi Techniques",
    subtitle: "10am - 11am",
    link: "",
  },
  {
    img: "/dashboard/training-3.png",
    title: "Beginner EMDR Techniques",
    subtitle: "10am - 11am",
    link: "",
  },
  {
    img: "/dashboard/training-4.png",
    title: "Beginner EMDR Techniques",
    subtitle: "10am - 11am",
    link: "",
  },
];

export default training;
