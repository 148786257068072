import { useCall } from "@stream-io/video-react-sdk";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { HP_ROUTES } from "../../../../../routes/hp-routes";

import { useObservableState } from "observable-hooks";
import { useEffect } from "react";
import { match } from "ts-pattern";
import { MainRoomContainer } from "../../../../../components/live-session/main-room/main-room-container";
import { useGroupSessionState } from "../group-session.state";
import { PaginatedGridView } from "./layouts/paginated-grid.view";
import { MainRoomGroupSpeakerViewLayout } from "./layouts/speaker-view";
import { MeditationToolsPanel } from "../../../../../components/live-session/right-nav/therapy-tools/meditation.tools";

export const HpGroupSessionMainRoomPage: React.FC = () => {
  const gss = useGroupSessionState();
  const call = useCall()!;
  const nav = useNavigate();

  const currentLayout = useObservableState(gss.currentLayout$, "PaginatedGrid");

  useEffect(() => {
    console.log("CALL ID! ", call.id);
  }, [call]);

  return (
    <MainRoomContainer
      mainRoomStateMgr={gss.mainRoomMgr}
      topPreviewDisplay={(_) => <></>}
      topBarLeft={{
        memberProfilePhotos: [],
      }}
      leftControls={[]}
      knownTools={[
        {
          tool: "meditation",
          component: (
            <MeditationToolsPanel mainRoomStateMgr={gss.mainRoomMgr} />
          ),
        },
      ]}
      // rightNavDisplay={(rightPanelViewState) =>
      //   match(rightPanelViewState)
      //     .with({ state: "THERAPY_TOOLS" }, () => {
      //       return <TherapyToolMenuContainer knownTools={[]} />;
      //     })
      //     .otherwise(() => <></>)
      // }
      stageContent={
        <div className="flex-1 flex flex-col justify-center min-h-0 px-16 py-8 relative">
          {match(currentLayout)
            .with("PaginatedGrid", () => <PaginatedGridView />)
            .with("Speaker", () => <MainRoomGroupSpeakerViewLayout />)
            .exhaustive()}
          <div className="absolute top-4 right-8">
            <button
              className="border py-1 px-2 rounded-lg text-white"
              onClick={() => {
                const nextLayout =
                  currentLayout === "PaginatedGrid"
                    ? "Speaker"
                    : "PaginatedGrid";

                console.log("NEXT LAYOUT! ", nextLayout);
                gss.selectedLayout$.next(nextLayout);
              }}
            >
              {currentLayout === "PaginatedGrid"
                ? "Speaker view"
                : "Gallery view"}
            </button>
          </div>
        </div>
      }
      onEndCall={() => onEndCall(nav)}
    />
  );
};

async function onEndCall(nav: NavigateFunction) {
  nav(HP_ROUTES.MY.DASHBOARD.CLIENTS.path);
}

// const SettingsButton: React.FC = () => {
//   const { useScreenShareState } = useCallStateHooks();
//   const { status: screenshareStatus, screenShare } = useScreenShareState();
//   return (
//     <Menu as="div" className="relative inline-block text-left">
//       <Menu.Button>
//         <ControlPanelComponents.RoundButtonContainer
//           onClick={() => {}}
//           bgColor="white"
//         >
//           <SettingsIcon />
//         </ControlPanelComponents.RoundButtonContainer>
//       </Menu.Button>
//       <Transition
//         as={Fragment}
//         enter="transition ease-out duration-100"
//         enterFrom="transform opacity-0 scale-95"
//         enterTo="transform opacity-100 scale-100"
//         leave="transition ease-in duration-75"
//         leaveFrom="transform opacity-100 scale-100"
//         leaveTo="transform opacity-0 scale-95"
//       >
//         <Menu.Items className="absolute right-0 bottom-16 w-56 origin-bottom-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
//           <div className="px-1 py-1 ">
//             <Menu.Item>
//               {({ active }) => (
//                 <button
//                   className={`${
//                     active ? "bg-violet-500 text-white" : "text-gray-900"
//                   } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
//                   onClick={() => {
//                     screenShare.toggle();
//                   }}
//                 >
//                   {screenshareStatus === "enabled"
//                     ? "Stop Sharing"
//                     : "Share Screen"}
//                 </button>
//               )}
//             </Menu.Item>
//           </div>
//         </Menu.Items>
//       </Transition>
//     </Menu>
//   );
// };
