import { RouteObject } from "react-router-dom";
import { route, string } from "react-router-typesafe-routes/dom";
import { CpLayout } from "../pages/cp/cp.layout";
import { CpDashboardLayout } from "../pages/cp/dashboard/dashboard.layout";
import { CpDashboardHomePage } from "../pages/cp/dashboard/home.page";
import { CpHpDetailsPage } from "../pages/cp/dashboard/hps/[hpid]/details.page";
import { CpSettingsPage } from "../pages/cp/dashboard/settings.page";
import { CpLivePrivateSessionLayout } from "../pages/cp/sessions/[sessionId]/live/live.layout";
import { CpLivePrivateSessionMainRoomPage } from "../pages/cp/sessions/[sessionId]/live/main-room.page";
import { CpSessionReviewPage } from "../pages/cp/sessions/[sessionId]/review.page";

export const CP_ROUTES = route(
  "cp",
  {},
  {
    DASHBOARD: route(
      "dashboard",
      {},
      {
        HOME: route("home", {}),
        SETTINGS: route("settings", {}),
        HPS: route(
          "hps",
          {},
          {
            HP_ID: route(
              ":hpId",
              { params: { hpId: string().defined() } },
              {
                DETAILS: route("details", {}),
              }
            ),
          }
        ),
      }
    ),
    SESSIONS: route(
      "sessions",
      {},
      {
        SESSION_ID: route(
          ":sessionId",
          { params: { sessionId: string().defined() } },
          {
            REVIEW: route("review", {}),
            LIVE: route(
              "live",
              {},
              {
                MAIN_ROOM: route("main-room", {}),
                WAITING_ROOM: route("waiting-room", {}),
              }
            ),
          }
        ),
      }
    ),
  }
);

export const cpRoutes: RouteObject = {
  path: CP_ROUTES.path,
  element: <CpLayout />,
  children: [
    {
      path: CP_ROUTES.SESSIONS.path,
      children: [
        {
          path: CP_ROUTES.SESSIONS.SESSION_ID.path,
          children: [
            {
              path: CP_ROUTES.SESSIONS.SESSION_ID.LIVE.path,
              element: <CpLivePrivateSessionLayout />,
              children: [
                {
                  path: CP_ROUTES.SESSIONS.SESSION_ID.LIVE.MAIN_ROOM.path,
                  element: <CpLivePrivateSessionMainRoomPage />,
                },
              ],
            },
            {
              path: CP_ROUTES.SESSIONS.SESSION_ID.REVIEW.path,
              element: <CpSessionReviewPage />,
            },
          ],
        },
      ],
    },
    {
      path: CP_ROUTES.DASHBOARD.path,
      element: <CpDashboardLayout />,
      children: [
        {
          path: CP_ROUTES.DASHBOARD.HOME.path,
          element: <CpDashboardHomePage />,
        },
        {
          path: CP_ROUTES.DASHBOARD.SETTINGS.path,
          element: <CpSettingsPage />,
        },
        {
          path: CP_ROUTES.DASHBOARD.HPS.path,
          children: [
            {
              path: CP_ROUTES.DASHBOARD.HPS.HP_ID.DETAILS.path,
              element: <CpHpDetailsPage />,
            },
          ],
        },
      ],
    },
  ],
};
