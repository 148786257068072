import React from "react";
import { Outlet } from "react-router-dom";

export const CpLayout: React.FC = () => {
  return (
    <div className="w-screen h-screen flex flex-col overflow-hidden">
      {/* <nav className="flex px-4 py-1 border">
        <button
          onClick={() => {
            signOut(firebaseAuth).then(() => {
              window.location.href = "/";
            });
          }}
        >
          Sign out
        </button>
      </nav> */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
};
