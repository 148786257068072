import { useCall, useCallStateHooks } from "@stream-io/video-react-sdk";
import { format } from "date-fns";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import { match } from "ts-pattern";
import {
  BaseRegisterPingRes,
  MainRoomStateMgr,
  SpecialClockData,
} from "../../../mgrs/live-session/main-room/main-room.statemgr";
import { O, Rx } from "../../../prelude";
import { Avatar } from "../../avatar.tc";
import { ReviewPageContent } from "../../session-review/components/session-review.components";
import {
  ControlPanelComponents,
  ControlPanelContainer,
  DefaultCallControlButtons,
  SettingsIcon,
} from "../control-panel.components";
import {
  LiveSessionComponents,
  SettingsRightNav,
} from "../live-session.components";
import { MeditationPreview } from "../right-nav/therapy-tools/meditation.tools";
import {
  KnownTools,
  TherapyToolMenuContainer,
} from "../right-nav/therapy-tools/therapy-tools.components";

export interface MainRoomContainerProps<
  RegisterPingRes extends {
    timeLeftSeconds: number | null;
    mbShowNearEndSessionTimer: string | null;
  },
  TopPreviewViewState,
  ExtraRemoteState
> {
  mainRoomStateMgr: MainRoomStateMgr<
    RegisterPingRes,
    TopPreviewViewState,
    ExtraRemoteState
  >;
  stageContent: React.ReactNode;
  onEndCall: () => Promise<void>;
  knownTools: KnownTools;
  // rightNavDisplay: (rightPanel: OpenRightPanelViewState) => React.ReactNode;
  leftControls: React.ReactNode[];
  topBarLeft: {
    memberProfilePhotos: (string | null)[];
    totalMembers?: number;
  };
  topPreviewDisplay: (topPreview: TopPreviewViewState) => React.ReactNode;
}

export const MainRoomContainer = <
  RegisterPingRes extends BaseRegisterPingRes,
  CustomTopPreviewViewStates,
  ExtraRemoteState
>({
  onEndCall,
  stageContent,
  knownTools,
  // rightNavDisplay,
  leftControls,
  topBarLeft,
  mainRoomStateMgr,
  topPreviewDisplay,
}: MainRoomContainerProps<
  RegisterPingRes,
  CustomTopPreviewViewStates,
  ExtraRemoteState
>) => {
  const call = useCall()!;
  const { useMicrophoneState, useCameraState } = useCallStateHooks();
  const { microphone, isMute: isAudioMute } = useMicrophoneState();
  const { camera, isMute: isVideoMute } = useCameraState();
  const rightNav = useObservableEagerState(mainRoomStateMgr.rightNav$);
  const isTopPreviewMaximized = useObservableEagerState(
    mainRoomStateMgr.isTopPreviewMaximized$
  );
  const isLeftNavToolsOpen = useObservableEagerState(
    mainRoomStateMgr.isLeftNavToolsOpen$
  );

  if (isTopPreviewMaximized) {
    return (
      <div className="w-screen h-screen bg-vid-black-900 flex flex-col relative overflow-hidden p-8">
        <TopPreviewSection
          mainRoomStateMgr={mainRoomStateMgr}
          renderCustomTopPreviewDisplay={topPreviewDisplay}
        />
      </div>
    );
  }

  return (
    <div className="w-screen h-screen bg-vid-black-900 flex flex-col relative overflow-hidden">
      <div className="flex-1 flex gap-8 min-h-0 py-8 px-2 md:px-[36px] max-w-screen">
        {isLeftNavToolsOpen && (
          <div className="basis-[60px] self-stretch grow-0 shrink-0 relative hidden md:flex">
            <div className="absolute inset-0 z-20 flex flex-col-reverse items-start gap-4 py-8">
              <LiveSessionComponents.BottomLeftButtons.ToggleNavButton
                arrowDirection="right"
                onClick={() => {
                  mainRoomStateMgr.toggleLeftToolsNav();
                }}
              />
              {leftControls.map((lc, idx) => (
                <div key={idx}>{lc}</div>
              ))}
            </div>
          </div>
        )}
        <div className="flex-1 min-h-0 flex flex-col gap-4">
          <div className="basis-[55px] grow-0 shrink-0 flex justify-between">
            <div className="flex-1 min-w-0 flex">
              <div className="border rounded-full flex items-center text-white px-4">
                <div className="flex items-center ">
                  {topBarLeft.memberProfilePhotos.map((mp, idx) => (
                    <div
                      key={idx}
                      style={{
                        transform: `translateX(${idx * -20}px)`,
                      }}
                    >
                      <Avatar mbProfilePhoto={mp} />
                    </div>
                  ))}
                </div>
                {topBarLeft.totalMembers ? (
                  <div
                    style={{
                      transform: `translateX(-10px)`,
                    }}
                  >
                    {topBarLeft.totalMembers}
                  </div>
                ) : (
                  <div className="text-white">
                    {format(new Date(), "MMM do")}
                  </div>
                )}
              </div>
            </div>
            <ClocksSection
              timeLeftMinutes$={mainRoomStateMgr.timeLeftMinutes$}
              mbShowSpecialClock$={mainRoomStateMgr.mbShowSpecialClock$}
            />
          </div>
          <div className="flex-1 flex flex-col min-w-0 min-h-0 gap-4 max-w-screen relative">
            <TopPreviewSection
              mainRoomStateMgr={mainRoomStateMgr}
              renderCustomTopPreviewDisplay={topPreviewDisplay}
            />
            {stageContent}
            {!isLeftNavToolsOpen && (
              <div className="absolute bottom-4 left-4 ">
                <LiveSessionComponents.BottomLeftButtons.ToggleNavButton
                  arrowDirection="left"
                  onClick={() => {
                    mainRoomStateMgr.toggleLeftToolsNav();
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {O.isSome(rightNav) && (
          <div className="absolute inset-0 md:relative flex flex-col py-0">
            <div className="flex-1 w-full md:w-[360px] bg-white rounded-[12px] flex flex-col overflow-y-auto">
              <div
                className="flex justify-end px-4 py-6 cursor-pointer"
                onClick={() => {
                  mainRoomStateMgr.closeRightNav();
                }}
              >
                <span className="w-8 h-8 rounded-full border flex justify-center items-center">{`X`}</span>
              </div>
              <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
                {match(rightNav.value)
                  .with({ state: "THERAPY_TOOLS" }, ({ initialViewState }) => {
                    return (
                      <div className="max-h-full flex flex-col gap-4 py-4 px-4 rounded-lg overflow-y-scroll self-stretch">
                        <TherapyToolMenuContainer
                          knownTools={knownTools}
                          initialViewState={initialViewState}
                        />
                      </div>
                    );
                  })
                  .with({ state: "SETTINGS" }, () => {
                    return <SettingsRightNav />;
                  })
                  .with({ state: "NEXT_SCHEDULE_REMINDER" }, () => {
                    return <div>Next Schedule Reminder</div>;
                  })
                  .exhaustive()}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="basis-[100px] grow-0 shrink-0 bg-white flex flex-col justify-center">
        <ControlPanelContainer
          centralControls={
            <DefaultCallControlButtons
              audioButton={{
                isMuted: isAudioMute,
                onClick: () => {
                  microphone.toggle();
                },
              }}
              videoButton={{
                isMuted: isVideoMute,
                onClick: () => {
                  camera.toggle();
                },
              }}
              onEndCallButtonClick={() => {
                async function onEnd() {
                  console.log("LEAVING CALL! ");
                  const leaveCallResult = await call.leave();
                  console.log("LEAVE CALL RESULT! ", leaveCallResult);
                  const endCallResult = await onEndCall();
                  console.log("END CALL RESULT! ", endCallResult);
                }
                onEnd().catch();
              }}
            />
          }
          rightControls={
            <div className="flex gap-4 items-center">
              <ControlPanelComponents.RoundButtonContainer
                onClick={() => {
                  mainRoomStateMgr.openRightNav({
                    state: "THERAPY_TOOLS",
                  });
                }}
                bgColor="white"
              >
                <img src="/magicpen.svg" />
              </ControlPanelComponents.RoundButtonContainer>
              <ControlPanelComponents.RoundButtonContainer
                onClick={() => {
                  mainRoomStateMgr.openRightNav({
                    state: "SETTINGS",
                  });
                }}
                bgColor="white"
              >
                <SettingsIcon />
              </ControlPanelComponents.RoundButtonContainer>
            </div>
          }
        />
      </div>
    </div>
  );
};

const TopPreviewSection = <
  RegisterPingRes extends BaseRegisterPingRes,
  CustomTopPreviewViewStates,
  ExtraRemoteState
>({
  mainRoomStateMgr,
  renderCustomTopPreviewDisplay,
}: {
  mainRoomStateMgr: MainRoomStateMgr<
    RegisterPingRes,
    CustomTopPreviewViewStates,
    ExtraRemoteState
  >;
  renderCustomTopPreviewDisplay: (
    topPreview: CustomTopPreviewViewStates
  ) => React.ReactNode;
}) => {
  const mbTopPreviewState = useObservableEagerState(
    mainRoomStateMgr.topPreview$
  );
  const isMaximized = useObservableEagerState(
    mainRoomStateMgr.isTopPreviewMaximized$
  );

  if (O.isNone(mbTopPreviewState)) {
    return <></>;
  }

  return (
    <div
      className={`${
        isMaximized ? "flex-1" : "basis-[400px] grow-0 shrink-0"
      }  bg-white rounded-[12px] p-4 overflow-y-auto relative`}
    >
      {!isMaximized && (
        <div className="flex justify-end">
          <button
            onClick={() => {
              mainRoomStateMgr.closeTopPreview();
            }}
          >
            X
          </button>
        </div>
      )}
      {match(mbTopPreviewState.value)
        .with({ _tag: "Meditation" }, () => {
          return <MeditationPreview mainRoomStateMgr={mainRoomStateMgr} />;
        })
        .with({ _tag: "Review" }, ({ sessionType }) => {
          return (
            <ReviewPageContent
              sessionType={sessionType}
              hideTabs={["Invoice"]}
            />
          );
        })
        .with({ _tag: "Extra" }, ({ extraState }) => {
          return renderCustomTopPreviewDisplay(extraState);
        })
        .exhaustive()}

      <div
        className="absolute bottom-4 right-4 w-[40px] h-[40px] bg-vid-black-900 rounded-full flex items-center justify-center cursor-pointer"
        onClick={() => {
          mainRoomStateMgr.isTopPreviewMaximized$.next(!isMaximized);
        }}
      >
        <MaximizeSVG />
      </div>
    </div>
  );
};

const ClocksSection: React.FC<{
  timeLeftMinutes$: Rx.Observable<number | null>;
  mbShowSpecialClock$: Rx.Observable<SpecialClockData | null>;
}> = ({ timeLeftMinutes$, mbShowSpecialClock$ }) => {
  const timeLeftMinutes = useObservableState(timeLeftMinutes$, null);
  const mbShowSpecialClock = useObservableState(mbShowSpecialClock$, null);
  return (
    <div className="flex gap-4">
      {mbShowSpecialClock && (
        <CountdownClock
          timeLeftSeconds={mbShowSpecialClock.secondsLeft}
          backgroundColor="vid-purple"
          showSeconds={true}
        />
      )}
      <CountdownClock
        timeLeftSeconds={
          timeLeftMinutes ? Math.floor(timeLeftMinutes * 60) : null
        }
      />
    </div>
  );
};

const CountdownClock: React.FC<{
  timeLeftSeconds: number | null;
  backgroundColor?: string;
  showSeconds?: boolean;
}> = ({ timeLeftSeconds, backgroundColor, showSeconds }) => {
  return (
    <div
      className={`border px-5 py-2 rounded-full text-white flex items-center gap-4 text-base ${
        backgroundColor ? `bg-${backgroundColor}` : ""
      }`}
    >
      <img src="/alarm.svg" alt="Alarm icon" width={20} height={20} />
      {timeLeftSeconds !== null &&
        (showSeconds
          ? format(new Date(timeLeftSeconds * 1000), "m:ss")
          : `${Math.floor(timeLeftSeconds / 60)}`)}
    </div>
  );
};

const MaximizeSVG: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7zM18 6L6 18"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M18 10V6h-4M6 14v4h4"
    ></path>
  </svg>
);
