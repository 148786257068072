import { RadioGroup } from "@headlessui/react";
import { useState } from "react";

export const OnboardHpDurationPreferencePage: React.FC = () => {
  const [selectedDuration, setSelectedDuration] = useState<string>("");
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <h1 className="font-bold text-5xl text-default-purple">
        What is the typical duration of your sessions?
      </h1>
      <RadioGroup
        className="flex gap-8"
        value={selectedDuration}
        onChange={setSelectedDuration}
      >
        <RadioGroup.Option value="30">
          {({ checked }) => (
            <span className={checked ? "bg-blue-200" : ""}>Startup</span>
          )}
        </RadioGroup.Option>
      </RadioGroup>
    </div>
  );
};
