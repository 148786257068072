import React from "react";
import { useParams } from "react-router-dom";
import { ReviewPageContent } from "../../../../../components/session-review/components/session-review.components";

export const HpSessionReviewPage: React.FC = () => {
  const sessionId = useParams().sessionId!;
  return (
    <div>
      <nav className="flex p-8 border-b shadow-md">
        <div className="flex-1" />
        <div className="flex-1">
          <h1>{`Review ${sessionId}`}</h1>
        </div>
        <div className="flex-1 flex flex-row-reverse">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              window.location.href = `/hp/my/dashboard/invoices`;
            }}
          >
            Go to dashboard
          </button>
        </div>
      </nav>
      <ReviewPageContent
        sessionType={{ _tag: "PRIVATE", sessionId: sessionId }}
        hideTabs={[]}
      />
    </div>
  );
};
