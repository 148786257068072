import { useObservableEagerState } from "observable-hooks";
import { Link } from "react-router-dom";
import { useHpState } from "../../pages/hp/hp.state";
import { HP_ROUTES } from "../../routes/hp-routes";
import { Avatar } from "../avatar.tc";

export const ProfileButton = () => {
  const dashboardState = useHpState();
  const myProfile = useObservableEagerState(dashboardState.myProfile$);
  return (
    <Link
      to={HP_ROUTES.MY.DASHBOARD.SETTINGS.path}
      className="hidden lg:flex border rounded-lg w-fit p-4 h-[60px] items-center justify-between"
    >
      <img src="/dashboard/menu.svg" width={20} height={20} className="mr-4" />
      <Avatar mbProfilePhoto={myProfile.profilePhoto} size={30} />
    </Link>
  );
};
