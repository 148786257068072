import React from "react";
import { useParams } from "react-router-dom";
import { BE } from "../../../../backend";
import { ReviewPageContent } from "../../../../components/session-review/components/session-review.components";

export const CpSessionReviewPage: React.FC = () => {
  const sessionId = useParams().sessionId!;

  return (
    <div className="h-screen w-screen border-4 border-green-400 flex flex-col gap-4 overflow-y-scroll">
      <h1>{`Review ${sessionId}`}</h1>

      <button
        className="bg-blue-500 text-white p-2 rounded-md"
        onClick={() => {
          BE.authedTE((tkn) =>
            BE.Api(tkn).u.session.triggerTranscriptProcessingLambda.mutate({
              sessionId,
            })
          )().then((er) => {
            console.log(er);
          });
        }}
      >
        Start the transcript lambda!
      </button>
      <button
        className="bg-blue-500 text-white p-2 rounded-md"
        onClick={() => {
          BE.authedTE((tkn) =>
            BE.Api(tkn).u.session.invokeGenerateAiSummary.mutate({
              sessionId,
            })
          )().then((er) => {
            console.log(er);
          });
        }}
      >
        Start the AI summary lambda!
      </button>

      <button
        className="bg-blue-500 text-white p-2 rounded-md"
        onClick={() => {
          BE.authedTE((tkn) =>
            BE.Api(tkn).u.session.review.getOrCreateChronologicalSummary.mutate(
              {
                sessionId,
              }
            )
          )().then((er) => {
            console.log(er);
          });
        }}
      >
        Get or create ai chronological summary!
      </button>

      <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
        <ReviewPageContent
          sessionType={{ _tag: "PRIVATE", sessionId: sessionId }}
          hideTabs={[]}
        />
      </div>
    </div>
  );
};
