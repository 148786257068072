import { Outlet } from "react-router-dom";
import { DashboardFCs } from "../../../components/dashboard/dashboard.components";
import { signOut } from "firebase/auth";
import { useFirebaseJs } from "shared/firebase";
import { CpDashboardState, CpDashboardStateContext } from "./cpdashboard.state";
import React from "react";
import { BE } from "../../../backend";
import { pipe } from "fp-ts/lib/function";
import { O, RD } from "shared/base-prelude";
import { useTaskEither } from "shared/backend-api/api.mgr";

export const CpDashboardLayout: React.FC = ({}) => {
  const rdMyProfile = useTaskEither(
    BE.authedTE((tkn) => BE.Api(tkn).cp.getMyProfile.query())
  );

  return pipe(
    rdMyProfile,
    RD.toOption,
    O.fold(
      () => <div>Loading...</div>,
      (myProfile) => <CpDashboardLayoutContent myProfile={myProfile} />
    )
  );
};

const CpDashboardLayoutContent: React.FC<{
  myProfile: { id: string; name: string; profilePhoto: string | null };
}> = ({ myProfile }) => {
  const firebaseJs = useFirebaseJs();
  const dashboardState = React.useMemo(
    () => new CpDashboardState({ myProfile }),
    [myProfile]
  );

  return (
    <CpDashboardStateContext.Provider value={dashboardState}>
      <div
        className="flex w-full max-h-screen"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div>
          <DashboardFCs.LeftMenu
            onLogoClick={() => {
              window.location.href = "/cp/dashboard/home";
            }}
            onSignout={() => {
              signOut(firebaseJs.auth).then(() => {
                window.location.href = "/";
              });
            }}
            links={[
              () => <HomeLink />,
              () => (
                <DashboardFCs.LeftMenuLink to="/cp/dashboard/settings">
                  Settings
                </DashboardFCs.LeftMenuLink>
              ),
            ]}
          />
        </div>
        <div className="flex-1 overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </CpDashboardStateContext.Provider>
  );
};

const HomeLink: React.FC = () => {
  return (
    <DashboardFCs.LeftMenuLink to="/cp/dashboard/home">
      <div>Home</div>
    </DashboardFCs.LeftMenuLink>
  );
};
