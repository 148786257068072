import { useState } from "react";

export const DefaultAvatar: React.FC<{ size?: number }> = ({ size }) => {
  const d = size ?? 45;
  return (
    <div
      className="bg-vid-black-200 rounded-full p-2 flex flex-col justify-center items-center "
      style={{
        width: d,
        height: d,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"100%"}
        height={"100%"}
        fill="none"
        viewBox={`0 0 45 56`}
      >
        <path
          stroke="#AD99FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M22.5 23a9.375 9.375 0 100-18.75 9.375 9.375 0 100 18.75zM38.606 41.75c0-7.256-7.219-13.125-16.106-13.125-8.888 0-16.106 5.869-16.106 13.125"
        ></path>
      </svg>
    </div>
  );
};

export const Avatar: React.FC<{
  mbProfilePhoto: string | null;
  size?: number;
}> = ({ mbProfilePhoto, size }) => {
  if (mbProfilePhoto) {
    return (
      <img
        src={mbProfilePhoto}
        alt="profile photo"
        className={`rounded-full object-cover max-h-[100%] aspect-square`}
        style={{
          width: size ?? "100%",
          height: size ?? "100%",
        }}
      />
    );
  }
  return <DefaultAvatar size={size} />;
};

type ProfileImageSelectorProps = {
  currentMbProfile: string | null;
  onImageChange: (base64String: string) => void;
  size?: number;
};

export const ProfileImageSelector: React.FC<ProfileImageSelectorProps> = ({
  currentMbProfile,
  onImageChange,
  size,
}) => {
  const [mbProfilePhoto, setMbProfilePhoto] = useState(currentMbProfile);

  return (
    <div className="flex items-center justify-center">
      <label htmlFor="profileImage" className="cursor-pointer">
        <div className="relative">
          <Avatar mbProfilePhoto={mbProfilePhoto} size={size ?? 125} />
          <div className="absolute bottom-0 right-0 w-[42px] h-[42px] rounded-full bg-white flex justify-center items-center">
            <img src={"/edit.svg"} alt="edit" />
          </div>
        </div>
        <input
          id="profileImage"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={(e) => {
            const file = e.target.files ? e.target.files[0] : null;
            if (file) {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64String = reader.result as string;
                if (base64String) {
                  // Handle the base64 string here
                  setMbProfilePhoto(base64String);
                  onImageChange(base64String);
                }
              };
              reader.readAsDataURL(file);
            }
          }}
        />
      </label>
    </div>
  );
};
