const sessions = [
    {
        "img": "/dashboard/session-1.png",
        "title": "Anne Adams",
        "subtitle": "10am - 11am",
        "link": ""
    },
    {
        "img": "/dashboard/session-2.png",
        "title": "Anne Adams",
        "subtitle": "10am - 11am",
        "link": ""
    },
    {
        "img": "/dashboard/session-3.png",
        "title": "Anne Adams",
        "subtitle": "10am - 11am",
        "link": ""
    },
    {
        "img": "/dashboard/session-4.png",
        "title": "Anne Adams",
        "subtitle": "10am - 11am",
        "link": ""
    }
]

export default sessions;