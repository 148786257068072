import { RouteObject } from "react-router-dom";
import { route, string } from "react-router-typesafe-routes/dom";
import { IndexLayout } from "../index.layout";
import { AnonConfirmEmailInviteRedirectPage } from "../pages/anon/confirm-email-invite/confirm-email-redirect.page";
import { AnonConfrimEmailInviteJoinSessionPage } from "../pages/anon/confirm-email-invite/join-session.page";
import { AnonConfirmEmailViewOurWorkPage } from "../pages/anon/confirm-email-invite/view-our-work.page";
import { CpOnboardCreateAccountPage } from "../pages/cp-onboard/create-account.page";
import { LoginPage } from "../pages/login.page";
import { OnboardHpDurationPreferencePage } from "../pages/onboard/hp/duration-pref.page";
import { OnboardHpRegisterPage } from "../pages/onboard/hp/register.page";
import { OnboardHpTherapyTypesPage } from "../pages/onboard/hp/therapy-types.page";
import { OnboardLayout } from "../pages/onboard/onboard.layout";
import { PortalSelectionPage } from "../portal-selection.page";
import { cpRoutes } from "./cp-routes";
import { hpRoutes } from "./hp-routes";

export const ONBOARD_ROUTES = route(
  "onboard",
  {},
  {
    PORTAL_SELECTION: route("portal-selection", {}),
    HP: route(
      "hp",
      {},
      {
        REGISTER: route("register", {}),
        MY: route(
          "my",
          {},
          {
            THERAPY_TYPES: route("therapy-types", {}),
            DURATION_PREFERENCE: route("duration-preference", {}),
          }
        ),
      }
    ),
  }
);

const onboardRoutes: RouteObject = {
  path: ONBOARD_ROUTES.path,
  element: <OnboardLayout />,
  children: [
    {
      path: ONBOARD_ROUTES.PORTAL_SELECTION.path,
      element: <PortalSelectionPage />,
    },
    {
      path: ONBOARD_ROUTES.HP.path,
      children: [
        {
          path: ONBOARD_ROUTES.HP.REGISTER.path,
          element: <OnboardHpRegisterPage />,
        },
        {
          path: ONBOARD_ROUTES.HP.MY.path,
          children: [
            {
              path: ONBOARD_ROUTES.HP.MY.THERAPY_TYPES.path,
              element: <OnboardHpTherapyTypesPage />,
            },
            {
              path: ONBOARD_ROUTES.HP.MY.DURATION_PREFERENCE.path,
              element: <OnboardHpDurationPreferencePage />,
            },
          ],
        },
      ],
    },
  ],
};

export const ROOT_ROUTES = route(
  "",
  {},
  {
    LOGIN: route("login", { searchParams: { prefilledEmail: string() } }),
  }
);

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <IndexLayout />,
    children: [
      {
        path: ROOT_ROUTES.LOGIN.path,
        element: <LoginPage />,
      },
      onboardRoutes,
      cpRoutes,
      hpRoutes,
      {
        path: "onboard-cp/create-account",
        element: <CpOnboardCreateAccountPage />,
      },
      {
        path: "anon",
        children: [
          {
            path: "confirm-email-invite",
            children: [
              {
                path: "redirect-handler",
                element: <AnonConfirmEmailInviteRedirectPage />,
              },
              {
                path: "join-session",
                element: <AnonConfrimEmailInviteJoinSessionPage />,
              },
              {
                path: "view-our-work",
                element: <AnonConfirmEmailViewOurWorkPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
