import { useState } from "react";

export const FormDisclosureContainer: React.FC<{
  buttonView: React.ReactNode;
  dropdownView: (close: () => void) => React.ReactNode;
  height?: number;
}> = ({ buttonView, dropdownView, height }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative flex flex-col">
      <div className="relative flex flex-col">
        {/* Wrapper for button and dropdown */}
        <button
          className="py-4 px-6 border flex justify-between items-center rounded-xl"
          onClick={() => {
            setIsOpen((b) => !b);
          }}
        >
          {buttonView}
          <DownArrowSvg />
        </button>
        {isOpen && (
          <div
            className={`absolute top-full -left-2 -right-2 rounded-lg bg-vid-black-100 border z-20 min-h-[100px] ${
              height ? `h-${height}` : ""
            }`}
          >
            {dropdownView(() => {
              setIsOpen(false);
            })}
          </div>
        )}
      </div>
    </div>
  );
};

function DownArrowSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91 4.08008"
        stroke="#1D1626"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="rotate(90 12 12)"
      />
    </svg>
  );
}
