import { UApiOutput } from "shared/backend-api/trpc-cli";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import {
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { RD } from "shared/base-prelude";
import { BE } from "../../../../../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../../../../../loading";
import { HP_ROUTES } from "../../../../../../../../routes/hp-routes";
import { TabView, TopBanner } from "../../../components/community.components";
import { useTaskEither } from "shared/backend-api/api.mgr";

type MemberGlobalProfileResp =
  UApiOutput["hp"]["communities"]["getMemberGlobalProfile"];

export const GlobalMemberPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const curPath = useLocation().pathname;
  const rdMemberGlobalProfile = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.communities.getMemberGlobalProfile.query({
        userId: userId!,
      })
    )
  );

  return pipe(
    rdMemberGlobalProfile,
    RD.fold3(
      () => <FullContainerLoadingSpinner />,
      (err) => <div>{JSON.stringify(err)}</div>,
      ({ member }) => (
        <div className="flex flex-col p-8 gap-8">
          <TopBanner
            title={member.name!}
            circleImageUrl={member.profilePhoto}
            rightView={<></>}
            nextToTitleFc={<></>}
          />
          <div className="flex gap-8">
            <TabView
              title="About"
              linkTo={HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
                { userId: userId! }
              )}
              isCurrent={
                curPath ===
                HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
                  { userId: userId! }
                )
              }
            />
            <TabView
              title="Chat"
              linkTo={HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.CHAT.buildPath(
                { userId: userId! }
              )}
              isCurrent={
                curPath ===
                HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.CHAT.buildPath(
                  { userId: userId! }
                )
              }
            />
          </div>
          <div className="flex-1 flex flex-col">
            <Outlet context={member} />
          </div>
        </div>
      )
    )
  );
};

export const GlobalMemberAboutTab: React.FC = () => {
  const z = useOutletContext<MemberGlobalProfileResp["member"]>();

  return (
    <div className="flex-1 flex flex-col">
      <h1>{z.name}</h1>
      <h4>{z.email}</h4>
    </div>
  );
};
