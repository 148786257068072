import { pipe } from "fp-ts/lib/function";
import React, { useMemo } from "react";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { AppChatChannelTypeName } from "shared";
import { useTaskEither } from "shared/backend-api/api.mgr";
import { E, O, RD, TE } from "shared/base-prelude";
import { IoTrash } from "react-icons/io5";
import * as stream from "stream-chat";
import {
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelPreviewUIComponentProps,
  Chat,
  DefaultStreamChatGenerics,
  MessageInput,
  MessageList,
  Thread,
  useTranslationContext,
  Window,
} from "stream-chat-react";
import { match } from "ts-pattern";
import { BE } from "../../../../../../../backend";
import { FullContainerLoadingSpinner } from "../../../../../../../loading";
import { HP_ROUTES } from "../../../../../../../routes/hp-routes";
import { format } from "date-fns";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "../../../../../../../components/primitives/button";
import { useHpState } from "../../../../../hp.state";

export const CommunityEventPage: React.FC = () => {
  const hpState = useHpState();
  const params = useTypedParams(
    HP_ROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.EVENT_ID
  );

  const rdEventInfo = useTaskEither(
    BE.authedTE((tkn) =>
      BE.Api(tkn).hp.community.getEventInfo.query({
        communitySlug: params.community,
        eventId: params.eventId,
      })
    )
  );

  return pipe(
    rdEventInfo,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (eventInfo) => (
        <div className="flex flex-col gap-4 border-4 rounded-lg p-4">
          <div className="flex justify-between">
            <h4 className="text-2xl font-bold">{eventInfo.title}</h4>
            <button
              className=" px-4 py-2 rounded-md"
              onClick={() => {
                BE.fetchEndpointTE((Api) =>
                  Api.hp.community.hardDeleteEvent.mutate({
                    eventId: params.eventId,
                    communitySlug: params.community,
                  })
                )().then((er) => {
                  console.log("ER! ", er);
                  if (E.isRight(er)) {
                    hpState.showBottomToast({
                      msg: "Successfully deleted event!",
                      reload: true,
                    });
                  }
                });
              }}
            >
              <IoTrash />
            </button>
          </div>
          <p>{eventInfo.description}</p>
          <p>{`Occurs at ${format(eventInfo.start_time, "P p")} ${
            eventInfo.recurring_status !== "none" && eventInfo.recurring_status
          }`}</p>
          {eventInfo.myStatus === null && (
            <div className="flex gap-4">
              <div className="w-[100px]">
                <PrimaryButton
                  title="Attend"
                  onClick={() => {
                    BE.fetchEndpointTE((Api) =>
                      Api.hp.community.setEventStatus.mutate({
                        communitySlug: params.community,
                        eventId: params.eventId,
                        status: "attending",
                      })
                    )().then((er) => {
                      if (E.isRight(er)) {
                        hpState.showBottomToast({
                          msg: "Successfuly set status!",
                          reload: true,
                        });
                      }
                    });
                  }}
                />
              </div>
              <div className="w-[100px]">
                <InversePrimaryButton
                  title="Interested"
                  onClick={() => {
                    BE.authedTE((tkn) =>
                      BE.Api(tkn).hp.community.setEventStatus.mutate({
                        communitySlug: params.community,
                        eventId: params.eventId,
                        status: "interested",
                      })
                    )().then((er) => {
                      if (E.isRight(er)) {
                        hpState.showBottomToast({
                          msg: "Successfuly set status!",
                          reload: true,
                        });
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
          {eventInfo.myStatus && <p>{`My status: ${eventInfo.myStatus}`}</p>}
        </div>
      )
    )
  );
};

interface Props {
  setupChatTE: () => TE.TaskEither<
    any,
    {
      channel: {
        name: string;
        id?: string | null;
        type: AppChatChannelTypeName;
      };
      apiKey: string;
      token: string;
      allMemberIds: string[];
    }
  >;
  myProfile: { id: string; name: string };
}

type ChannelViewState =
  | { _tag: "LIST" }
  | { _tag: "CHANNEL"; channel: stream.Channel };

export const GroupChatFC: React.FC<Props> = ({ myProfile, setupChatTE }) => {
  const [viewState, setViewState] = React.useState<ChannelViewState>({
    _tag: "LIST",
  });
  const [rdSetup, setChannel] = React.useState<
    RD.RemoteData<
      any,
      {
        cli: stream.StreamChat<stream.DefaultGenerics>;
        channel: stream.Channel;
      }
    >
  >(RD.initial);

  React.useEffect(() => {
    async function setup() {
      const startChatRes = await setupChatTE()();

      if (E.isLeft(startChatRes)) {
        return RD.failure(startChatRes.left);
      }

      const cli = stream.StreamChat.getInstance(startChatRes.right.apiKey);

      const connUserRes = await cli.connectUser(
        {
          id: myProfile.id,
          name: myProfile.name,
        },
        startChatRes.right.token
      );

      console.log("CONN USER RES! ", connUserRes);

      const channel = startChatRes.right.channel.id
        ? cli.getChannelById(
            startChatRes.right.channel.type,
            startChatRes.right.channel.id,
            { members: startChatRes.right.allMemberIds }
          )
        : cli.getChannelByMembers(startChatRes.right.channel.type, {
            members: startChatRes.right.allMemberIds,
          });
      setChannel(RD.success({ cli, channel }));
    }
    setup().catch();
  }, []);

  if (!RD.isSuccess(rdSetup)) {
    return <div>Loading...</div>;
  }

  const onChannelListItemClick = (channel: stream.Channel) => {
    setViewState({ _tag: "CHANNEL", channel });
  };

  return (
    <Chat client={rdSetup.value.cli}>
      {match(viewState)
        .with({ _tag: "LIST" }, () => (
          <ChannelList
            filters={{ members: { $in: [myProfile.id] } }}
            Preview={(props) => (
              <CustomChannelPreview
                {...props}
                onChannelListItemClick={onChannelListItemClick}
              />
            )}
          />
        ))
        .with({ _tag: "CHANNEL" }, (res) => (
          <Channel channel={res.channel}>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        ))
        .exhaustive()}
    </Chat>
  );
};

const CustomChannelPreview: React.FC<
  ChannelPreviewUIComponentProps<DefaultStreamChatGenerics> & {
    onChannelListItemClick: (channel: stream.Channel) => void;
  }
> = (props) => {
  const {
    channel,
    activeChannel,
    displayImage,
    displayTitle,
    latestMessage,
    setActiveChannel,
    onChannelListItemClick,
  } = props;
  const latestMessageAt = channel.state.last_message_at;
  const isSelected = channel.id === activeChannel?.id;
  const { userLanguage } = useTranslationContext();

  const timestamp = useMemo(() => {
    if (!latestMessageAt) {
      return "";
    }
    const formatter = new Intl.DateTimeFormat(userLanguage, {
      timeStyle: "short",
    });
    return formatter.format(latestMessageAt);
  }, [latestMessageAt, userLanguage]);

  const handleClick = () => {
    console.log("HANDLING CLICK! ", channel);
    setActiveChannel?.(channel);
    onChannelListItemClick(channel);
  };

  return (
    <button
      className={`channel-preview ${
        isSelected ? "channel-preview_selected" : ""
      } border w-full p-4 cursor-pointer`}
      // disabled={isSelected}
      onClick={handleClick}
    >
      <img className="channel-preview__avatar" src={displayImage} alt="" />
      <div className="channel-preview__main">
        <div className="channel-preview__header">
          {displayTitle}
          <time
            dateTime={latestMessageAt?.toISOString()}
            className="channel-preview__timestamp"
          >
            {timestamp}
          </time>
        </div>
        <div className="channel-preview__message">{latestMessage}</div>
      </div>
    </button>
  );
};
