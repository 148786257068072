import { SfuModels, StreamVideoParticipant } from "@stream-io/video-react-sdk";
import { Effect } from "effect";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { epipe } from "shared/base-prelude";
import { useDebouncedCallback } from "use-debounce";
import { E, Rx, TE } from "./prelude";

export function useDebouncedOnClick(onClick: () => void, delay: number = 300) {
  return useDebouncedCallback(onClick, delay);
}

export function useBehaviorSubject<T>(subject: Rx.BehaviorSubject<T>) {
  const [value, setValue] = useState(subject.getValue());
  useEffect(() => {
    console.log("MEOW!");
    const sub = subject.subscribe((s) => {
      console.log("SUBJECT! ", s);
      setValue(s);
    });
    return () => {
      console.log("UNUSBCRIBING!");
      sub.unsubscribe();
    };
  }, [subject]);
  return value;
}

export function convertObservableToBehaviorSubject<T>(
  observable: Rx.Observable<T>,
  initValue: T
): Rx.BehaviorSubject<T> {
  const subject = new Rx.BehaviorSubject(initValue);

  observable.subscribe(subject);

  return subject;
}

export function convertObservableToSubject<T>(
  observable: Rx.Observable<T>
): Rx.Subject<T> {
  const subject = new Rx.Subject<T>();

  observable.subscribe(subject);

  return subject;
}

export function currentObservableValue<T>(
  observable: Rx.Observable<T>
): Promise<T> {
  return new Promise((resolve) => {
    const sub = observable.subscribe((v) => {
      resolve(v);
      sub.unsubscribe();
    });
  });
}

export function safeParseInt(s: string): number | undefined {
  const n = parseInt(s);
  return isNaN(n) ? undefined : n;
}

export function useScrollToAnchor() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
}

export function base64ToBlob(base64: string, mimeType: string): Blob {
  const byteCharacters = atob(base64.split(",")[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

export function delayP(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function taskEitherToEff<E, V>(
  te: TE.TaskEither<E, V>
): Effect.Effect<V, E, never> {
  return epipe(
    Effect.promise(te),
    Effect.flatMap((eres) =>
      E.isLeft(eres) ? Effect.fail(eres.left) : Effect.succeed(eres.right)
    )
  );
}

export const hasScreenShare = (p: StreamVideoParticipant) =>
  p.publishedTracks.includes(SfuModels.TrackType.SCREEN_SHARE);
